
import axios from "axios"

const baseUrl = process.env.REACT_APP_API_BASEURL

/**
* @function getAssociatedCompaniesSubmissionService
* @param (authToken, indicator, submissionId, userId)
* @description api to Associated Companies submission details.
*/
export const getAssociatedCompaniesSubmissionService = async ({ authToken, indicator, submissionId, userId }) => {
    const result = await axios.get(`${baseUrl}users/sectionQuestions/list/associated?indicator=${indicator}&submissionId=${submissionId}&userId=${userId}`, {
        headers: {
            Authorization: `Bearer ${authToken}`
        }
    })
    return result;
}

/**
* @function getProgressAssociatedCompaniesSubmissionService
* @param (authToken, proGress_Dimension, submissionId, userId)
* @description api to progress Associated Companies submission details.
*/
export const getProgressAssociatedCompaniesSubmissionService = async ({ authToken, submissionId, proGress_Dimension, userId }) => {
    const result = await axios.get(`${baseUrl}ProGress/questions/list/associated?submissionId=${submissionId}&proGress_Dimension=${proGress_Dimension}&userId=${userId}`, {
        headers: {
            Authorization: `Bearer ${authToken}`
        }
    })
    return result;
}


/**
    * @function getAssesmentSummaryQuestionService
    * @param ({ token, SubmissionId )
    * @description call post API to start the assesment for ESG
*/
export const getAssesmentSummaryQuestionService = async ({ token, SubmissionId }) => {
    const result = await axios.get(`${baseUrl}admin/submission/summery/WithQuestion?SubmissionId=${SubmissionId}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    return result;
};