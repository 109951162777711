import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import { Form } from 'reactstrap';
import SignatureCanvas from 'react-signature-canvas';
import toast, { Toaster } from 'react-hot-toast'
import { useForm } from 'react-hook-form';
import { useSelector } from "react-redux";
import classNames from 'classnames';

// Utility-Service
import { getToken, getUser } from "../../../utility/AuthService";

// Redux-Functions
import { selectLanguageUse } from '../../../redux/slices/language.Slice';

// API service
import { uploadApprovalSignServices } from '../../../services/progressReportServices/uploadApprovalSign';

const SignatureModal = (props) => {
    const authToken = getToken();
    const user = getUser()
    const languageUse = useSelector(selectLanguageUse || user?.language)

    const [drawnSign, setDrawnSign] = useState();

    let sigPad = {}

    const { setValue, handleSubmit, watch, formState: { errors } } = useForm();
    const watchSignature = watch('uploadPDF')

    /**
        * @function PDFup
        * @param e, status
        * @description append multiple pdf
    */
    const PDFup = (e, status) => {
        setValue(`uploadPDF.files`, e?.target?.files)
        if (status === 'add') {
            append({ files: null })
        }
    }

    /**
        * @function clear
        * @param
        * @description clear canvas
    */
    const clear = () => {
        sigPad.clear()
        setDrawnSign()
    }

    /**
        * @function trim
        * @param
        * @description trim to image
    */
    const trim = () => {
        setDrawnSign(sigPad.getTrimmedCanvas().toDataURL('image/png'))
    }

    /**
        * @function handleUploadSign
        * @param data
        * @description to upload sign
    */
    const handleUploadSign = async (data) => {
        let signnaturePic = null

        if (drawnSign) {
            const res = await fetch(drawnSign);
            const blob = await res.blob();
            signnaturePic = new File([blob], 'signature.png', { type: 'image/png' });
        }

        try {
            const result = await uploadApprovalSignServices({
                authToken: authToken,
                id: props?.submissionId,
                file: drawnSign ? signnaturePic : data?.uploadPDF?.files[0]
            });
            if (result?.data?.statusCode === 200) {
                props?.setUserSignUploaded(!props?.userSignUploaded)
                toast.success(languageUse === 'English' ? 'Sign uploaded' : 'Tanda dimuat naik')
                props.handleClose()
            } else {
                toast.error(result?.response?.data?.message || languageUse === 'English' ? 'Something went wrong!' : 'Ada yang tak kena!')
            };
            return false;
        } catch (error) {
            toast.error(error?.response?.data?.message || languageUse === 'English' ? 'Something went wrong!' : 'Ada yang tak kena!')
        }
    }

    /**
        * @function onSubmit
        * @param (data)
        * @description to submit
    */
    const onSubmit = (data) => {
        if (data?.uploadPDF || drawnSign) {
            handleUploadSign(data)
        } else {
            toast.error(languageUse === 'English' ? 'Add signature image or Draw you signature' : 'Tambah imej tandatangan atau Lukiskan tandatangan anda')
        }
    };

    return <>
        <div>
            <Toaster />
        </div>
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton>{languageUse === 'English' ? 'Add your signature' : 'Tambah tandatangan anda'}</Modal.Header>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    {!drawnSign && <div className={classNames('text-center')}>
                        <label htmlFor={`uploadPDF`} className="addPdf rounded " >
                            {watchSignature ? <p role='button' className='mb-0 p-2 bg-primary text-white'>{languageUse === 'English' ? 'Signature file uploaded' : 'Fail tandatangan dimuat naik'}</p> : <div role='button' className=''>
                                <p className='mb-0 p-2'>{languageUse === 'English' ? 'Add Signature file' : 'Tambah fail Tandatangan'}</p>
                            </div>}
                        </label>
                        <input
                            id={`uploadPDF`}
                            type="file"
                            accept="image/*"
                            className={`${errors.uploadPDF ? 'is-invalid' : 'd-none'}`}
                            name={`uploadPDF`}
                            onChange={(e) => {
                                if (uploadPDF?.files) {
                                    PDFup(e, 'edit')
                                } else {
                                    PDFup(e, 'add')
                                }
                            }}
                        />
                    </div>}

                    {!watchSignature && <div className='border mt-3'>
                        <p className='text-center m-2'>{languageUse === 'English' ? 'Draw your signature' : 'Lukis tandatangan anda'}</p>
                        <div className='border rounded m-2'>
                            <SignatureCanvas penColor='black' canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }} ref={(ref) => { sigPad = ref }} />
                        </div>
                        <div className='d-flex justify-content-between mb-2 px-2'>
                            <Button variant="danger" onClick={() => clear()}>
                                {languageUse === 'English' ? 'Clear' : 'Jelas'}
                            </Button>
                            <Button variant="success" onClick={() => trim()} >
                                {languageUse === 'English' ? 'Save' : 'Simpan'}
                            </Button>
                        </div>

                        {drawnSign ? <div className='border rounded text-center p-1 m-2'>
                            <img className=''
                                src={drawnSign} />
                        </div> : null
                        }
                    </div>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" type='button' onClick={props.handleClose}>
                        {languageUse === 'English' ? 'Close' : 'Tutup'}
                    </Button>
                    <Button variant="primary" type='submit'>
                        {languageUse === 'English' ? 'Submit' : 'Hantar'}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    </>
};

export default SignatureModal