import React, { useState } from 'react'

//third party
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
ChartJS.register(BarElement, CategoryScale, LinearScale, ArcElement, Tooltip, Legend, ChartDataLabels);

// ** Reactstrap Imports
import {
  Row,
  Col,
  CardTitle,
  CardText,
  Form,
  Label,
  Input,
  Button
} from "reactstrap"
import toast, { Toaster } from 'react-hot-toast'
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";

const PieChart = (props) => {
    return (
        <>
            <Pie options={props.options} data={props.data} width={props.width} height={props.height} />
          
        </>
    )
};

export default PieChart