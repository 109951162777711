import axios from "axios"
const baseUrl = process.env.REACT_APP_API_BASEURL

/**
    * @function startSubmissionService
    * @param (authToken)
    * @description call post API to start the assesment
*/
export const startSubmissionService = ({ authToken }) => axios.post(`${baseUrl}users/submission/create`, {}, {
    headers: {
        Authorization: `Bearer ${authToken}`
    }
})

/**
    * @function endSubmissionService
    * @param ({ authToken, SubmissionId })
    * @description End the assesment 
*/
export const endSubmissionService = async ({ authToken, SubmissionId }) => {
    const result = await axios.patch(`${baseUrl}users/submission/end`, {
        SubmissionId
    }, {
        headers: {
            Authorization: `Bearer ${authToken}`
        }
    })
    return result
}

/**
    * @function startProgressSubmissionService
    * @param (authToken)
    * @description call post API to start the progress assesment
*/
export const startProgressSubmissionService = ({ authToken }) => {
    const result = axios.post(`${baseUrl}ProGress/submission/start`, {}, {
        headers: {
            Authorization: `Bearer ${authToken}`
        }
    })
    return result
}

/**
    * @function endProgressSubmissionService
    * @param ({ authToken, SubmissionId })
    * @description End the assesment 
*/
export const endProgressSubmissionService = async ({ authToken, SubmissionId }) => {
    const result = await axios.patch(`${baseUrl}ProGress/submission/end`, {
        SubmissionId
    }, {
        headers: {
            Authorization: `Bearer ${authToken}`
        }
    });
    return result
}

/**
    * @function getAssesmentSummaryService
    * @param ({ authToken, SubmissionId )
    * @description call post API to start the assesment
*/
export const getAssesmentSummaryService = async ({ authToken, SubmissionId }) => {
    const result = await axios.get(`${baseUrl}users/submission/summery?SubmissionId=${SubmissionId}`, {
        headers: {
            Authorization: `Bearer ${authToken}`
        }
    })
    return result
}

/**
    * @function getAssesmentListService
    * @param ({ authToken, limit, offset })
    * @description call get API to get the list of assesment
*/
export const getAssesmentListService = async ({ authToken, limit, offset }) => {
    const result = await axios.get(`${baseUrl}users/submissions/list?offset=${offset}&limit=${limit}`, {
        headers: {
            Authorization: `Bearer ${authToken}`
        }
    })
    return result
}

/**
    * @function getProgressAssesmentListService
    * @param ({ authToken, limit, offset })
    * @description call get API to get the list of progress assesment
*/
export const getProgressAssesmentListService = async ({ authToken, limit, offset }) => {
    const result = await axios.get(`${baseUrl}ProGress/submission/list?offset=${offset}&limit=${limit}`, {
        headers: {
            Authorization: `Bearer ${authToken}`
        }
    })
    return result
}