import { useState, useEffect, useCallback } from "react";
import { Button, Spinner, Form, InputGroup } from 'react-bootstrap';
import Select from 'react-select';
import { CSVLink } from "react-csv";
import DataTable from 'react-data-table-component';
import { AiOutlineEye } from "react-icons/ai";
import { RxDashboard } from "react-icons/rx";
import { BsSkipEndCircle } from "react-icons/bs";
import { CiSearch } from "react-icons/ci";
import { ChevronDown } from 'react-feather';
import CryptoJS from 'crypto-js';
import ReactPaginate from 'react-paginate';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from 'moment';

// Custom Component
import UploadedDocumentsModal from "../modal/UploadedDocumentsModal";
import ShowPdfModal from "../modal/ShowPdfModal";

// Utility-Service
import { getToken, getUser } from "../../../utility/AuthService";

// Redux-Functions
import { selectLanguageUse } from '../../../redux/slices/language.Slice';

// API-Service
import { associatedIndustryFilterListService, associatedSearchListService, getAssociatedCompanies, getUserProgressAssessmentSummaryService } from "../../../services/associatedProgressCompaniesService";
import { getAllIndustryListService } from "../../../services/industryService";

const ProgressAssociatedCompanies = () => {
    const navigate = useNavigate()

    const authToken = getToken()
    const user = getUser()
    const languageUse = useSelector(selectLanguageUse || user?.language)

    const [searchTerm, setSearchTerm] = useState('')
    const [assesmentLoading, setAssesmentLoading] = useState(false);
    const [assesmentList, setAssesmentList] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [totalEntry, setTotalEntry] = useState()
    const [submissionId, setSubmissionId] = useState()
    const [uploadedDocumentsModal, setUploadedDocumentsModal] = useState(false);
    const [pdfItem, setPdfItem] = useState();
    const [showPdfModalShow, setShowPdfModalShow] = useState(false);
    const [allIndustry, setallIndustry] = useState([])
    const [selectedIndustry, setIndustry] = useState()
    const [csvData, setCSVData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0)
    const [offsetCal, setoffsetCal] = useState(0)
    const [multipleCSVData, setMultipleCSVData] = useState([]);
    const [multipleCSVLoading, setMultipleCSVLoading] = useState(false)
    const [csvDownloadIndex, setCSVDownloadIndex] = useState();

    const pageLimit = 10

    const { register, watch } = useForm({
        defaultValues: {
            paginationVal: 10
        }
    });
    const watchPaginationVal = watch('paginationVal')

    const csvHeaders = [
        { label: 'No.', key: 'no' },
        { label: 'Company Name', key: 'companyName' },
        { label: 'Submission By', key: 'submissionBy' },
        { label: 'Date', key: 'date' },
        { label: 'ESG Maturity Level', key: 'level' },
    ];

    const [mutipleCSVHeaders, setMultipleCSVHeaders] = useState([
        { label: 'Submission Time', key: 'submission_time' },
        { label: 'Started On', key: 'started_on' },
        { label: 'Username', key: 'username' },
        { label: 'Company Name', key: 'company_name' },
        { label: 'Industry', key: 'industry' },
        { label: 'Parent access code', key: 'parent_access_code' },
        { label: 'User Email', key: 'user_email' },
        { label: 'Phone number', key: 'phone_number' },
        { label: 'Location', key: 'location' },
        { label: 'Company export', key: 'company_export' },
        { label: 'Climate Maturity Level', key: 'climate_maturity_level' },
        { label: 'Climate Governance', key: 'climate_governance' },
        { label: 'GHG Emissions Reduction', key: 'ghg_emissions_reduction' },
        { label: 'Business Strategy Integration', key: 'business_strategy_integration' }
    ]);

    /**
    * @function getLevel
    * @param scoreleval
    * @description used to get leval
*/
    const getLevel = (scoreleval) => {
        const allLeavls = ["Laggard", "Beginner", "Intermediate", "Advanced", "Leader"];
        let leval = allLeavls[0];
        if (scoreleval > 0 && scoreleval <= 20) {
            leval = allLeavls[0];
        } else if (scoreleval > 20 && scoreleval <= 40) {
            leval = allLeavls[1];
        } else if (scoreleval > 40 && scoreleval <= 60) {
            leval = allLeavls[2];
        } else if (scoreleval > 60 && scoreleval <= 80) {
            leval = allLeavls[3];
        } else if (scoreleval > 80 && scoreleval <= 100) {
            leval = allLeavls[4];
        } else {
            leval = allLeavls[0];
        }
        return leval;
    }

    useEffect(() => {
        if (csvDownloadIndex) {
            // handleIndividualDownload(selectedRows[csvDownloadIndex], assesmentList?.find(item => item?._id === selectedRows[csvDownloadIndex])?.userId?._id)
        } else if (csvDownloadIndex === 0) {
            // handleIndividualDownload(selectedRows[csvDownloadIndex], assesmentList?.find(item => item?._id === selectedRows[csvDownloadIndex])?.userId?._id)
        }
    }, [csvDownloadIndex])

    const handleShowPdfModalShow = () => {
        setShowPdfModalShow(!showPdfModalShow);
    }

    const UploadedDocumentsModaltoggle = (id) => {
        setSubmissionId(id)
        setUploadedDocumentsModal(!uploadedDocumentsModal)
    };

    const ExportButton = () => {
        return (
            <CSVLink data={csvData} headers={csvHeaders} filename="Associated Companies.csv">
                <button className="btn btn-primary">{languageUse === 'English' ? "Export CSV" : "Eksport CSV"}</button>
            </CSVLink>
        );
    };

    /**
        * @function goToDashboard
        * @param S_id
        * @description navigate to Dashboard page
    */
    const goToDashboard = (S_id) => {
        const encryptedSubId = CryptoJS.AES.encrypt(S_id, 'mySecretKey').toString();
        navigate(`/user/progress/dashboard/${encodeURIComponent(encryptedSubId)}`)
    };

    /**
        * @function goToDirectDownload
        * @param S_id 
        * @description navigate to Dashboard page
    */
    const goToDirectDownload = (S_id) => {
        const encryptedSubId = CryptoJS.AES.encrypt(S_id, 'mySecretKey').toString();
        navigate(`/user/progress/dashboard/${encodeURIComponent(encryptedSubId)}?download=true`)
    };

    /**
        * @function handleEdit
        * @param status, S_id, userId
        * @description use encrypted submission id to go to the question page.
    */
    const handleEdit = (status, S_id, userId) => {
        const encryptedSubId = CryptoJS.AES.encrypt(S_id, 'mySecretKey').toString();
        if (status) {
            navigate(`/progress${encodeURIComponent(encryptedSubId)}?userId=${userId}`)
        } else {
            navigate(`/view/progress/${encodeURIComponent(encryptedSubId)}?userId=${userId}`)
        }
    };

    /**
        * @function getDocumentsView
        * @param status, id
        * @description use condition to return desired button.
    */
    const getDocumentsView = (status, id) => {
        if (!status) {
            return (<Button className="login-btn-min-width border rounded p-2" onClick={() => UploadedDocumentsModaltoggle(id)} variant='primary'>
                <span className="text-uppercase">{languageUse === 'English' ? "View" : "Lihat"}</span>
            </Button>)
        } else {
            return (<>-</>)
        };
    };

    /**
        * @function getStatusIcon
        * @param status, id, userId
        * @description use condition to return desired icon.
    */
    const getStatusIcon = (status, id, userId) => {
        if (!status) {
            return (
                <div className="d-flex flex-row align-items-center">
                    <AiOutlineEye style={{ fontSize: "20px" }} cursor={'pointer'} onClick={() => handleEdit(status, id, userId)}></AiOutlineEye>
                    <RxDashboard style={{ fontSize: "16px", marginLeft: '2rem' }} cursor={'pointer'} onClick={() => goToDashboard(id)}></RxDashboard>
                </div>
            )
        } else {
            return <BsSkipEndCircle style={{ fontSize: "20px" }} cursor={'pointer'} onClick={() => handleEdit(status, id, userId)} />
        };
    }

    /**
        * @function getIndustryID
        * @param
        * @description get industry list using api
    */
    const getIndustryID = async () => {
        try {
            const result = await getAllIndustryListService({
                authToken
            });
            if (result?.data?.statusCode === 200) {
                const industries = []
                result?.data?.data?.list.map(number => {
                    industries.push({ value: number._id, label: number.name })
                });
                setallIndustry(industries)
            } else {
                setallIndustry([])
                toast.error(error?.response?.data?.message || 'Something went wrong!')
            }
        } catch (error) {
            setallIndustry([])
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    };

    useEffect(() => {
        getIndustryID()
    }, []);

    const searchData = async (searchKey) => {
        const result = await associatedSearchListService({
            authToken,
            userId: user?._id,
            offset: offsetCal,
            limit: watchPaginationVal || pageLimit,
            search: searchKey
        });
        if (result?.data?.statusCode) {
            setAssesmentLoading(false)
            setCSVData(result?.data?.data?.list?.map((item, index) => {
                return {
                    no: index + 1,
                    companyName: item?.user?.company,
                    submissionBy: item?.user?.username,
                    date: moment(item?.submission?.updatedAt).format("DD/MM/YYYY"),
                    level: item?.submission?.leval
                }
            }))
            setTotalEntry(result?.data?.data?.count)
            setAssesmentList(result?.data?.data?.list)
        } else {
            setAssesmentLoading(false)
            setCSVData([])
            setAssesmentList([])
            toast.success('Something went wrong!')
        }
    }

    const industryFilter = async () => {
        const result = await associatedIndustryFilterListService({
            authToken,
            userId: user?._id,
            offset: offsetCal,
            limit: pageLimit,
            q: selectedIndustry?.value
        });
        if (result?.data?.statusCode) {
            setAssesmentLoading(false)
            setCSVData(result?.data?.data?.list?.map((item, index) => {
                return {
                    no: index + 1,
                    companyName: item?.user?.company,
                    submissionBy: item?.user?.username,
                    date: moment(item?.submission?.updatedAt).format("DD/MM/YYYY"),
                    level: item?.submission?.leval
                }
            }))
            setTotalEntry(result?.data?.data?.count)
            setAssesmentList(result?.data?.data?.list)
        } else {
            setAssesmentLoading(false)
            setCSVData([])
            setAssesmentList([])
            toast.success('Something went wrong!')
        }
    }

    /**
        * @function handlePagination
        * @param page
        * @description Function to handle Pagination
    */
    const handlePagination = page => {
        setCurrentPage(page.selected)
        setoffsetCal(page.selected * watchPaginationVal)
    }

    /**
        * @function getAssesmentList
        * @param
        * @description get Assesment List
    */
    const getAssociatedCompaniesList = async () => {
        try {
            setAssesmentLoading(true)
            const result = await getAssociatedCompanies({
                authToken,
                userId: user?._id,
                offset: offsetCal,
                limit: watchPaginationVal || pageLimit,
            });
            setCSVData(result?.data?.data?.list?.map((item, index) => {
                return {
                    no: index + 1,
                    companyName: item?.user?.company,
                    submissionBy: item?.user?.username,
                    date: moment(item?.submission?.updatedAt).format("DD/MM/YYYY"),
                    level: item?.submission?.leval
                }
            }))
            setTotalEntry(result?.data?.data?.count)
            setAssesmentList(result?.data?.data?.list)
            setAssesmentLoading(false)
        } catch (error) {
            setAssesmentLoading(false)
            setCSVData([])
            setAssesmentList([])
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    };

    useEffect(() => {
        const getData = setTimeout(() => {
            if (searchTerm.length > 0) {
                searchData(searchTerm)
            } else if (selectedIndustry) {
                industryFilter()
            } else {
                getAssociatedCompaniesList()
            }
        }, 1000)

        return () => clearTimeout(getData)
    }, [searchTerm, offsetCal, pageLimit, selectedIndustry, watchPaginationVal]);

    const handleRowSelected = useCallback(state => {
        setSelectedRows(state.selectedRows?.map(item => item?.submission?._id))
    });

    const handleCSVDownloadSelected = async () => {
        if (selectedRows?.length > 0) {
            setCSVDownloadIndex(0)
        }
    };

    /**
        * @function handleIndividualDownload
        * @param id
        * @description to handle the download
    */
    const handleIndividualDownload = async (id, userId) => {
        if (id) {
            setMultipleCSVLoading(true)
            const result = await getUserProgressAssessmentSummaryService({
                token: authToken,
                SubmissionId: id,
                userId
            });
            if (csvDownloadIndex === 0) {
                const tempHeader = mutipleCSVHeaders
                result?.data?.data?.requiredQuestions?.forEach((item, index) => {
                    item?.questions?.forEach((subItem, subIndex) => {
                        tempHeader?.push({
                            label: `Dimension: ${item?.dimension} Question: ${subItem?.questionId?.question}`?.replace(/"/g, '""'),
                            key: `required_question_${index}_${subIndex}`
                        })
                    })
                })
                setMultipleCSVHeaders(tempHeader)
            }
            if (Number.isInteger(csvDownloadIndex)) {
                const temp = multipleCSVData;
                const tempObject = {
                    submission_time: moment(result?.data?.data?.updatedAt).format("DD-MM-YYYY, hh:mma"),
                    started_on: moment(result?.data?.data?.createdAt).format("DD-MM-YYYY, hh:mma"),
                    username: result?.data?.data?.profile?.username,
                    company_name: result?.data?.data?.profile?.company,
                    industry: result?.data?.data?.profile?.industry?.name,
                    parent_access_code: result?.data?.data?.profile?.joinedAccessCode?.length > 0 ? result?.data?.data?.profile?.joinedAccessCode[0] : '--',
                    user_email: result?.data?.data?.profile?.email,
                    phone_number: result?.data?.data?.profile?.mobileNo,
                    location: result?.data?.data?.profile?.companyLocation,
                    company_export: `${Array.isArray(result?.data?.data?.exportQuestion?.answer) ? result?.data?.data?.exportQuestion?.answer?.join(', ') : result?.data?.data?.exportQuestion?.answer || ""}`?.replace(/"/g, '""'),
                    climate_maturity_level: result?.data?.data?.readinessLeval ? result?.data?.data?.readinessLeval : "--",
                    climate_governance: result?.data?.data?.dimensionScoring?.find(item => item?.name?.toLowerCase()?.replace(/ /g, "_") === "climate_governance") ? getLevel((result?.data?.data?.dimensionScoring?.find(item => item?.name?.toLowerCase()?.replace(/ /g, "_") === "climate_governance")?.correct / result?.data?.data?.dimensionScoring?.find(item => item?.name?.toLowerCase()?.replace(/ /g, "_") === "climate_governance")?.total) * 100 || 0) : "--",
                    ghg_emissions_reduction: result?.data?.data?.dimensionScoring?.find(item => item?.name?.toLowerCase()?.replace(/ /g, "_") === "ghg_emissions_reduction") ? getLevel((result?.data?.data?.dimensionScoring?.find(item => item?.name?.toLowerCase()?.replace(/ /g, "_") === "ghg_emissions_reduction")?.correct / result?.data?.data?.dimensionScoring?.find(item => item?.name?.toLowerCase()?.replace(/ /g, "_") === "ghg_emissions_reduction")?.total) * 100 || 0) : "--",
                    business_strategy_integration: result?.data?.data?.dimensionScoring?.find(item => item?.name?.toLowerCase()?.replace(/ /g, "_") === "business_strategy_integration") ? getLevel((result?.data?.data?.dimensionScoring?.find(item => item?.name?.toLowerCase()?.replace(/ /g, "_") === "business_strategy_integration")?.correct / result?.data?.data?.dimensionScoring?.find(item => item?.name?.toLowerCase()?.replace(/ /g, "_") === "business_strategy_integration")?.total) * 100 || 0) : "--"
                }
                result?.data?.data?.requiredQuestions?.forEach((item, index) => {
                    item?.questions?.forEach((subItem, subIndex) => {
                        tempObject[`required_question_${index}_${subIndex}`] = `${Array.isArray(subItem?.answer) ? subItem?.answer.join(', ') : subItem?.answer}`?.replace(/"/g, '""')
                    })
                })
                temp.push(tempObject)
                setMultipleCSVData(temp)
            }
            setTimeout(() => {
                if (selectedRows?.length - 1 > csvDownloadIndex) {
                    setCSVDownloadIndex(csvDownloadIndex + 1)
                } else {
                    setCSVDownloadIndex()
                    setMultipleCSVLoading(false)
                    setTimeout(() => {
                        document.getElementById("multiple-export-csv").click();
                        setMultipleCSVData([])
                    }, 100)
                }
            }, 100)
        }
    }

    useEffect(() => {
        if (csvDownloadIndex) {
            handleIndividualDownload(selectedRows[csvDownloadIndex], assesmentList?.find(item => item?.submission?._id === selectedRows[csvDownloadIndex])?.user?._id)
        } else if (csvDownloadIndex === 0) {
            handleIndividualDownload(selectedRows[csvDownloadIndex], assesmentList?.find(item => item?.submission?._id === selectedRows[csvDownloadIndex])?.user?._id)
        }
    }, [csvDownloadIndex])

    /**
    * @function goToAnalytics
    * @param (data)
    * @description to go to analytics page
    */
    const goToAdvanceAnalytics = async (data) => {
        navigate("/progressAdvanceAnalytics");
    };

    useEffect(() => {
        setCurrentPage(0)
        setoffsetCal(0)
    }, [watchPaginationVal])

    const columns = [
        {
            name: languageUse === 'English' ? 'No.' : 'Tidak.',
            cell: (row, index) => (currentPage + 1) > 1 ? (((currentPage + 1) - 1) * watchPaginationVal) + index + 1 : index + 1,
            width: "80px",
        },
        {
            name: languageUse === 'English' ? "Company Name" : "Nama Syarikat",
            selector: (row) => row?.user?.company || '--',
            width: "200px",
        },
        {
            name: languageUse === 'English' ? "Submission By" : "Penyerahan Oleh",
            selector: (row) => row?.user?.username || '--',
            width: "200px",
        },
        {
            name: languageUse === 'English' ? "Date" : "Tarikh",
            selector: (row) => row?.submission?.updatedAt ? moment(row?.submission?.updatedAt).format("DD/MM/YYYY") : '--',
            width: "150px",
        },
        {
            name: languageUse === 'English' ? "Readiness Level" : "Tahap Kesediaan",
            selector: (row) => row?.submission?.readinessLeval || '--',
            width: "200px",
        },
        {
            name: languageUse === 'English' ? "Action" : "Tindakan",
            selector: (row) => row?.submission ? <button className="btn  downloadButton" onClick={() => goToDirectDownload(row?.submission?._id)}>{languageUse === 'English' ? "Download" : "Muat turun"}</button> : '--',
            width: "150px",
        },
        {
            name: languageUse === 'English' ? "Documents" : "Dokumen",
            selector: (row) => getDocumentsView(row?.submission?.isDraft, row?.submission?._id),
            width: "150px",
        },
        {
            name: "",
            selector: (row) => row?.submission ? getStatusIcon(row?.submission?.isDraft, row?.submission?._id, row?.submission?.userId) : '--',
            // width: "200px",
        }
    ];

    // to disable row selection
    const rowDisabledCriteria = row => !row?.submission;

    return <>
        {assesmentLoading ? <div className="d-flex justify-content-center align-items-center" style={{ height: '70vh' }}>
            <Spinner animation="grow" />
        </div> : <>
            <div className="container history-text-color">
                <div className="d-flex flex-wrap align-items-center">
                    <h2 className="me-3">{languageUse === 'English' ? 'Progress Associated Companies' : 'Syarikat Bersekutu Kemajuan'}</h2>
                    <div className="me-3 flex-grow-1">
                        <InputGroup className="border rounded-3">
                            <InputGroup.Text className='bg-transparent border-0'><CiSearch /></InputGroup.Text>
                            <Form.Control
                                className='bg-transparent border-0 rounded-3 px-0 search-input'
                                placeholder={languageUse === 'English' ? 'Search' : 'Cari'}
                                onChange={(e) => {
                                    setSearchTerm(e.target.value)
                                }}
                            />
                        </InputGroup>
                    </div>
                    <Select
                        placeholder={languageUse === 'English' ? "Select Industry" : "Pilih Industri"}
                        className="me-3"
                        isClearable
                        options={allIndustry}
                        defaultValue={selectedIndustry}
                        onChange={setIndustry}
                    />
                    {(multipleCSVData.length === 0 && !multipleCSVLoading && selectedRows?.length > 0) ? <div className='text-end px-1 me-3'>
                        <button className='btn btn-primary' onClick={() => handleCSVDownloadSelected()}>Export Submission</button>
                    </div> : (multipleCSVData.length > 0 && !multipleCSVLoading && selectedRows?.length > 0) ? <div className='text-end px-1 me-3'>
                        <CSVLink data={multipleCSVData} headers={mutipleCSVHeaders} filename="ProgressSubmissionAssoComp.csv">
                            <button id='multiple-export-csv' className="btn btn-primary">Export Submission</button>
                        </CSVLink>
                    </div> : multipleCSVLoading ? <div className='text-end p-1 me-3'><Spinner color='primary' /></div> : <></>
                    }
                    {assesmentList?.length > 0 ? <>
                        <button className="btn btn-primary me-3" onClick={goToAdvanceAnalytics}>
                            {languageUse === 'English' ? 'Advanced Analytics' : 'Analisis lanjutan'}
                        </button>
                    </> : <></>}
                    <ExportButton />
                </div>

                {assesmentList?.length > 0 ? <>
                    {/* Design for large screen */}
                    <div className='react-dataTable react-dataTable-selectable-rows'>
                        <DataTable
                            // noHeader
                            columns={columns}
                            customStyles={{
                                headCells: {
                                    style: {
                                        fontSize: '1rem',
                                        fontWeight: 'bold',
                                        backgroundColor: '#F4FBFE'
                                    }
                                },
                                rows: {
                                    style: {
                                        fontSize: '1rem'
                                    }
                                }
                            }}
                            className='react-dataTable mt-3'
                            sortIcon={<ChevronDown size={10} />}
                            data={assesmentList}
                            // key={assesmentList?.id}
                            // pagination
                            // paginationServer
                            // paginationTotalRows={totalEntry}
                            // paginationPerPage={countPerPage}
                            // paginationComponentOptions={{ noRowsPerPage: true }}
                            // paginationComponent={CustomPagination}
                            // paginationDefaultPage={currentPage + 1}
                            selectableRows
                            onSelectedRowsChange={handleRowSelected}
                            selectableRowDisabled={rowDisabledCriteria}
                        />
                    </div>
                    {/* <div className="table-responsive">
                        <table className="table border">
                            <thead className="table-head">
                                <tr className="history-text-color">
                                    <th scope="col">No.</th>
                                    <th scope="col">{languageUse === 'English' ? "Company Name" : "Nama Syarikat"}</th>
                                    <th scope="col">{languageUse === 'English' ? "Submission By" : "Penyerahan Oleh"}</th>
                                    <th scope="col">{languageUse === 'English' ? "Date" : "Tarikh"}</th>
                                    <th scope="col">{languageUse === 'English' ? "Readiness Level" : "Tahap Kesediaan"}</th>
                                    <th scope="col">{languageUse === 'English' ? "Action" : "Tindakan"}</th>
                                    <th scope="col">{languageUse === 'English' ? "Documents" : "Dokumen"}</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>

                            <tbody>
                                {assesmentList?.length > 0 && assesmentList?.map((item, index) => (
                                    <tr key={index}>
                                        <td>{(currentPage + 1) > 1 ? (((currentPage + 1) - 1) * watchPaginationVal) + index + 1 : index + 1}.</td>

                                        <td>{item?.user?.company}</td>
                                        <td>{item?.user?.username}</td>
                                        <td>{moment(item?.submission?.updatedAt).format("DD/MM/YYYY")}</td>
                                        <td>{item?.submission?.readinessLeval}</td>
                                        <td> {item?.submission ? <button className="btn  downloadButton" onClick={() => goToDirectDownload(item?.submission?._id)}>{languageUse === 'English' ? "Download" : "Muat turun"}</button> : '----'} </td>
                                        <td>{getDocumentsView(item?.submission?.isDraft, item?.submission?._id)}</td>

                                        <td>{item?.submission ? getStatusIcon(item?.submission?.isDraft, item?.submission?._id, item?.submission?.userId) : '---'}</td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div> */}

                    <div className='d-flex align-items-center justify-content-end w-100 mt-3'>
                        <div className='px-2 fs-12'>
                            <span className='me-1'>{languageUse === 'English' ? 'Show' : 'tunjuk'}</span>
                            <select name="paginationData" id="paginationDataShow" className='rounded-pill border bg-light' {...register("paginationVal")} >
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            <span className='ms-1'>{languageUse === 'English' ? "entries" : "entri"}</span>
                            <span className='ms-2'>{languageUse === 'English' ? "Showing" : "menunjukkan"} {currentPage === 0 ? `${1}` : (currentPage * watchPaginationVal) + 1} - {(watchPaginationVal * (currentPage + 1)) > totalEntry ? totalEntry : (watchPaginationVal * (currentPage + 1)) || 10} of {totalEntry || 0} {languageUse === 'English' ? "entries" : "entri"}</span>
                        </div>

                        <ReactPaginate
                            breakLabel='...'
                            pageRangeDisplayed={2}
                            forcePage={currentPage}
                            marginPagesDisplayed={2}
                            activeClassName='active'
                            pageClassName='page-item rounded-circle me-2'
                            breakClassName='page-item rounded-circle me-2'
                            nextLinkClassName='page-link me-2'
                            pageLinkClassName='page-link rounded-circle me-2'
                            breakLinkClassName='page-lin rounded-circle me-2'
                            previousLinkClassName='page-link me-2'
                            nextClassName='page-item next-item me-2'
                            previousClassName='page-item prev-item me-2'
                            pageCount={Math.ceil(totalEntry / (watchPaginationVal || 10)) || 1}
                            onPageChange={page => handlePagination(page)}
                            containerClassName='pagination react-paginate separated-pagination pagination-sm justify-content-end pe-1 mb-0'
                        />
                    </div>
                </> : <>
                    <div className='pt-5 ps-0'>
                        <div className='w-100 rounded bgColor-noQ height-noQ d-flex align-items-center justify-content-center'>
                            <p className=''>No Associated Companies Available</p>
                        </div>
                    </div>
                </>
                }
            </div>
        </>}

        <UploadedDocumentsModal modal={uploadedDocumentsModal} toggle={UploadedDocumentsModaltoggle} handleShowPdfModalShow={handleShowPdfModalShow} submissionId={submissionId} setPdfItem={setPdfItem} progress={true} />
        <ShowPdfModal show={showPdfModalShow} handleClose={handleShowPdfModalShow} pdfItem={pdfItem} />
    </>
};

export default ProgressAssociatedCompanies