import React from 'react'
// Custom Component
import NavbarComponent from "../../components/layouts/Navbar";
import UploadDocuments from '../../components/UploadDocuments/UploadDocuments';
import Footer from '../../components/layouts/Footer'

const UploadDocumentsPage = () => {
    return (
        <>
            <NavbarComponent />
            <UploadDocuments />
            <Footer />
        </>
    )
};

export default UploadDocumentsPage