import { createSlice } from "@reduxjs/toolkit"

export const languageSlice = createSlice({
    name: "Language",
    initialState: {
        language: 'English',
    },
    reducers: {
        setLanguageChoice: (state, action) => {
            state.language = action.payload
        },
    }

})

export const {
    setLanguageChoice
} = languageSlice.actions

export const selectLanguageUse = (state) => state.language.language

export default languageSlice.reducer
