
import axios from "axios"
const baseUrl = process.env.REACT_APP_API_BASEURL

export const indicatorListService = async ({ authToken, filter, limit, offset, submissionId }) => {
    const result = await axios.get(`${baseUrl}indicator?offset=${offset}&limit=${limit}&filter=${filter}&submissionId=${submissionId}`, {
        headers: {
            Authorization: `Bearer ${authToken}`
        }
    })
    return result;
}

/**
 * @function getImageIndicatorService
 * @param (token, file)
 * @description call API to get the indicator image
 */
export const getImageIndicatorService = ({ token, file }) => {
    return axios.post(`${baseUrl}indicator/file`, {
        file: {
            ...file
        }
    }, {
        responseType: 'arraybuffer',
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            // "Access-Control-Allow-Origin": '*'
        }
    })
}

export const activeIndicatorListService = ({ token }) => axios.get(`${baseUrl}indicator?filter=Active`, {
    headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
    }
})