import React, { useState } from 'react'
import NavbarComponent from "../../components/layouts/Navbar";
import AdvancedAnalyticsSidebar from '../../components/advanceAnalytics/AdvancedAnalyticsSidebar';
import { Col, Row, Container, Button } from 'reactstrap';
import ESGMaturityLevels from '../../components/advanceAnalytics/ESGMaturityLevels';
import ESGperformance from '../../components/advanceAnalytics/ESGperformance';
import BreakdownESGperformance from '../../components/advanceAnalytics/BreakdownESGperformance';
import UndertakingActions from '../../components/advanceAnalytics/UndertakingActions';
import SDGmapping from '../../components/advanceAnalytics/SDGmapping';

import { MdKeyboardBackspace } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import Overall from '../../components/advanceAnalytics/Overall';
import Footer from '../../components/layouts/Footer'

const AdvanceAnalyticsPage = () => {
    const navigate = useNavigate()
    const [activeKey, setActiveKey] = useState('1')
    return (
        <Container>
            <NavbarComponent />
            <Row className='p-2 mb-3'>
                <Col>
                    <p role='button' onClick={() => navigate(-1)}><MdKeyboardBackspace size={20} className='me-2' />Back</p>
                    <h4>Supply Chain ESG</h4>
                    <AdvancedAnalyticsSidebar activeKey={activeKey} setActiveKey={setActiveKey} />
                </Col>
            </Row>

            <Row className='borde p-2'>
                <Col className="mt-3">
                    {activeKey === "1" && <ESGMaturityLevels />}
                    {activeKey === "2" && <ESGperformance />}
                    {activeKey === "3" && <BreakdownESGperformance />}
                    {activeKey === "4" && <UndertakingActions />}
                    {activeKey === "5" && <SDGmapping />}
                    {activeKey === "6" && <Overall />}
                </Col>
            </Row>
            <Footer />
        </Container>
    )
};

export default AdvanceAnalyticsPage