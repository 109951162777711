// ** React Imports
import React, { Fragment, useState, forwardRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { LogOut } from 'react-feather'
// ** Reactstrap Imports
import {
    Row,
    Col,
    Container,
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Dropdown,
    NavbarText,
    Label
} from 'reactstrap'

import Login from '../../components/auth/Login'
// import Login from '../../components/auth//Login2'
import Header from '../../components/auth/Header'
import Footer from '../../components/layouts/Footer'

const UserLogIn = (args) => {
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(false)
    const navigate = useNavigate()
    const { id } = useParams()


    return (
        <>
            <Fragment>
                <Header />
                <Login />
                <Footer />
            </Fragment>
        </>

    )
}

export default UserLogIn
