
import axios from "axios"
const baseUrl = process.env.REACT_APP_API_BASEURL

/**
    * @function uploadApprovalSignServices
    * @param (authToken, id, files)
    * @description call API to upload
*/
export const uploadApprovalSignServices = async ({ authToken, id, file }) => {
    const result = await axios.patch(`${baseUrl}ProGress/submission/approvelsign/upload?id=${id}`,
        { file },
        {
            headers: {
                Authorization: `Bearer ${authToken}`,
                'Content-Type': 'multipart/form-data'
            }
        })
    return result;
};
