import React, { useState } from 'react'
import { Col, Row } from 'reactstrap'
import MaturityLevelsTabs from './MaturityLevelsTabs'
import Multilevel from './Multilevel'
import AvgClimateActionMaturity from './AvgClimateActionMaturity'

const MaturityLevels = () => {
    const [activeKey, setActiveKey] = useState('1')

    return (
        <>
            <Row className='ps-2'>
                <MaturityLevelsTabs activeKey={activeKey} setActiveKey={setActiveKey} />
            </Row>
            <Row className='borde p-2'>
                <Col className="mt-3">
                    {activeKey === "1" && <Multilevel />}
                    {activeKey === "2" && <AvgClimateActionMaturity />}
                </Col>
            </Row>
        </>
    )
}

export default MaturityLevels