import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { Card, Button, CardTitle, CardHeader, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom'
import moment from 'moment';


import VerticalBarChart from '../CustomChart/VerticalBarChart';
import CustomDatePicker from '../CustomDatePicker/CustomDatePicker';
import Selection from '../Selection/Selection';
import { getToken } from '../../../utility/AuthService';
import { filterEmissionIndustryAndStateService, getRecordedEmissionService } from '../../../services/analyticsServices/advanceAnalyticsService/advanceAnalyticsService';
import { demographicsListService } from '../../../services/demographicsService';
import { getAllIndustryListService } from '../../../services/industryService';
import TotalScopeChart from './TotalScopeChart';
import { useForm } from 'react-hook-form';

const GHGemission = () => {
    const token = getToken()
    const [isLoading, setLoading] = useState(false)
    const [countryName, setCountryName] = useState()
    const [stateName, setStateName] = useState()
    const [industryName, setIndustryName] = useState()
    const [companySizeName, setCompanySizeName] = useState()
    const [allIndustryList, setAllIndustryList] = useState([])
    const [parcentOrDigit, setParcentOrDigit] = useState('digit')
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [chartData, setchartData] = useState()
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);

    const {
        register,
        reset,
        handleSubmit,
        watch,
        control,
        formState: { errors },
        setValue
    } = useForm();

    const watchCountry = watch("Country")
    const watchState = watch("State")

    const companySizeList = [
        {
            value: "Less than RM 300,000",
            label: "Less than RM 300,000"
        },
        {
            value: "RM 300,000 - 3 mil",
            label: "RM 300,000 - 3 mil"
        },
        {
            value: "RM 3 mil - 20 mil",
            label: "RM 3 mil - 20 mil"
        },
        {
            value: "RM 20 mil - 50 mil",
            label: "RM 20 mil - 50 mil"
        },
        {
            value: "RM 50 mil - 100 mil",
            label: "RM 50 mil - 100 mil"
        },
        {
            value: "RM 100 mil +",
            label: "RM 100 mil +"
        }
    ]

    /**
        * @function getCountryList
        * @param
        * @description get country list using api
    */
    const getCountryList = async () => {
        try {
            const result = await demographicsListService({
                type: 'Country',
                typeId: ""
            });
            if (result?.data?.statusCode === 200) {
                setCountryList(result?.data?.data?.list?.map(item => {
                    return {
                        value: item?._id,
                        label: item?.name
                    }
                }))
            }
        } catch (error) {
            setCountryList([])
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    };

    /**
        * @function getStateList
        * @param
        * @description get state list using api
    */
    const getStateList = async () => {
        try {
            const result = await demographicsListService({
                type: 'State',
                typeId: watchCountry?.value
            });
            if (result?.data?.statusCode === 200) {
                setStateList(result?.data?.data?.list?.map(item => {
                    return {
                        value: item?._id,
                        label: item?.name
                    }
                }))
            }
        } catch (error) {
            setStateList([])
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    };

    useEffect(() => {
        getCountryList()
    }, []);

    useEffect(() => {
        setStateName()
        setValue("State", null)
        if (watchCountry?.value) {
            getStateList()
        }
    }, [watchCountry]);

    /**
        * @function fetchIndustryData
        * @param
        * @description to get all indistry list
    */
    const fetchIndustryData = async () => {
        try {
            const result = await getAllIndustryListService({
                token,
            });
            if (result?.data?.statusCode === 200) {
                setLoading(false)
                setAllIndustryList(result?.data?.data?.list?.map((item) => {
                    return {
                        value: item?._id,
                        label: item?.name
                    }
                }))
            } else {
                setLoading(false)
                setAllIndustryList([])
                toast.error(result?.response?.data?.message || 'Something went wrong')
            }
        } catch (error) {
            setLoading(false)
            setAllIndustryList([])
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    }

    /**
        * @function getRecordedEmissionDataService
        * @param
        * @description to get chart data
    */
    const getRecordedEmissionDataService = async () => {
        try {
            setLoading(true)
            const result = await getRecordedEmissionService({
                token
            });
            if (result?.data?.statusCode === 200) {
                setchartData(result?.data?.data)
                setLoading(false)
            } else {
                setLoading(false)
                setchartData()
                toast.error(result?.response?.data?.message || 'Something went wrong')
            }
        } catch (error) {
            setLoading(false)
            setchartData()
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    }

    /**
        * @function getfilterEmissionService
        * @param
        * @description to get filter data
    */
    const getfilterEmissionService = async () => {
        try {
            setLoading(true)
            const result = await filterEmissionIndustryAndStateService({
                token,
                country: watchCountry?.label || '',
                state: watchState?.label || '',
                industry: industryName?.value || '',
                companySize: companySizeName?.value || '',
                fromDate: startDate ? moment(startDate).format('YYYY-MM-DD') : '',
                toDate: endDate ? moment(endDate).format('YYYY-MM-DD') : '',
            });
            if (result?.data?.statusCode === 200) {
                setchartData(result?.data?.data)
                setLoading(false)
            } else {
                setLoading(false)
                setchartData()
                toast.error(result?.response?.data?.message || 'Something went wrong')
            }
        } catch (error) {
            setLoading(false)
            setchartData()
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    }

    useEffect(() => {
        if (watchCountry || watchState || industryName || companySizeName || startDate || endDate) {
            getfilterEmissionService()
        } else {
            getRecordedEmissionDataService()
        }
    }, [watchCountry, watchState, industryName, companySizeName, startDate, endDate]);

    useEffect(() => {
        fetchIndustryData()
    }, []);

    /**
        * @function handleDigit
        * @param
        * @description to show data in digit
    */
    const handleDigit = async () => {
        setParcentOrDigit('digit')
    };

    /**
        * @function handleParcent
        * @param ()
        * @description to show data in parcent
    */
    const handleParcent = async () => {
        setParcentOrDigit('parcent')
    };

    const VarticalBarData1 = {
        labels: ["Recorded Scope 1 Emissions", "Recorded Scope 2 Emissions", "GHG Emissions Reduction Targets"],
        datasets: [
            {
                data: chartData ? parcentOrDigit === 'digit' ? [chartData?.numberData?.emissionScope1, chartData?.numberData?.emissionScope2, chartData?.numberData?.emissionReductionTarget] : [chartData?.percentageData?.emissionScope1?.toFixed(2), chartData?.percentageData?.emissionScope2?.toFixed(2), chartData?.percentageData?.emissionReductionTarget?.toFixed(2)] : [],
                maxBarThickness: 60,
                backgroundColor: ["#009EDB"],
                hoverBackgroundColor: ["#009EDB"],
                borderRadius: 1
            }
        ]
    };

    const VarticalBarOption = {
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false,
                },
                border: {
                    display: false
                }
            },
            y: {
                ticks: {
                    display: true
                },
                border: {
                    display: false
                },
                grid: {
                    drawBorder: false
                }
            },
            xAxes: [
                {
                    gridLines: {
                        display: false
                    },
                    barPercentage: 0.5,
                    barThickness: 6,
                    maxBarThickness: 8,
                    minBarLength: 2
                }
            ],
            yAxes: [
                {
                    gridLines: {
                        display: false
                    }
                }
            ]
        },
        plugins: {
            legend: {
                display: false
            },
            datalabels: {
                anchor: 'end',
                align: 'start',
                color: "white",
                display: function (context) {
                    return context.dataset.data[context.dataIndex] > 0;
                },
                font: {
                    weight: "bold",
                },
                formatter: (value) => {
                    return parcentOrDigit === 'digit' ? parseInt(value) : `${parseFloat(value).toFixed(2)}%`
                }
            }
        },
        pointLabelFontFamily: "Quadon Extra Bold",
        scaleFontFamily: "Quadon Extra Bold"
    };

    return <Card className="border-0">
        <div className='px-2 py-1'>
            <h3 className='mt-4'><strong>Number & Percentage of Companies with Recorded Emissions and Reduction Targets</strong></h3>
            <div className='d-flex flex-md-wrap flex-column flex-md-row justify-content-between align-items-center'>
                <div className=''>
                    <Selection className={'w-100'} options={countryList} title={'Country'} control={control} name={'Country'} setWatchValue={setCountryName} styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            width: '250px'
                        }),
                    }} />
                </div>
                {watchCountry?.value &&
                    <div className=''>
                        <Selection
                            className={'w-100'}
                            options={stateList}
                            control={control}
                            title={'State'}
                            name={'State'}
                            setWatchValue={setStateName}
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    width: '250px'
                                }),
                            }}
                        />
                    </div>
                }
                <div className=''>
                    <Selection className={'w-100'} options={allIndustryList} title={'Industry'} name={'Industry'} setWatchValue={setIndustryName} styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            width: '250px'
                        }),
                    }} />
                </div>
                <div className=''>
                    <Selection className={'w-100'} options={companySizeList} title={'Company Size'} name={'CompanySize'} setWatchValue={setCompanySizeName} styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            width: '250px'
                        }),
                    }} />
                </div>

                <div className='d-flex flex-column flex-md-row align-items-center'>
                    <div className='me-2 mb-2 mb-md-0'>
                        <CustomDatePicker startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
                    </div>

                    <div className='d-flex align-items-center rounded-pill bg-btn-analytics px-1 py-1'>
                        <Button color='' className={parcentOrDigit === 'parcent' ? 'bg-white rounded-pill text-primary' : 'bg-btn-analytics rounded-pill text-primary'} onClick={handleParcent}>
                            Percentage
                        </Button>
                        <Button color='' className={parcentOrDigit === 'digit' ? 'bg-white rounded-pill text-primary' : 'bg-btn-analytics rounded-pill text-primary'} onClick={handleDigit}>
                            Digit number
                        </Button>
                    </div>

                </div>
            </div>
        </div>

        {isLoading && <div className='text-center h-100'><Spinner></Spinner></div>}
        {!isLoading && <>
            <div className='col-12 p-2'>
                <VerticalBarChart data={VarticalBarData1} options={VarticalBarOption} />
            </div>
            {/* <div className='text-center text-primary'>
                <Link to={'/admin/GHGEmissionCompanyList'}>
                    Click to view the list of companies that have measured scope 1 / scope 2 / set reduction target.
                </Link>
            </div> */}
        </>}
        <TotalScopeChart />
    </Card>
};

export default GHGemission