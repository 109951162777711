
import axios from "axios"

const baseUrl = process.env.REACT_APP_API_BASEURL

/**
* @function getAssociatedCompanies
* @param (authToken)
* @description api to Associated Companies.
*/
export const getAssociatedCompanies = async ({ authToken, userId, offset, limit }) => {
    const result = await axios.get(`${baseUrl}ProGress/associcated/companies?userId=${userId}&offset=${offset}&limit=${limit}`, {
        headers: {
            Authorization: `Bearer ${authToken}`
        }
    })
    return result;
}

/**
* @function associatedSearchListService
* @param (authToken, userId, offset, limit, serach)
* @description api to search Associated Companies.
*/
export const associatedSearchListService = async ({ authToken, userId, offset, limit, search }) => {
    const result = await axios.get(`${baseUrl}ProGress/associcated/companies?userId=${userId}&offset=${offset}&limit=${limit}&search=${search}`, {
        headers: {
            Authorization: `Bearer ${authToken}`
        }
    })
    return result;
}

/**
* @function associatedIndustryFilterListService
* @param (authToken, userId, offset, limit, q)
* @description api to search Associated Companies with industry.
*/
export const associatedIndustryFilterListService = async ({ authToken, userId, offset, limit, q }) => {
    const result = await axios.get(`${baseUrl}ProGress/associcated/companies?userId=${userId}&offset=${offset}&limit=${limit}&q=${q}`, {
        headers: {
            Authorization: `Bearer ${authToken}`
        }
    })
    return result;
}

/**
    * @function getUserProgressAssessmentSummaryService
    * @param ({ token, SubmissionId, userId })
    * @description call post API to start the assesment
*/
export const getUserProgressAssessmentSummaryService = async ({ token, SubmissionId, userId }) => {
    const result = await axios.get(`${baseUrl}ProGress/submission/summeryWithQuestions?SubmissionId=${SubmissionId}&userId=${userId}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    return result
}