import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import classnames from "classnames"

// ** Reactstrap Imports
import {
    Row,
    Col,
    Card,
    Input,
    Label,
    Button,
    CardTitle,
    CardHeader,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    Toast,
    Spinner,
    Badge,
    UncontrolledButtonDropdown
} from 'reactstrap'
//custom
import VerticalBarChart from '../CustomChart/VerticalBarChart'
import RadarChart from '../CustomChart/RadarChart'
import states from '../../../utility/State'
import { getAllIndustryListService } from '../../../services/industryService'
import Selection from '../Selection/Selection'
import { getToken } from '../../../utility/AuthService'

//Redux-Service
import { selectLanguageUse } from '../../../redux/slices/language.Slice'
import { useSelector } from 'react-redux';
import CustomDatePicker from '../CustomDatePicker/CustomDatePicker'
import { filterESGPerformanceIndustryAndStateService, getESGPerformanceDataService } from '../../../services/analyticsServices/advacneAnalyticsService/ESGperformance'
import { demographicsListService } from '../../../services/demographicsService';
import { Eye } from 'react-feather'
import moment from 'moment'
import { useForm } from 'react-hook-form'

const ESGperformance = () => {
    const token = getToken()
    const [countryName, setCountryName] = useState()
    const [stateName, setStateName] = useState()
    const [industryName, setIndustryName] = useState()
    const [allIndustryList, setAllIndustryList] = useState()
    const [isLoading, setLoading] = useState(false)
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [parcentOrDigit, setParcentOrDigit] = useState('parcent')
    // const [parcentOrDigit, setParcentOrDigit] = useState('digit')
    const [chartData, setchartData] = useState()
    const [avgNumData, setAvgNumData] = useState()
    const [avgPercentData, setAvgPercentData] = useState()
    const [allLabels, setAllLabels] = useState()
    const [vendorCount, setVendorCount] = useState()
    const [eye1, setEye1] = useState()
    const [eye2, setEye2] = useState()
    const [eye3, setEye3] = useState()
    const [eye4, setEye4] = useState()
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const languageUse = useSelector(selectLanguageUse || user?.language)

    const {
        register,
        reset,
        handleSubmit,
        watch,
        control,
        formState: { errors },
        setValue
    } = useForm();

    const watchCountry = watch("Country")
    const watchState = watch("State")

    /**
        * @function getCountryList
        * @param
        * @description get country list using api
    */
    const getCountryList = async () => {
        try {
            const result = await demographicsListService({
                type: 'Country',
                typeId: ""
            });
            if (result?.data?.statusCode === 200) {
                setCountryList(result?.data?.data?.list?.map(item => {
                    return {
                        value: item?._id,
                        label: item?.name
                    }
                }))
            }
        } catch (error) {
            setCountryList([])
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    };

    /**
        * @function getStateList
        * @param
        * @description get state list using api
    */
    const getStateList = async () => {
        try {
            const result = await demographicsListService({
                type: 'State',
                typeId: watchCountry?.value
            });
            if (result?.data?.statusCode === 200) {
                setStateList(result?.data?.data?.list?.map(item => {
                    return {
                        value: item?._id,
                        label: item?.name
                    }
                }))
            }
        } catch (error) {
            setStateList([])
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    };

    useEffect(() => {
        getCountryList()
    }, []);

    useEffect(() => {
        setStateName()
        setValue("State", null)
        if (watchCountry?.value) {
            getStateList()
        }
    }, [watchCountry]);

    /**
    * @function fetchIndustryData
    * @param ()
    * @description to get all indistry list
    */
    const fetchIndustryData = async () => {
        try {
            const result = await getAllIndustryListService({
                token,
            });
            if (result?.data?.statusCode === 200) {
                if (result?.data?.data?.list?.length > 0) {
                    setAllIndustryList(result?.data?.data?.list?.map((item) => {
                        return {
                            value: item?._id,
                            label: languageUse === 'English' ? item?.name : item?.name_Malay
                        }
                    }))
                }
            } else {
                setAllIndustryList([])
                toast.error(result?.response?.data?.message || 'Something went wrong')
            }

        } catch (error) {
            setAllIndustryList([])
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }

    }

    /**
* @function getESGPerformanceData
* @param ()
* @description to get chart data
*/
    const getESGPerformanceData = async () => {
        try {
            setLoading(true)
            const result = await getESGPerformanceDataService({
                token,
            });
            if (result?.data?.statusCode === 200) {
                setchartData(result?.data?.data)
                setAllLabels(Object.keys(result?.data?.data?.averageNumber))
                setAvgNumData(Object.values(result?.data?.data?.averageNumber)?.map(item => item?.toFixed(2)))
                setAvgPercentData(Object.values(result?.data?.data?.averagePercent)?.map(item => item?.toFixed(2)))
                setVendorCount(result?.data?.data?.vendorCount)

                let avgNumVal = []
                avgNumVal = Object.values(result?.data?.data?.averageNumber)?.map(item => item?.toFixed(2))
                avgNumVal.shift()
                const tempSum = avgNumVal.reduce((accumulator, currentValue) => parseFloat(accumulator) + parseFloat(currentValue), 0);
                const tempAvg = tempSum / 3
                // const tempArray = avgNumVal.splice(0, 0, tempAvg)
                const tempArray = [tempAvg]?.concat(avgNumVal)
                setAvgNumData(tempArray);
                setLoading(false)
            } else {
                setLoading(false)
                setchartData()
                setAllLabels()
                setAvgNumData()
                setAvgPercentData()
                setVendorCount()
                toast.error(result?.response?.data?.message || 'Something went wrong')
            }
        } catch (error) {
            setLoading(false)
            setchartData()
            setAllLabels()
            setAvgNumData()
            setAvgPercentData()
            setVendorCount()
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    }

    useEffect(() => {
        fetchIndustryData()
        // getESGPerformanceData()
    }, [languageUse]);

    /**
    * @function filterESGPerformanceIndustryAndStateData
    * @param ()
    * @description to get filter data
    */
    const filterESGPerformanceIndustryAndStateData = async () => {
        try {
            setLoading(true)
            const result = await filterESGPerformanceIndustryAndStateService({
                token,
                country: watchCountry?.label || '',
                state: watchState?.label || '',
                industry: industryName?.value || '',
                fromDate: startDate ? moment(startDate).format('YYYY-MM-DD') : '',
                toDate: endDate ? moment(endDate).format('YYYY-MM-DD') : '',
            });
            if (result?.data?.statusCode === 200) {
                setchartData(result?.data?.data)
                setAllLabels(Object.keys(result?.data?.data?.averageNumber))
                setAvgNumData(Object.values(result?.data?.data?.averageNumber)?.map(item => item?.toFixed(2)))
                setAvgPercentData(Object.values(result?.data?.data?.averagePercent)?.map(item => item?.toFixed(2)))
                setVendorCount(result?.data?.data?.vendorCount)

                let avgNumVal = []
                avgNumVal = Object.values(result?.data?.data?.averageNumber)?.map(item => item?.toFixed(2))
                avgNumVal.shift()
                const tempSum = avgNumVal.reduce((accumulator, currentValue) => parseFloat(accumulator) + parseFloat(currentValue), 0);
                const tempAvg = tempSum / 3
                // const tempArray = avgNumVal.splice(0, 0, tempAvg)
                const tempArray = [tempAvg]?.concat(avgNumVal)
                setAvgNumData(tempArray);
                setLoading(false)
            } else {
                setLoading(false)
                setchartData()
                setAllLabels()
                setAvgNumData()
                setAvgPercentData()
                setVendorCount()
                toast.error(result?.response?.data?.message || 'Something went wrong')
            }
        } catch (error) {
            setLoading(false)
            setchartData()
            setAllLabels()
            setAvgNumData()
            setAvgPercentData()
            setVendorCount()
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    }

    useEffect(() => {
        if (watchCountry || watchState || industryName || startDate || endDate) {
            filterESGPerformanceIndustryAndStateData()
        } else {
            getESGPerformanceData()
        }
    }, [watchCountry, watchState, industryName, startDate, endDate]);

    /**
   * @function handleDigit
   * @param ()
   * @description to show data in digit
   */
    const handleDigit = async () => {
        setParcentOrDigit('digit')
    };

    /**
   * @function handleParcent
   * @param ()
   * @description to show data in parcent
   */
    const handleParcent = async () => {
        setParcentOrDigit('parcent')
    };

    /**
   * @function funcShowNameTop
   * @param ()
   * @description to show top user name
   */
    const funcShowNameTop = (index, indexTop) => {
        setEye1(index)
        setEye2(indexTop)
    };

    /**
   * @function funcShowNameBot
   * @param ()
   * @description to show bottom user name
   */
    const funcShowNameBot = (index, indexBot) => {
        setEye3(index)
        setEye4(indexBot)
    };

    // const labelList = ['ESG', 'Social', 'Governance', 'Environment']
    const labelList = allLabels

    const radarData = {
        // labels: ['ESG', 'Governance', 'Social', 'Environment'],
        labels: allLabels,
        datasets: [
            {
                label: 'Radar Chart',
                // data: [23, 20, 12, 18],
                data: parcentOrDigit === 'digit' ? avgNumData : avgPercentData,
                backgroundColor: 'transparent',
                borderColor: '#009EDB',
                borderWidth: 3,
                pointRadius: 0,
                lineTension: 0.5
            },
        ],
    };

    const radarOptions = {
        plugins: {
            legend: {
                display: false
            },
            datalabels: {
                formatter: (value) => {
                    return parcentOrDigit === 'digit' ? parseInt(value) : `${parseFloat(value).toFixed(2)}%`
                },
            }
        },
        scales: {
            r: {
                angleLines: {
                    display: true
                },
                suggestedMin: 0,
                suggestedMax: 100,
                grid: {
                    circular: true
                },
                ticks: {
                    beginAtZero: true,
                    max: 100,
                    min: 0,
                    stepSize: 25
                },
                pointLabels: {
                    font: {
                        size: 12,
                        // family: 'bold'
                    }
                }
            },

            borderRadius: 55
        }
    };

    const VarticalBarData = {
        labels: labelList,

        datasets: [

            {
                data: parcentOrDigit === 'digit' ? avgNumData : avgPercentData,
                // data: [727, 589, 537, 543],
                backgroundColor: ["#000", '#38761D', '#F1C232', '#00F'],
                hoverBackgroundColor: ["#000", '#38761D', '#F1C232', '#00F'],
                borderRadius: 5,
            }
        ]
    };

    const VarticalBarOption = {
        responsive: true,

        scales: {
            x: {
                grid: {
                    display: false,
                },
                border: {
                    display: false,
                },
            },
            y: {
                max: 100,
                ticks: {
                    display: false,
                },
                grid: {
                    display: false,
                },
                border: {
                    display: false,
                },
                title: {
                    display: true,
                    text: 'Average Score%'
                },
            },
            xAxes: [
                {
                    scaleLabel: {
                        display: false
                    },
                    gridLines: {
                        display: false
                    },
                }
            ],
            yAxes: [
                {
                    gridLines: {
                        display: false,
                    },
                }
            ]
        },

        plugins: {
            legend: {
                display: false,
            },

            datalabels: {
                anchor: 'end',
                // clamp: false,
                align: 'start',
                color: "white",
                display: function (context) {
                    return context.dataset.data[context.dataIndex] > 0;
                },
                font: {
                    weight: "bold",
                },
                formatter: (value) => {
                    return parcentOrDigit === 'digit' ? parseInt(value) : `${parseFloat(value).toFixed(2)}%`
                },
            },
        },

        pointLabelFontFamily: "Quadon Extra Bold",
        scaleFontFamily: "Quadon Extra Bold",
    };

    return (
        <>
            <Card className='border-0'>
                <div className=''>
                    <h4>ESG Performance:</h4>
                    <p>Average Scores Across {vendorCount} Vendors</p>
                </div>
                <div className='p-2'>
                    <div className='d-flex flex-md-wrap flex-column flex-md-row justify-content-between align-items-center'>
                        <div className='me-2'>
                            <Selection className={'w-100'} options={countryList} control={control} title={'Country'} name={'Country'} setWatchValue={setCountryName} styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    width: '250px'
                                }),
                            }} />
                        </div>
                        {watchCountry?.value &&
                            <div className='me-2'>
                                <Selection className={'w-100'} options={stateList} control={control} title={'State'} name={'State'} setWatchValue={setStateName} styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        width: '250px'
                                    }),
                                }} />
                            </div>
                        }
                        <div className=''>
                            <Selection className={'w-100'} options={allIndustryList} title={'Industry'} name={'Industry'} setWatchValue={setIndustryName} styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    width: '250px'
                                }),
                            }} />
                        </div>

                        <div className='d-flex flex-column flex-md-row align-items-center'>
                            <div className='me-2 mb-2 mb-md-0'>
                                <CustomDatePicker startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
                            </div>

                            {/* <div className='d-flex align-items-center rounded-pill bg-btn-analytics px-2 py-2'>
                                <Button color='' className={parcentOrDigit === 'parcent' ? 'bg-white rounded-pill text-primary' : 'bg-btn-analytics rounded-pill text-primary'} onClick={handleParcent}>
                                    Percentage
                                </Button>
                                <Button color='' className={parcentOrDigit === 'digit' ? 'bg-white rounded-pill text-primary' : 'bg-btn-analytics rounded-pill text-primary'} onClick={handleDigit}>
                                    Digit number
                                </Button>
                            </div> */}

                        </div>
                    </div>

                </div>
                {isLoading && <div className='text-center h-100'><Spinner></Spinner></div>}

                {!isLoading && <>
                    <div className='row container d-flex align-items-center mb-3'>
                        <div className='col-12 col-md-6'>
                            <VerticalBarChart data={VarticalBarData} options={VarticalBarOption} />
                        </div>
                        <div className='mb-3 p-1 col-12 col-md-6' style={{ height: '430px', width: '430px' }}>
                            <RadarChart data={radarData} options={radarOptions} />
                        </div>
                    </div>

                    <div className="table-responsive p-2">
                        <table className="table table-responsive table-bordered">
                            <thead className="text-color-primary bg-table-head fw-bold">
                                <tr>
                                    <td scope="col fw-bold">
                                        <h5 className='text-center'>Dimension</h5>
                                    </td>
                                    <td scope="col">
                                        <h5>Top 3 Vendors</h5>
                                        <span>(1 = Highest Scoring Vendor)</span>
                                    </td>
                                    <td scope="col">
                                        <h5>Bottom 3 Vendors</h5>
                                        <span>(1 = Lowest Scoring Vendor)</span>
                                    </td>
                                </tr>
                            </thead>

                            <tbody>
                                {labelList?.length > 0 && labelList?.map((item, index) => {
                                    return (

                                        <tr key={index}>
                                            <td className={classnames("py-1 text-center fw-bold", {
                                                'bg-ESG': index === 0, 'bg-Environment': index === 1, 'bg-Social': index === 2, 'bg-Governance': index === 3
                                            })}>
                                                {item}
                                            </td>
                                            <td className=''>
                                                {chartData?.top[item] && chartData?.top[item]?.length > 0 && chartData?.top[item]?.map((itemTop, indexTop) => (
                                                    <>
                                                        <p className='mb-0' key={indexTop}>{`${indexTop + 1}. ${itemTop?.company} ${(eye1 === index && eye2 === indexTop) ? `(${itemTop?.username})` : ''}`} {!(eye1 === index && eye2 === indexTop) && <Eye size={15} role='button' onClick={() => funcShowNameTop(index, indexTop)} />}</p>
                                                    </>

                                                ))}
                                            </td>
                                            <td className=''>
                                                {chartData?.bottom[item] && chartData?.bottom[item]?.length > 0 && chartData?.bottom[item]?.map((itemBot, indexbot) => (
                                                    <>
                                                        <p className='mb-0' key={indexbot}>{`${indexbot + 1}. ${itemBot?.company} ${(eye3 === index && eye4 === indexbot) ? `(${itemBot?.username})` : ''}`} {!(eye3 === index && eye4 === indexbot) && <Eye size={15} role='button' onClick={() => funcShowNameBot(index, indexbot)} />}</p>
                                                    </>
                                                ))}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </>}

            </Card>
        </>
    )
};

export default ESGperformance