
import axios from "axios"
const baseUrl = process.env.REACT_APP_API_BASEURL

/**
  * @function changePasswordService
  * @param (authToken, password, newPassword)
  * @description call API to edit Porfile Service
  */
export const changePasswordService = async ({ authToken, password, newPassword }) => {
    const result = await axios.post(`${baseUrl}auth/change-password`, {
        password,
        newPassword,
    }, {
        headers: {
            Authorization: `Bearer ${authToken}`
        }
    })
    return result;
}