// ** React Imports
import React, { Fragment, useState, forwardRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'

// ** Reactstrap Imports
import {
    Row,
    Col,
    Card,
    Input,
    Label,
    Button,
    CardTitle,
    CardHeader,
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavbarText,
    Container,

} from 'reactstrap'

// Utility-Service
import { getToken } from '../../../utility/AuthService'
import Segwitz from '../../../assets/images/Segwitz.png'

// API-Service
// import { getSingleDataService } from '../../../services/postService'

// Redux-Settings
// import {
//     selectPostDetail,
//     setSingleData,
// } from '../../../redux/slices/postSlice'

const FooterPage = () => {
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(false)
    // const Detail = useSelector(selectPostDetail)
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const token = getToken()
    const navigate = useNavigate()
    const { id } = useParams()

    return (
        <>
            <Container className='mt-auto'>
                <Row>
                    <Col className='d-flex flex-wrap align-items-center justify-content-center justify-content-lg-between my-3' xs='12'>
                        <p className='text-center mb-0'>Copyright © 2024 UN Global Compact Network Malaysia & Brunei. All Rights Reserved.</p>
                        <div className='d-flex flex-column align-items-center mt-3 mt-lg-0'>
                            <span className='text-primary'>Technology Partner</span>
                            <Link target="_blank" to="https://segwitz.com/">
                                <img className='mt-1 img-fluid' src={Segwitz} />
                            </Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>

    )
}

export default FooterPage
