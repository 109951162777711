import React from 'react'

const VerticalStackedBarChartLegends = () => {
    return (
        <>
            <div className='d-flex flex-sm-row flex-column align-items-center mb-2'>
                <div className='pieLegends rounded-circle ms-2 me-1'
                    style={{
                        backgroundColor: '#EA4335'
                    }}>
                </div>
                <span>No</span>

                <div className='pieLegends rounded-circle ms-2 me-1'
                    style={{
                        backgroundColor: '#34A853'
                    }}>
                </div>
                <span>Yes</span>
            </div>
          
        </>
    )
};

export default VerticalStackedBarChartLegends