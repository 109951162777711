import axios from "axios"
const baseUrl = process.env.REACT_APP_API_BASEURL

/**
* @function getOverallDimensionData
* @param {  authToken, id }
* @description This function is used to get over all user dimension data list
*/
export const getOverallDimensionData = async ({ authToken, id, userId }) => {
    const result = axios.get(`${baseUrl}users/associcated/companies/usersLevel?dimension=${id}&userId=${userId}`, {
        headers: {
            Authorization: `Bearer ${authToken}`,
        }
    })
    return result;
};