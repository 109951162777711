import axios from "axios"
const baseUrl = process.env.REACT_APP_API_BASEURL

/**
    * @function getProgressAssessmentSummaryService
    * @param ({ authToken, SubmissionId )
    * @description call post API to start the assesment
*/
export const getProgressAssessmentSummaryService = async ({ authToken, SubmissionId }) => {
    const result = await axios.get(`${baseUrl}ProGress/submission/summery?SubmissionId=${SubmissionId}`, {
        headers: {
            Authorization: `Bearer ${authToken}`
        }
    })
    return result
}