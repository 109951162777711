
import axios from "axios"
const baseUrl = process.env.REACT_APP_API_BASEURL

/**
    * @function uploadDocumentServices
    * @param (authToken, submissionId, dimension, name, description)
    * @description call API to upload submission document
*/
export const uploadDocumentServices = async ({ authToken, submissionId, dimension, name, description }) => {
    const result = await axios.post(`${baseUrl}users/submission/document`, {
        submissionId,
        dimension,
        name,
        description,
    }, {
        headers: {
            Authorization: `Bearer ${authToken}`
        }
    })
    return result;
};

/**
    * @function uploadPDFServices
    * @param (authToken, id, files)
    * @description call API to upload pdf for assessment submission
*/
export const uploadPDFServices = async ({ authToken, id, files }) => {
    const result = await axios.patch(`${baseUrl}users/submission/document/upload?id=${id}`, files, {
        headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'multipart/form-data'
        }
    })
    return result;
}

/**
    * @function uploadProgressDocumentServices
    * @param { authToken, submissionId, proGress_Dimension, name, description }
    * @description call API to upload progress submission document
*/
export const uploadProgressDocumentServices = async ({ authToken, submissionId, proGress_Dimension, name, description }) => {
    const result = await axios.post(`${baseUrl}ProGress/submission/document`, {
        submissionId,
        proGress_Dimension,
        name,
        description
    }, {
        headers: {
            Authorization: `Bearer ${authToken}`
        }
    })
    return result
}

/**
    * @function uploadProgressPDFServices
    * @param (authToken, id, files)
    * @description call API to upload pdf for progress assessment submission
*/
export const uploadProgressPDFServices = async ({ authToken, id, files }) => {
    const result = await axios.patch(`${baseUrl}ProGress/submission/files/upload?id=${id}`, files, {
        headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'multipart/form-data'
        }
    })
    return result;
}