import React from 'react'
import OtpVerify from "../../components/auth/OtpVerify"
import Header from '../../components/auth/Header'
import Footer from '../../components/layouts/Footer'

function OtpVerifyPage() {
    return (
        <>
            <Header />
            <OtpVerify />
            <Footer />
        </>
    )
};

export default OtpVerifyPage