import React, { useState, useEffect } from 'react'
import toast from 'react-hot-toast'
import classnames from "classnames"
import { Home } from "react-feather"
import _12 from "../../../assets/images/analytics/indicatorImages/12.png"
import _13 from "../../../assets/images/analytics/indicatorImages/13.png"
import _16 from "../../../assets/images/analytics/indicatorImages/16.png"
import _5 from "../../../assets/images/analytics/indicatorImages/5.png"
import _6 from "../../../assets/images/analytics/indicatorImages/6.png"
import _7 from "../../../assets/images/analytics/indicatorImages/7.png"
import _8 from "../../../assets/images/analytics/indicatorImages/8.png"
// import { _12, _13, _16, _5, _6, _7, _8 } from "../../../../assets/images/Images"

// ** Reactstrap Imports
import {
    Row,
    Col,
    Card,
    Input,
    Label,
    Button,
    CardTitle,
    CardHeader,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    Toast,
    Spinner,
    Badge,
    UncontrolledButtonDropdown
} from 'reactstrap'
import { getSDGmappingService } from '../../../services/analyticsServices/advacneAnalyticsService/SDGmapping'
import { getImageIndicatorService } from '../../../services/getImageServices'
import { getToken } from '../../../utility/AuthService'
import ViewAllComapnyModal from '../modal/ViewAllComapnyModal'

const SDGmapping = () => {
    const [isLoading, setLoading] = useState(false)
    const baseUrl = process.env.REACT_APP_API_BASEURL
    const token = getToken()
    const [chartData, setchartData] = useState()
    const [indicatorLabels, setIndicatorLabels] = useState()
    const [imageList, setImageList] = useState([])
    const [fieldData, setFieldDataList] = useState()
    const [showViewAll, setShowViewAllShow] = useState(false);

    /**
    * @function viewAllComapny
    * @param ()
    * @description to view all company
    */
    const viewAllComapny = (data) => {
        setFieldDataList(data)
        setShowViewAllShow(!showViewAll)
    }

    /**
    * @function getBreakdownESGData
    * @param ()
    * @description to get chart data
    */
    const getSDGmappingData = async () => {
        try {
            setLoading(true)
            const result = await getSDGmappingService({
                token,
            });
            if (result?.data?.statusCode === 200) {
                setchartData(result?.data?.data)
                setIndicatorLabels(Object.keys(result?.data?.data?.sdgMapping))
                setLoading(false)
            } else {
                setLoading(false)
                setchartData()
                setIndicatorLabels()
                toast.error(result?.response?.data?.message || 'Something went wrong')
            }
        } catch (error) {
            setLoading(false)
            setchartData()
            setIndicatorLabels()
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    }

    useEffect(() => {
        getSDGmappingData()
    }, []);

    // const labelList = [
    //     {
    //         name: 'Climate Action',
    //         image: <img src={_13} width={150} height={150} />
    //     },
    //     {
    //         name: 'Energy Efficiency',
    //         image: <img src={_7} width={150} height={150} />
    //     },
    //     {
    //         name: 'Water',
    //         image: <img src={_6} width={150} height={150} />
    //     },
    //     {
    //         name: 'Waste',
    //         image: <img src={_12} width={150} height={150} />
    //     },
    //     {
    //         name: 'Human Rights',
    //         image: <img src={_8} width={150} height={150} />
    //     },
    //     {
    //         name: 'Living Wages',
    //         image: <img src={_5} width={150} height={150} />
    //     },
    //     {
    //         name: 'Gender Equality',
    //         image: <img src={_13} width={150} height={150} />
    //     },
    //     {
    //         name: 'Anti - Corruption',
    //         image: <img src={_16} width={150} height={150} />
    //     },
    // ]

    return (
        <>
            <Card className='border-0'>
                <div className='p-2'>
                    <div className='d-flex flex-column flex-md-row justify-content-between align-items-center'>
                        <div className=''>
                            <h4>SDG Mapping</h4>
                            <p>Number of Vendors Corresponding to Each Level of Readiness to Contribute to the SDGs</p>
                        </div>

                        {/* <div className='text-end d-flex align-items-start rounded-pill bg-btn-analytics px-1'>
                            <Button className={parcentOrDigit === 'parcent' ? "float-start analyticsConvertButtonClicked login-btn-min-width mb-4" : "float-start analyticsConvertButton login-btn-min-width mb-4"} variant='outline-primary' onClick={handleParcent}>
                                {isLoading ? <Spinner variant='dark' size='sm' /> : 'Percentage'}
                            </Button>
                          
                            <Button className={parcentOrDigit === 'digit' ? "float-start analyticsConvertButtonClicked login-btn-min-width mb-4" : "float-start analyticsConvertButton login-btn-min-width mb-4"} variant='outline-primary' onClick={handleDigit}>
                                {isLoading ? <Spinner variant='dark' size='sm' /> : 'Digit number'}
                            </Button>
                        </div> */}
                    </div>

                </div>
                {isLoading && <div className="d-flex justify-content-center align-items-center" style={{ height: '70vh' }}>
                    <Spinner animation="grow" />
                </div>}

                {!isLoading && <div className="table-responsive p-2">
                    <table className="table table-responsive table-bordered">
                        <thead className="text-color-primary bg-table-head fw-bold">
                            <tr>
                                <td scope="col fw-bold">
                                    <h5>ESG Indicator</h5>
                                </td>
                                <td scope="col">
                                    <h5>SDG</h5>

                                </td>
                                <td scope="col">
                                    <h5>Laggard</h5>
                                </td>
                                <td scope="col">
                                    <h5>Beginner</h5>
                                </td>
                                <td scope="col">
                                    <h5>Intermediate</h5>
                                </td>
                                <td scope="col">
                                    <h5>Advanced</h5>
                                </td>
                                <td scope="col">
                                    <h5>Leader</h5>
                                </td>
                            </tr>
                        </thead>

                        {/* <tbody>
                            {labelList?.length > 0 && labelList?.map((item, index) => {
                                return (
                                            
                                    <tr key={index}>
                                        <td className='bg-table-head fw-bold'>
                                            <h5>{item?.name}</h5>
                                        </td>
                                        <td className='p-0'>
                                            {item?.image}
                                        </td>
                                        <td className=''>
                                            <p className='fw-bold mt-0'>Number of Vendors: 4</p>
                                            <div className='d-flex flex-column'>
                                                <span>- Jurutera Adda</span>
                                                <span>- Pine Contract &  Supplies</span>
                                                <span>- Shorefield</span>
                                                <span>- Tokoh Ilham</span>

                                            </div>
                                            
                                        </td>
                                        <td className=''>
                                            Dummy
                                        </td>
                                        <td className=''>
                                            Dummy
                                        </td>
                                        <td className=''>
                                            Dummy
                                        </td>
                                        <td className=''>
                                            Dummy
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody> */}

                        <tbody>
                            {indicatorLabels?.length > 0 && indicatorLabels?.map((item, index) => {

                                return (

                                    <tr key={index} className=''>
                                        <td className='bg-table-head fw-bold align-top pt-1'>
                                            <h5>{item}</h5>
                                        </td>
                                        {/* <td className='p-0 d-flex justify-content-center align-items-top'>
                                            {imageList && <img src={imageList[index]} alt={imageList[index]} width={180}/>}
                                        </td> */}
                                        <td className='p-0 d-flex justify-content-center align-items-top'>
                                            {imageList && <img src={`data:image/jpeg;base64,${chartData?.sdgMapping[item]?.file}`} alt={''} width={180} />}
                                        </td>
                                        <td className='align-top position-relative'>
                                            <div className='d-flex flex-column justify-content-start'>
                                                <p className='fw-bold mt-0'>Number of Vendors: {chartData?.sdgMapping[item]?.Laggard?.length}</p>
                                                <div className='d-flex flex-column mb-5'>
                                                    {chartData?.sdgMapping[item]?.Laggard?.length > 0 && chartData?.sdgMapping[item]?.Laggard?.slice(0, 3).map((itemField, indexField) => (
                                                        <span key={indexField}>{`- ${itemField?.company}`}</span>
                                                    ))}

                                                </div>
                                                {chartData?.sdgMapping[item]?.Laggard?.length > 3 && <Button color='primary' className='my-1 position-absolute bottom-0' onClick={() => viewAllComapny(chartData?.sdgMapping[item]?.Laggard)}>View all</Button>}
                                            </div>

                                        </td>
                                        <td className='align-top position-relative'>
                                            <div className='d-flex flex-column justify-content-start'>
                                                <p className='fw-bold mt-0'>Number of Vendors: {chartData?.sdgMapping[item]?.Beginner?.length}</p>
                                                <div className='d-flex flex-column mb-5'>
                                                    {chartData?.sdgMapping[item]?.Beginner?.slice(0, 3).map((itemField, indexField) => (
                                                        <span key={indexField}>{`- ${itemField?.company}`}</span>
                                                    ))}
                                                </div>
                                                {chartData?.sdgMapping[item]?.Beginner?.length > 3 && <Button color='primary' className='my-1 position-absolute bottom-0' onClick={() => viewAllComapny(chartData?.sdgMapping[item]?.Beginner)}>View all</Button>}
                                            </div>
                                        </td>
                                        <td className='align-top position-relative'>
                                            <div>
                                                <p className='fw-bold mt-0'>Number of Vendors: {chartData?.sdgMapping[item]?.Intermediate?.length}</p>
                                                <div className='d-flex flex-column mb-5'>
                                                    {chartData?.sdgMapping[item]?.Intermediate?.slice(0, 3).map((itemField, indexField) => (
                                                        <span key={indexField}>{`- ${itemField?.company}`}</span>
                                                    ))}
                                                </div>
                                                {chartData?.sdgMapping[item]?.Intermediate?.length > 3 && <Button color='primary' className='my-1 position-absolute bottom-0' onClick={() => viewAllComapny(chartData?.sdgMapping[item]?.Intermediate)}>View all</Button>}
                                            </div>

                                        </td>
                                        <td className='align-top position-relative'>
                                            <p className='fw-bold mt-0'>Number of Vendors: {chartData?.sdgMapping[item]?.Advanced?.length}</p>
                                            <div className='d-flex flex-column mb-5'>
                                                {chartData?.sdgMapping[item]?.Advanced?.slice(0, 3).map((itemField, indexField) => (
                                                    <span key={indexField}>{`- ${itemField?.company}`}</span>
                                                ))}
                                            </div>
                                            {chartData?.sdgMapping[item]?.Advanced?.length > 3 && <Button color='primary' className='my-1 position-absolute bottom-0' onClick={() => viewAllComapny(chartData?.sdgMapping[item]?.Advanced)}>View all</Button>}
                                        </td>
                                        <td className='align-top position-relative'>
                                            <div>
                                                <p className='fw-bold mt-0'>Number of Vendors: {chartData?.sdgMapping[item]?.Leader?.length}</p>
                                                <div className='d-flex flex-column mb-5'>
                                                    {chartData?.sdgMapping[item]?.Leader?.slice(0, 3).map((itemField, indexField) => (
                                                        <span key={indexField}>{`- ${itemField?.company}`}</span>
                                                    ))}
                                                </div>
                                                <div>
                                                    {chartData?.sdgMapping[item]?.Leader?.length > 3 && <Button color='primary' className='my-1 position-absolute bottom-0' onClick={() => viewAllComapny(chartData?.sdgMapping[item]?.Leader)}>View all</Button>}
                                                </div>
                                            </div>

                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>

                    </table>
                </div>}

            </Card>
            <ViewAllComapnyModal show={showViewAll} handleClose={viewAllComapny} fieldData={fieldData} />
        </>
    )
};

export default SDGmapping