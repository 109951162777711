// ** Reducers Imports
// import layout from "./layout"
// import navbar from "./navbar"
import auth from './slices/auth.Slice'
import submission from './slices/submission.Slice'
import language from './slices/language.Slice'
// import users from './slices/usersSlice'
  
const rootReducer = {
    auth,
    submission,
    language
}

export default rootReducer
