import React, { useState } from 'react'
import { Col, Container, Row } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
// icon & image
import { MdKeyboardBackspace } from 'react-icons/md';
// custom
import NavbarComponent from "../../components/layouts/Navbar";
import Footer from '../../components/layouts/Footer'
import AdvancedAnalyticsProgressSidebar from '../../components/advanceAnalyticsProgress/AdvancedAnalyticsProgressSidebar';
import MaturityLevels from '../../components/advanceAnalyticsProgress/MaturityLevels/MaturityLevels';
import GHGemission from '../../components/advanceAnalyticsProgress/GHGemission';
import ProgressActions from '../../components/advanceAnalyticsProgress/ProgressActions';

const AdvanceAnalyticsProgressPage = () => {
    const navigate = useNavigate()
    const [activeKey, setActiveKey] = useState('1')
    return (
        <Container>
            <NavbarComponent />
            <Row className='p-2'>
                <Col>
                    <p role='button' onClick={() => navigate(-1)}><MdKeyboardBackspace size={20} className='me-2' />Back</p>
                    {/* <h4>Supply Chain ESG</h4> */}
                    <AdvancedAnalyticsProgressSidebar activeKey={activeKey} setActiveKey={setActiveKey} />
                </Col>
            </Row>
            <Row className='borde p-2'>
                <Col className="mt-3">
                    {activeKey === "1" && <MaturityLevels />}
                    {activeKey === "2" && <GHGemission />}
                    {activeKey === "3" && <ProgressActions />}
                </Col>
            </Row>
            <Footer />
        </Container>
    )
}

export default AdvanceAnalyticsProgressPage