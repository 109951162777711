import { useState } from 'react';
import { Modal, ModalFooter, ModalHeader, ModalBody, Form, Label, Button, FormFeedback, Spinner } from "reactstrap";
import { useForm, Controller } from 'react-hook-form'
import { Toaster, toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";

// Custom Component
import InputPasswordToggle from '../../components/input-password-toggle';

// Redux-Functions
import { selectLanguageUse } from '../../../redux/slices/language.Slice';

// Utility Service
import { getToken, getUser } from '../../../utility/AuthService';

// API Service
import { changePasswordService } from '../../../services/changePasswordService';

const ChangePasswordModal = (props) => {
    const navigate = useNavigate()
    const token = getToken()
    const user = getUser()

    const languageUse = useSelector(selectLanguageUse || user?.language);

    const [isLoading, setLoading] = useState(false)

    const { control, handleSubmit, formState: { errors } } = useForm();

    /**
        * @function handleChangePassword
        * @param data
        * @description handle change password
    */
    const handleChangePassword = async (data) => {
        let result = []
        try {
            setLoading(true)
            result = await changePasswordService({
                authToken: token,
                password: data?.currentPassword,
                newPassword: data?.newPassword
            });

            if (result?.data?.statusCode === 200) {
                setLoading(false)
                toast.success('Password Changed')
                navigate('/login')
            } else {
                setLoading(false)
                toast.error(result?.response?.data?.message || 'Something went wrong')
            }
        } catch (error) {
            setLoading(false)
            toast.error(result?.response?.data?.message || 'Something went wrong')
        }
    };

    /**
        * @function onSubmit
        * @param data
        * @description used to submit
    */
    const onSubmit = (data) => {
        if (data?.newPassword === data?.confirmPassword) {
            handleChangePassword(data)
        } else {
            toast.error(languageUse === 'English' ? 'New Password and Confirm Password must be same' : 'Kata Laluan Baru dan Sahkan Kata Laluan mestilah sama')
        }
    };

    return <>
        <div>
            <Toaster />
        </div>
        <div>
            <Modal
                isOpen={props.modal}
                toggle={props.toggle}
                backdropClassName='bg-dark'
            >
                <ModalHeader className='border-0'>{languageUse === 'English' ? 'Change Password' : 'Tukar kata laluan'}</ModalHeader>
                <Form className='auth-login-form mt-3' onSubmit={handleSubmit(onSubmit)}>
                    <ModalBody>
                        <div className='mb-4'>
                            <Label className='form-label' for='currentPassword'>
                                {languageUse === 'English' ? 'Current Password' : 'Kata Laluan Semasa'}
                            </Label>
                            <Controller
                                id='currentPassword'
                                name='currentPassword'
                                defaultValue=''
                                control={control}
                                render={({ field }) => (
                                    <InputPasswordToggle {...field} placeholder={languageUse === 'English' ? 'Current Password' : 'Kata Laluan Semasa'} invalid={errors.currentPassword && true} />
                                )}
                                rules={{
                                    required: languageUse === 'English' ? "Current Password is required" : "Kata Laluan Semasa diperlukan"
                                }}
                            />
                            {errors.currentPassword && <FormFeedback>{errors.currentPassword.message}</FormFeedback>}
                        </div>

                        <div className='mb-4'>
                            <Label className='form-label' for='newPassword'>
                                {languageUse === 'English' ? 'New Password' : 'Kata laluan baharu'}
                            </Label>
                            <Controller
                                id='newPassword'
                                name='newPassword'
                                defaultValue=''
                                control={control}
                                render={({ field }) => (
                                    <InputPasswordToggle {...field} placeholder={languageUse === 'English' ? 'New Password' : 'Kata laluan baharu'} invalid={errors.newPassword && true} />
                                )}
                                rules={{
                                    required: languageUse === 'English' ? "New Password is required" : "Kata Laluan Baharu diperlukan"
                                }}
                            />
                            {errors.newPassword && <FormFeedback>{errors.newPassword.message}</FormFeedback>}
                        </div>

                        <div className='mb-3'>
                            <Label className='form-label' for='confirmPassword'>
                                {languageUse === 'English' ? 'Confirm New Password' : 'Sahkan Kata Laluan Baharu'}
                            </Label>
                            <Controller
                                id='confirmPassword'
                                name='confirmPassword'
                                defaultValue=''
                                control={control}
                                render={({ field }) => (
                                    <InputPasswordToggle {...field} placeholder={languageUse === 'English' ? 'Confirm New Password' : 'Sahkan Kata Laluan Baharu'} invalid={errors.confirmPassword && true} />
                                )}
                                rules={{
                                    required: languageUse === 'English' ? "Confirm New Password is required" : "Sahkan Kata Laluan Baharu diperlukan"
                                }}
                            />
                            {errors.confirmPassword && <FormFeedback>{errors.confirmPassword.message}</FormFeedback>}
                        </div>
                    </ModalBody>
                    <ModalFooter className='d-flex justify-content-between'>
                        <Button className=" authButton login-btn-min-width mb-4" variant='outline-primary' onClick={props.toggle} >
                            {languageUse === 'English' ? 'Cancel' : 'Batal'}
                        </Button>
                        <Button type='submit' className=" authButton login-btn-min-width mb-4" variant='outline-primary'>
                            {isLoading ? <Spinner variant='dark' size='sm' /> : languageUse === 'English' ? 'Save' : 'Simpan'}
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </div>
    </>
};

export default ChangePasswordModal;