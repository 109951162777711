import React from 'react'
import ForgotPass from "../../components/auth/ForgotPass"
import Header from '../../components/auth/Header'
import Footer from '../../components/layouts/Footer'

function ForgotPasswordpage() {
  return (
    <>
      <Header />
      <ForgotPass />
      <Footer />
    </>
  )
}

export default ForgotPasswordpage