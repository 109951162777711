import React, { useState } from 'react'
import { useNavigate, useParam, Link, useLocation } from "react-router-dom";
import {
    Row,
    Col,
    Container,
    Label,
    Spinner,
    Toast,
    Input,
    CardBody,
    CardTitle,
    CardHeader,
    FormFeedback,
} from 'reactstrap'

import { Button } from 'react-bootstrap'
import * as Icons from 'react-feather'
import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import toast, { Toaster } from 'react-hot-toast'

// auth/input-password-toggle
import InputPasswordToggle from '../../components/input-password-toggle/index'

//API-Service
import { resetPasswordService } from '../../../services/authService'

function ResetPassword() {

    const [isLoading, setLoading] = useState(false)
    const navigate = useNavigate()
    const { state } = useLocation();
    const resetToken = state?.Token;

    const LoginValidation = yup.object().shape({
        password: yup.string().required('Please enter password'),
        confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match')
    })

    // ** Hooks
    const {
        reset,
        control,
        handleSubmit,
        formState: { errors }
    } = useForm({ mode: 'onChange', resolver: yupResolver(LoginValidation) })

    /**
    * @function handleResetPassword
    * @param (data)
    * @description set new password and reset the previous one.
    */
    const handleResetPassword = async (data) => {
        setLoading(true)
        try {
            const result = await resetPasswordService({
                password: data.password,
                resetToken
            });

            const saveMessage = result.data.data.message;

            if (result?.data.statusCode === 200) {
                reset()
                setLoading(false)
                navigate('/login');
            } else {
                toast.success(result.data.statusCode)
                setLoading(false)
            };
            return false;

        } catch (error) {
            setLoading(false);
            toast.error(error.response.data.message || 'Something went wrong')
        };
    }

    /**
    * @function onSubmit
    * @param (data)
    * @description to submit
    */
    const onSubmit = data => {
        if (Object.values(data).every(field => field.length > 0)) {
            handleResetPassword(data)
        }
    }

    return (
        <Container>
            <div>
                <Toaster />
            </div>
            <Row>
                <div className="user-login d-flex flex-column mx-auto p-4 p-lg-5 pb-lg-3 authDiv text-start col-lg-5 col-md-6 col-sm-8">
                    <h1 className="sign">Reset Password</h1>
                    <span className='login-border' />
                    <div>
                        {/* onSubmit={handleSubmit(onSubmit)} */}
                        <form className='auth-login-form mt-2' onSubmit={handleSubmit(onSubmit)}>
                            <div className='my-5'>
                                <div className='pb-4'>

                                    <div className='mb-4'>
                                        <Label className='form-label custom-label' for='password'>
                                            New Password
                                        </Label>
                                        <Controller
                                            id='password'
                                            name='password'
                                            defaultValue=''
                                            control={control}
                                            render={({ field }) => (
                                                <InputPasswordToggle {...field} placeholder='Password' invalid={errors.password && true} />
                                            )}
                                        />
                                        {errors.password && <FormFeedback>{errors.password.message}</FormFeedback>}
                                    </div>
                                    <div className='mb-4'>
                                        <Label className='form-label custom-label' for='confirmPassword'>
                                            Confirm New Password
                                        </Label>
                                        <Controller
                                            id='confirmPassword'
                                            name='confirmPassword'
                                            defaultValue=''
                                            control={control}
                                            render={({ field }) => (
                                                <InputPasswordToggle {...field} placeholder='confirmPassword' invalid={errors.confirmPassword && true} />
                                            )}
                                        />
                                        {errors.confirmPassword && <FormFeedback>{errors.confirmPassword.message}</FormFeedback>}
                                    </div>


                                </div>
                            </div>
                            <div className=''>
                                <Button className="float-end authButton login-btn-min-width" variant='outline-primary' color="primary" type="submit">
                                    {isLoading ? <Spinner variant='dark' size='sm' /> : 'Reset Password'}
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>

            </Row>
        </Container>
    );
}

export default ResetPassword