import { useState, useEffect } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { User, Lock, Power, Edit } from "react-feather";

// Custom Component
import NavbarComponent from "../components/layouts/Navbar";
import Footer from '../components/layouts/Footer'
import EditProfileModal from '../components/modal/EditProfileModal';
import AccessCodeModal from '../components/modal/AccessCodeModal';
import ChangePasswordModal from '../components/modal/ChangePasswordModal';

// Redux-Functions
import { submissionId } from '../../redux/slices/submission.Slice';
import { selectLanguageUse } from '../../redux/slices/language.Slice';

// Utility Service
import { getToken, getUser } from "../../utility/AuthService";

// Images
import ProfileIcon from '../../assets/images/profile/ProfileIcon.svg';

// API Service
import { getImageIndicatorService } from '../../services/indicatorService';
import { logoutService } from '../../services/authService';

const ProfilePage = () => {
    const authToken = getToken()
    const user = getUser()

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const languageUse = useSelector(selectLanguageUse || user?.language);

    const [editProfilemodal, setEditProfilemodal] = useState(false);
    const [changePasswordModal, setChangePasswordModal] = useState(false);
    const [accessCodeModal, setAccessCodeModal] = useState(false);
    const [profileImage, setProfileImage] = useState();

    /**
        * @function logout
        * @params
        * @description used to logout the user
    */
    const logOut = () => {
        logoutService();
        dispatch(submissionId());
        navigate("/login");
    };

    /**
        * @function handleEditProfileModal
        * @params
        * @description set the visibility of edit profile modal
    */
    const handleEditProfileModal = () => {
        setEditProfilemodal(!editProfilemodal)
    };

    /**
        * @function handleChangePasswordModal
        * @params
        * @description set the visibility of change password modal
    */
    const handleChangePasswordModal = () => {
        setChangePasswordModal(!changePasswordModal)
    };

    /**
        * @function handleAccessCodeModal
        * @params
        * @description set the visibility of access code modal
    */
    const handleAccessCodeModal = () => {
        setAccessCodeModal(!accessCodeModal)
    };

    /**
        * @function getImage
        * @param
        * @description  get profile image
    */
    const getImage = async () => {
        try {
            const result = await getImageIndicatorService({
                token: authToken,
                file: user?.file
            })
            const temp = `data:image/${user?.file?.filename?.split(".")[1]};base64, ${Buffer.from(result?.data, "base64").toString("base64")}`
            setProfileImage(temp)
        } catch (error) {
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    };

    useEffect(() => {
        if (user?.file) {
            getImage()
        }
    }, [user?.file]);

    return <>
        <div>
            <Toaster />
        </div>

        <NavbarComponent />

        <div className='d-flex align-items-center justify-content-center'>
            <div className='p-2 w-50 bg-white d-flex flex-column align-items-center border rounded p-3'>
                <div className='border-bottom pb-3 w-100 text-center'>
                    <div className='mb-1'>
                        {profileImage ? <img className='comment-logo rounded-circle' src={profileImage} alt='proPic' width={80} height={80} /> : <img className='img-fluid comment-logo rounded-circle me-3' src={ProfileIcon} alt='Avatar' />
                        }
                    </div>
                    <div>
                        <h4 className='fw-bold'>{user?.username}</h4>
                        <span className='fs-14 mb-2'>{user?.email}</span>
                    </div>
                </div>

                <div className='p-2 w-100'>
                    <div className='py-2 fw-bold' role='button' onClick={handleEditProfileModal}>
                        <Edit className='me-1' />{languageUse === 'English' ? 'Edit Profile' : 'Sunting profil'}
                    </div>
                    <div className='py-2 fw-bold' role='button' onClick={handleChangePasswordModal} >
                        <Lock className='me-1' />{languageUse === 'English' ? 'Change Password' : 'Tukar kata laluan'}
                    </div>
                    <div className='py-2 fw-bold' role='button' onClick={handleAccessCodeModal}>
                        <User className='me-1' />{languageUse === 'English' ? 'Access Code' : 'Kod akses'}
                    </div>
                    <div className='py-2 fw-bold' role='button' onClick={logOut}>
                        <Power className='me-1' />{languageUse === 'English' ? 'Logout' : 'Log keluar'}
                    </div>
                </div>
            </div>
        </div>

        <Footer />

        {/* Edit Profile Modal */}
        <EditProfileModal modal={editProfilemodal} toggle={handleEditProfileModal} profileImage={profileImage} />

        {/* Change Password Modal */}
        <ChangePasswordModal modal={changePasswordModal} toggle={handleChangePasswordModal} />

        {/* Access Code Modal */}
        <AccessCodeModal modal={accessCodeModal} toggle={handleAccessCodeModal} />
    </>
};

export default ProfilePage