import React, { useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// import { Document, Page } from 'react-pdf';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const ShowPdfModal = (props) => {
    const AWS_Url = process.env.REACT_APP_AWSURL
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageDimensions, setPageDimensions] = useState({ width: 0, height: 0 });
    const modalBodyRef = useRef(null);

    useEffect(() => {
        function updateDimensions() {
            if (modalBodyRef.current) {
                const modalBodyWidth = modalBodyRef.current.clientWidth;
                const modalBodyHeight = modalBodyRef.current.clientHeight;
                setPageDimensions({
                    width: modalBodyWidth,
                    height: modalBodyHeight
                });
            }
        }

        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, [props.show]);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    return (
        <>
            <Modal show={props.show} onHide={() => {
                props.handleClose()
                setPageNumber(1)
            }} size='lg'>
                {/* <Modal show={props.show} onHide={props.handleClose} fullscreen={true}> */}
                <Modal.Header closeButton>
                    {/* <Modal.Title>Are you sure to submit all the answers</Modal.Title> */}
                </Modal.Header>
                <Modal.Body ref={modalBodyRef}>
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        {/* <Document file="https://ungc.s3.ap-southeast-1.amazonaws.com/08da212f-b975-4eb2-88e5-9445f1f79902-Segwitz%20x%20Name%20-%20NDA%20India.pdf" onLoadSuccess={onDocumentLoadSuccess}> */}
                        <Document file={`${AWS_Url}${props?.pdfItem}`} onLoadSuccess={onDocumentLoadSuccess}>
                            <Page pageNumber={pageNumber} width={pageDimensions?.width} />
                        </Document>
                        <p>
                            Page {pageNumber} of {numPages}
                        </p>

                    </div>
                    <div className='d-flex justify-content-between align-items-center'>
                        <Button onClick={() => setPageNumber(pageNumber - 1)} disabled={pageNumber <= 1}>Prev</Button>
                        <Button onClick={() => setPageNumber(pageNumber + 1)} disabled={pageNumber >= numPages}>Next</Button>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        props.handleClose()
                        setPageNumber(1)
                    }}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
        </>
    )
};

export default ShowPdfModal