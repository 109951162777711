import { forwardRef } from "react";
import { Row, Col, Container } from "reactstrap";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from "chart.js";
import { BsCheckCircleFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom'
import parse from 'html-react-parser';
import moment from 'moment';
import classNames from "classnames";

//redux
import { selectLanguageUse } from '../../../redux/slices/language.Slice';

// Images
import LogoProgress from '../../../assets/images/LogoProgress.png';
import Segwitz from '../../../assets/images/Segwitz.png'

import useWindowSize from '../../../hooks/windowSize';

// Utility-Service
import { getUser } from "../../../utility/AuthService";

const ProgressPdfReportPage = forwardRef((props, ref) => {
    ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

    const size = useWindowSize()
    const profileData = props?.profileData;

    const user = getUser()
    const AWS_Url = process.env.REACT_APP_AWSURL
    const languageUse = useSelector(selectLanguageUse || user?.language)

    const box = document.getElementsByClassName("pdfSize");
    const pdfSize = box[0]?.offsetWidth

    return <Container>
        <div ref={ref} className={`px-4 pdfFont ${props.pdfContent ? 'd-block' : 'd-none'}`}>
            <table className="report-container" width={1100}>
                <thead className="report-header">
                    <tr>
                        <th className="report-header-cell">
                            <div className="header-info" style={{
                                paddingRight: size?.width > 1400 ? '0rem' : '0rem'
                            }}>
                                <div className="d-flex justify-content-between mb-3">
                                    <h2 className="pt-3">{languageUse === 'English' ? 'PRO-GRESS Climate Action Maturity Report' : 'Laporan Kematangan Tindakan Iklim PRO-GRESS'}</h2>
                                    <img src={LogoProgress} alt="LogoProgress" width={500} />
                                </div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tfoot className="report-footer">
                    <tr>
                        {' '}
                    </tr>
                </tfoot>
                <tbody className="report-content">
                    <tr>
                        <td className="report-content-cell">
                            <div className="main">
                                <div className="article">
                                    <div className="profile" style={{ marginRight: size?.width > 1400 ? '0rem' : '0rem' }}>
                                        <Row className="profile p-2 mb-0">
                                            <Col className="" md="6" sm="6">
                                                <p className="mb-0">{languageUse === 'English' ? 'Current Report Dated' : 'Laporan Semasa Bertarikh'} : <span className="bold">{moment(profileData?.updatedAt).format("DD-MM-YYYY")}</span></p>
                                                <p className="mb-0">{languageUse === 'English' ? 'Company' : 'Syarikat'} : <span className="bold">{profileData?.company}</span></p>
                                                <p className="mb-0">{languageUse === 'English' ? 'Company Registration Number' : 'Nombor pendaftaran syarikat'} : <span className="fw-bold">{profileData?.companyReg}</span></p>
                                                <p className="mb-0">{languageUse === 'English' ? 'Username' : 'Nama pengguna'} : <span className="bold">{profileData?.username}</span></p>
                                                <p className="mb-0">
                                                    {languageUse === 'English' ? 'State' : 'Negeri'} : <span className="fw-bold">{profileData?.state}</span>
                                                </p>
                                            </Col>
                                            <Col className="" md="6" sm="6">
                                                <p className="mb-0">{languageUse === 'English' ? 'Last Submission Date' : 'Tarikh Penyerahan Terakhir'} :{" "}<span className="bold">{props?.lastSubmission ? moment(props?.lastSubmission).format("DD-MM-YYYY") : `${languageUse === 'English' ? 'No Previous Submission' : 'Tiada Penyerahan Sebelumnya'}`}</span></p>
                                                <p className="mb-0">{languageUse === 'English' ? 'Industry' : 'Industri'} :{" "}<span className="bold">{languageUse === 'English' ? profileData?.industry?.name : profileData?.industry?.name_Malay}</span></p>
                                                <p className="mb-0">
                                                    {languageUse === 'English' ? 'Email' : 'E-mel'} : <span className="bold">{profileData?.email}</span>
                                                </p>
                                                <p className="mb-0">
                                                    {languageUse === 'English' ? 'Country' : 'Negara'} : <span className="fw-bold">{profileData?.country}</span>
                                                </p>
                                                <p className="mb-0">
                                                    {languageUse === 'English' ? 'City' : 'Bandar'} : <span className="fw-bold">{profileData?.city}</span>
                                                </p>
                                            </Col>
                                        </Row>
                                    </div>

                                    <Row className="p-0 my-2 d-block" style={{ marginRight: size?.width > 1400 ? '0rem' : '0rem', marginTop: '10rem' }}>
                                        <table className="table table-bordered border-0">
                                            <tbody>
                                                <tr className="border-0">
                                                    <td rowSpan={2} className="ps-3 border text-center fw-bold">{languageUse === 'English' ? "Your Climate Action Maturity Level:" : "Tahap Kematangan Tindakan Iklim Anda:"}</td>
                                                    <td className="ps-3 border-0 text-center fw-bold text-dark" style={{ backgroundColor: '#E06666' }}>{languageUse === 'English' ? 'Laggard' : 'Laggard'}</td>
                                                    <td className="ps-3 border-0 text-center fw-bold text-dark" style={{ backgroundColor: '#FCE5CD' }}>{languageUse === 'English' ? 'Beginner' : 'Pemula'}</td>
                                                    <td className="ps-3 border-0 text-center fw-bold text-dark" style={{ backgroundColor: '#D9EAD3' }}>{languageUse === 'English' ? 'Intermediate' : 'Pertengahan'}</td>
                                                    <td className="ps-3 border-0 text-center fw-bold text-white" style={{ backgroundColor: '#B6D7A8' }}>{languageUse === 'English' ? 'Advanced' : 'Maju'}</td>
                                                    <td className="ps-3 border-0 text-center fw-bold text-white" style={{ backgroundColor: '#93C47D' }}>{languageUse === 'English' ? 'Leader' : 'Ketua'} </td>
                                                </tr>
                                                <tr className="border-0">
                                                    <td className={classNames("ps-3 border-0 text-center fw-bold", { 'bg-Laggard': props?.readiness?.leval === 'Laggard' || props?.readiness?.leval_Malay === 'Laggard', })}>{languageUse === 'English' ? props?.readiness?.leval === 'Laggard' ? <BsCheckCircleFill className="text-white" /> : '' : props?.readiness?.leval_Malay === 'Laggard' ? <BsCheckCircleFill className="text-white" /> : ''}</td>
                                                    <td className={classNames("ps-3 border-0 text-center fw-bold", { 'bg-Beginner': props?.readiness?.leval === 'Beginner' || props?.readiness?.leval_Malay === 'Pemula', })}>{languageUse === 'English' ? props?.readiness?.leval === 'Beginner' ? <BsCheckCircleFill className="text-white" /> : '' : props?.readiness?.leval_Malay === 'Pemula' ? <BsCheckCircleFill className="text-white" /> : ''}</td>
                                                    <td className={classNames("ps-3 border-0 text-center fw-bold", { 'bg-Intermediate': props?.readiness?.leval === 'Intermediate' || props?.readiness?.leval_Malay === 'Pertengahan', })}>{languageUse === 'English' ? props?.readiness?.leval === 'Intermediate' ? <BsCheckCircleFill className="text-white" /> : '' : props?.readiness?.leval_Malay === 'Pertengahan' ? <BsCheckCircleFill className="text-white" /> : ''}</td>
                                                    <td className={classNames("ps-3 border-0 text-center fw-bold", { 'bg-Advanced': props?.readiness?.leval === 'Advanced' || props?.readiness?.leval_Malay === 'Maju', })}>{languageUse === 'English' ? props?.readiness?.leval === 'Advanced' ? <BsCheckCircleFill className="text-white" /> : '' : props?.readiness?.leval_Malay === 'Maju' ? <BsCheckCircleFill className="text-white" /> : ''}</td>
                                                    <td className={classNames("ps-3 border-0 text-center fw-bold", { 'bg-Leader': props?.readiness?.leval === 'Leader' || props?.readiness?.leval_Malay === 'Ketua', })}>{languageUse === 'English' ? props?.readiness?.leval === 'Leader' ? <BsCheckCircleFill className="text-white" /> : '' : props?.readiness?.leval_Malay === 'Ketua' ? <BsCheckCircleFill className="text-white" /> : ''}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Row>

                                    {/* For Small device */}
                                    <Row className="p-0 my-2 d-none">
                                        <h5 className="fw-bold">{languageUse === 'English' ? "Your Climate Action Maturity Level:" : "Tahap Kematangan Tindakan Iklim Anda:"}</h5>
                                        <table className="table table-bordered">
                                            <tbody>
                                                <tr>
                                                    <td className="ps-3 fw-bold text-dark" style={{ backgroundColor: '#E06666' }}>{languageUse === 'English' ? 'Laggard' : 'Laggard'}</td>
                                                    <td className="ps-3 fw-bold text-center ">{languageUse === 'English' ? props?.readiness?.leval === 'Laggard' ? <BsCheckCircleFill /> : '' : props?.readiness?.leval_Malay === 'Laggard' ? <BsCheckCircleFill /> : ''}</td>
                                                </tr>

                                                <tr>
                                                    <td className="ps-3 fw-bold text-dark" style={{ backgroundColor: '#FCE5CD' }}>{languageUse === 'English' ? 'Beginner' : 'Pemula'}</td>
                                                    <td className="ps-3 fw-bold text-center ">{languageUse === 'English' ? props?.readiness?.leval === 'Beginner' ? <BsCheckCircleFill /> : '' : props?.readiness?.leval_Malay === 'Pemula' ? <BsCheckCircleFill /> : ''}</td>
                                                </tr>

                                                <tr>
                                                    <td className="ps-3 fw-bold text-dark" style={{ backgroundColor: '#D9EAD3' }}>{languageUse === 'English' ? 'Intermediate' : 'Pertengahan'}</td>
                                                    <td className="ps-3 fw-bold text-center ">{languageUse === 'English' ? props?.readiness?.leval === 'Intermediate' ? <BsCheckCircleFill /> : '' : props?.readiness?.leval_Malay === 'Pertengahan' ? <BsCheckCircleFill /> : ''}</td>
                                                </tr>

                                                <tr>
                                                    <td className="ps-3 fw-bold text-white" style={{ backgroundColor: '#B6D7A8' }}>{languageUse === 'English' ? 'Advanced' : 'Maju'}</td>
                                                    <td className="ps-3 fw-bold text-center ">{languageUse === 'English' ? props?.readiness?.leval === 'Advanced' ? <BsCheckCircleFill /> : '' : props?.readiness?.leval_Malay === 'Maju' ? <BsCheckCircleFill /> : ''}</td>
                                                </tr>

                                                <tr>
                                                    <td className="ps-3 fw-bold text-white" style={{ backgroundColor: '#93C47D' }}>{languageUse === 'English' ? 'Leader' : 'Ketua'} </td>
                                                    <td className="ps-3 fw-bold text-center ">{languageUse === 'English' ? props?.readiness?.leval === 'Leader' ? <BsCheckCircleFill /> : '' : props?.readiness?.leval_Malay === 'Ketua' ? <BsCheckCircleFill /> : ''}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Row>

                                    <Row className="pt-2" style={{ backgroundColor: '#F4FBFE', borderRadius: '5px', border: '1px solid #009EDB', marginRight: size?.width > 1400 ? '0rem' : '0rem', marginTop: props?.questionAns?.length > 0 && props?.questionAns[2]?.["Business Strategy Integration"]?.length > 0 && props?.questionAns[2]?.["Business Strategy Integration"][0]?.yesAnswers?.length > 2 ? '5rem' : '5px' }}>
                                        <h5 className="fw-bold mb-2">{profileData?.company}</h5>
                                        <span className="textColor mb-2">{languageUse === 'English' ? props?.readiness?.leval : props?.readiness?.leval_Malay} :</span>

                                        <p className="">{languageUse === 'English' ? props?.readiness?.description : props?.readiness?.description_Malay}</p>
                                    </Row>

                                    <Row className="justify-content-between align-items-center" style={{ marginTop: props?.questionAns?.length > 0 && props?.questionAns[2]?.["Business Strategy Integration"]?.length > 0 && props?.questionAns[2]?.["Business Strategy Integration"][0]?.yesAnswers?.length > 12 ? '5px' : '5rem', marginBottom: props?.questionAns?.length > 0 && props?.questionAns[2]?.["Business Strategy Integration"]?.length > 0 && props?.questionAns[2]?.["Business Strategy Integration"][0]?.yesAnswers?.length > 12 ? '0rem' : '.5rem', marginRight: size?.width > 1400 ? '0rem' : '0rem' }}>
                                        <Col
                                            className="history-text-color mb-4 mb-md-0"
                                            md="7"
                                            sm="7"
                                        >
                                            <h4 className="ps-3 pt-2 mb-4">{languageUse === 'English' ? "Score of Dimensions" : "Skor Dimensi"}</h4>
                                            <div className="mt-5 d-flex justify-content-center align-items-center">
                                                <Bar data={props?.data} options={props?.options} height={props?.questionAns?.length > 0 && props?.questionAns[2]?.["Business Strategy Integration"]?.length > 0 && props?.questionAns[2]?.["Business Strategy Integration"][0]?.yesAnswers?.length > 6 ? 270 : 150}></Bar>
                                            </div>
                                        </Col>

                                        <Col
                                            className="history-text-color px-2 my-3"
                                            md="5"
                                            sm="5"
                                        >
                                            <div className="">
                                                <table className="table table-bordered mb-0">
                                                    <thead className="table-head">
                                                        <tr className="history-text-color">
                                                            <th className="ps-3 border-end-0" scope="col">{languageUse === 'English' ? "GHG emissions" : "Pelepasan GHG"}</th>
                                                            <th className="ps-3 border-start-0" scope="col"></th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr>
                                                            <td className="ps-3">{`Scope 1`}</td>
                                                            {props?.questionAns?.length > 0 && props?.questionAns?.length > 0 && props?.questionAns[1]?.["GHG emissions reduction"]?.length > 0 && props?.questionAns[1]?.["GHG emissions reduction"]?.length > 0 && props?.questionAns[1]?.["GHG emissions reduction"][0]?.yesAnswers?.length > 0 ? props?.questionAns[1]?.["GHG emissions reduction"][0]?.yesAnswers?.map((item, index) => (
                                                                <td key={index} className="ps-3">{`${item} MTCO2e`}</td>
                                                            )) : <td className="ps-3">N/A</td>}
                                                        </tr>
                                                        <tr>
                                                            <td className="ps-3">{`Scope 2`}</td>
                                                            {props?.questionAns?.length > 0 && props?.questionAns[1]?.["GHG emissions reduction"]?.length > 0 && props?.questionAns[1]?.["GHG emissions reduction"][1]?.yesAnswers?.length > 0 ? props?.questionAns[1]?.["GHG emissions reduction"][1]?.yesAnswers?.map((item, index) => (
                                                                <td key={index} className="ps-3">{`${item} MTCO2e`}</td>
                                                            )) : <td className="ps-3">N/A</td>}
                                                        </tr>
                                                        <tr>
                                                            <td className="ps-3">{languageUse === 'English' ? `GHG emission reduction target` : "Sasaran pengurangan pelepasan GHG"}</td>
                                                            {props?.questionAns?.length > 0 && props?.questionAns[1]?.["GHG emissions reduction"]?.length > 0 && props?.questionAns[1]?.["GHG emissions reduction"][2]?.answer ? <td className="ps-3 d-flex flex-column">{props?.questionAns?.length > 0 && props?.questionAns[1]?.["GHG emissions reduction"]?.length > 0 && props?.questionAns[1]?.["GHG emissions reduction"][2]?.answer === "Yes" ? `${props?.questionAns[1]?.["GHG emissions reduction"][2]?.answer}, \n ${props?.questionAns[1]?.["GHG emissions reduction"][2]?.yesAnswers} MTCO2e` : props?.questionAns[1]?.["GHG emissions reduction"][2]?.answer}</td> : <td className="ps-3">N/A</td>}
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="">
                                                <table className="table table-bordered mb-0">
                                                    <thead className="table-head">
                                                        <tr className="history-text-color">
                                                            <th className="ps-3" scope="col">{languageUse === 'English' ? "Certification" : "Pensijilan"}</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {props?.questionAns?.length > 0 && props?.questionAns[2]?.["Business Strategy Integration"]?.length > 0 && props?.questionAns[2]?.["Business Strategy Integration"][0]?.yesAnswers?.length > 0 ? props?.questionAns[2]?.["Business Strategy Integration"][0]?.yesAnswers?.map((item, index) => (
                                                            <tr key={index}>
                                                                <td className="ps-3">{item}</td>
                                                            </tr>
                                                        )) : <tr>
                                                            <td className="ps-3">N/A</td>
                                                        </tr>}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Col>
                                    </Row>


                                    <Row className="border" style={{ marginTop: props?.questionAns?.length > 0 && props?.questionAns[2]?.["Business Strategy Integration"]?.length > 0 && props?.questionAns[2]?.["Business Strategy Integration"][0]?.yesAnswers?.length > 10 ? '1rem' : '.5rem', marginBottom: '0rem', marginRight: size?.width > 1400 ? '0rem' : '0rem', pageBreakBefore: (props?.questionAns?.length > 0 && props?.questionAns[2]?.["Business Strategy Integration"]?.length > 0 && props?.questionAns[2]?.["Business Strategy Integration"][0]?.yesAnswers?.length < 12 && props?.questionAns[2]?.["Business Strategy Integration"][0]?.yesAnswers?.length > 3) && 'always' }} >
                                        <h4 className="pt-3">{languageUse === 'English' ? "Peer Comparison Bar" : "Carta Perbandingan Rakan Industri"}</h4>
                                        <div className="mt">

                                            <Bar style={{ paddingRight: size?.width > 1400 ? '0rem' : '0rem' }} data={props?.horizontalData} options={props?.horoizontalOptions} height={100}></Bar>
                                        </div>
                                    </Row>

                                    <Row className="" style={{ marginRight: size?.width > 1400 ? '0rem' : '0rem', pageBreakBefore: props?.questionAns?.length > 0 && props?.questionAns[2]?.["Business Strategy Integration"]?.length > 0 && props?.questionAns[2]?.["Business Strategy Integration"][0]?.yesAnswers?.length < 4 && 'always', marginTop: props?.questionAns?.length > 0 && props?.questionAns[2]?.["Business Strategy Integration"]?.length > 0 && props?.questionAns[2]?.["Business Strategy Integration"][0]?.yesAnswers?.length > 8 ? '5rem' : '5rem' }}>
                                        <h4 className="pt-2 my-3 fw-bold text-center">{languageUse === 'English' ? "CLIMATE TRANSITION ACTION PLAN" : "PELAN TINDAKAN TRANSISI IKLIM"}</h4>
                                        <p>{languageUse === 'English' ? `This is an overview of Company X climate transition action plan. The action plan is developed according to 3 focus areas: climate governance, emissions reduction and business strategy integration. The management of Company X hereby commits to implement the following action plan within 18 months from the date of signature.`.replace(/Company X/g, profileData?.company) : `Ini ialah gambaran keseluruhan pelan tindakan peralihan iklim Syarikat X. Pelan tindakan dibangunkan mengikut 3 bidang fokus: tadbir urus iklim, pengurangan pelepasan dan penyepaduan strategi perniagaan. Pihak pengurusan Syarikat X dengan ini komited untuk melaksanakan pelan tindakan berikut dalam tempoh 18 bulan dari tarikh tandatangan.`.replace(/Syarikat X/g, profileData?.company)}</p>

                                    </Row>

                                    {props?.actionPlan?.length > 0 && props?.actionPlan?.map((itemAction, indexAction) => {
                                        return <div key={indexAction} style={{ marginRight: size?.width > 1400 ? '0rem' : '0rem' }}>
                                            <Row>
                                                <div className={classNames({ "bg-Progress": props?.scorePercent <= 50, "bg-Leader": props?.scorePercent > 50 })}>
                                                    <h4 className="pt-2 mb-3 fw-bold text-white">
                                                        {languageUse === 'English' ? itemAction?.proGress_Dimension?.name ? parse(itemAction?.proGress_Dimension?.name) : '--' : itemAction?.proGress_Dimension?.name_Malay ? parse(itemAction?.proGress_Dimension?.name_Malay) : '--'}
                                                    </h4>
                                                    <p className="text-white">
                                                        {languageUse === 'English' ? itemAction?.actionPlan ? parse(itemAction?.actionPlan) : '--' : itemAction?.actionPlan_Malay ? parse(itemAction?.actionPlan_Malay) : '--'}
                                                    </p>

                                                </div>

                                                <div className="my-2 px-0 table-responsive">
                                                    <table className="table table-bordered">
                                                        <thead className={classNames('table-head', { "bg-Progress": props?.scorePercent <= 50, "bg-Leader": props?.scorePercent > 50 })}>
                                                            <tr className="text-white">
                                                                <th className="ps-3" scope="col">{languageUse === 'English' ? "Action Plan Objective" : "Objektif Pelan Tindakan"}</th>
                                                                <th className="ps-3" scope="col">{languageUse === 'English' ? "Key Actions" : "Tindakan Utama"}</th>
                                                                <th className="ps-3" scope="col">{languageUse === 'English' ? "Outcomes" : "Hasil"}</th>
                                                                <th className="ps-3" scope="col">{languageUse === 'English' ? "Timeline" : "Garis masa"}</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            <>
                                                                <tr>
                                                                    <td className="ps-3">
                                                                        {languageUse === 'English' ? itemAction?.objective ? parse(itemAction?.objective) : '--' : itemAction?.resources_Malay ? parse(itemAction?.resources_Malay) : '--'}
                                                                    </td>

                                                                    <td>
                                                                        {languageUse === 'English' ? itemAction?.process ? parse(itemAction?.process) : '--' : itemAction?.process_Malay ? parse(itemAction?.process_Malay) : '--'}
                                                                    </td>

                                                                    <td>
                                                                        {languageUse === 'English' ? itemAction?.output ? parse(itemAction?.output) : '--' : itemAction?.output_Malay ? parse(itemAction?.output_Malay) : '--'}
                                                                    </td>

                                                                    <td>
                                                                        {languageUse === 'English' ? itemAction?.timeline ? parse(itemAction?.timeline) : '--' : itemAction?.timeline_Malay ? parse(itemAction?.timeline_Malay) : '--'}
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </Row>
                                        </div>
                                    })}

                                    <Row style={{ marginBottom: '5rem', paddingRight: size?.width > 1400 ? '0rem' : '0rem' }}>
                                        <p>{languageUse === 'English' ? "This Report Has Been Checked and Approved By :" : "Laporan Ini Telah Disemak dan Diluluskan Oleh :"}</p>
                                        <Col className="d-flex flex-column" md="7" sm="7">
                                            <p>{languageUse === 'English' ? "Name" : "Nama"}  : {props?.approveInfo?.name}</p>
                                            <p>{languageUse === 'English' ? "Designation" : "Jawatan"}  : {props?.approveInfo?.desigantion}</p>
                                            <p>{languageUse === 'English' ? "Date" : "Tarikh"}  : {props?.approveInfo?.date}</p>
                                        </Col>

                                        <Col className="d-flex flex-column justify-content-end" md="5" sm="5">
                                            <div>
                                                {props?.userSign && <div className="">
                                                    <img src={`${AWS_Url}${props?.userSign}`} width={150} alt="sign" />
                                                </div>}
                                                <hr className="w-100"></hr>
                                                <p>{languageUse === 'English' ? 'Signature' : 'Tandatangan'}</p>
                                            </div>

                                        </Col>
                                    </Row>

                                    <Row className="" style={{ paddingRight: size?.width > 1400 ? '0rem' : '0rem' }}>
                                        <h4 className="pt-2 mt-5 mb-1 fw-bold">{languageUse === 'English' ? 'DISCLAIMER' : 'PENAFIAN'}</h4>
                                        <p>
                                            {languageUse === 'English' ? `The report and score calculations provided on this website are based on self-declared data submissions by ${profileData?.company}. The information provided in this report shall not be relied upon as a substitute for professional advice. UNGCMYB and its partner shall not be held liable for any errors, omissions, or inaccuracies in the data provided by ${profileData?.company} or for any actions taken in reliance on such information. ${profileData?.company} is solely responsible for the accuracy and completeness of the information they provide.` : `Laporan dan pengiraan markah yang disediakan di tapak web ini adalah berdasarkan penyerahan data yang diisytiharkan sendiri oleh ${profileData?.company}. Maklumat yang diberikan dalam laporan ini tidak boleh dipercayai sebagai pengganti nasihat profesional. UNGCMYB dan rakan kongsinya tidak akan bertanggungjawab atas sebarang kesilapan, peninggalan atau ketidaktepatan dalam data yang disediakan oleh ${profileData?.company} atau untuk sebarang tindakan yang diambil dengan bergantung pada maklumat tersebut. ${profileData?.company} bertanggungjawab sepenuhnya untuk ketepatan dan kesempurnaan maklumat yang mereka berikan.`}
                                        </p>
                                        <p className="fs-14">For more information & support, reach out to <a className="text-decoration-none" href="mailto:strategicpartnerships@alliancefg.com">strategicpartnerships@alliancefg.com</a></p>
                                    </Row>

                                    <Row style={{ marginBottom: '5rem', paddingRight: size?.width > 1400 ? '0rem' : '0rem' }}>
                                        <Col className='d-flex flex-wrap align-items-center justify-content-center justify-content-sm-between' xs='12'>
                                            <span className='text-center mb-0'>Copyright © 2024 UN Global Compact Network Malaysia & Brunei. All Rights Reserved.</span>
                                            <div className='d-flex flex-column align-items-center mt-3 mt-sm-0'>
                                                <span className='text-primary'>Technology Partner</span>
                                                <Link target="_blank" to="https://segwitz.com/">
                                                    <img className='mt-1 img-fluid' src={Segwitz} />
                                                </Link>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </Container>
});

export default ProgressPdfReportPage;