import React from 'react'
// Custom Component
import NavbarComponent from "../../components/layouts/Navbar";
import Analytics from '../../components/analytics/Analytics_test';
import Index from '../../components/analytics/Index';
import Footer from '../../components/layouts/Footer'

const AnalyticsPage = () => {
    return (
        <>
            <NavbarComponent />
            {/* <Analytics /> */}
            <Index />
            <Footer />
        </>
    )
};

export default AnalyticsPage