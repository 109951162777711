import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import classnames from "classnames"

// ** Reactstrap Imports
import {
    Row,
    Col,
    Card,
    Input,
    Label,
    Button,
    CardTitle,
    CardHeader,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    Toast,
    Spinner,
    Badge,
    UncontrolledButtonDropdown
} from 'reactstrap'
//custom
import VerticalBarChart from '../CustomChart/VerticalBarChart'
import states from '../../../utility/State'
import { getAllIndustryListService } from '../../../services/industryService'
import Selection from '../Selection/Selection'
import { getToken } from '../../../utility/AuthService'
//Redux-Service
import { selectLanguageUse } from '../../../redux/slices/language.Slice'
import { useSelector } from 'react-redux';
import CustomDatePicker from '../CustomDatePicker/CustomDatePicker'
import { filterBreakdownESGIndustryAndStateService, getBreakdownESGService } from '../../../services/analyticsServices/advacneAnalyticsService/BreakdownESG'
import { Eye } from 'react-feather'
import moment from 'moment'
import { filterOverallIndustryAndStateService, getOverallService } from '../../../services/analyticsServices/advacneAnalyticsService/Overall'
import { useForm } from 'react-hook-form'
import { demographicsListService } from '../../../services/demographicsService'

const Overall = () => {
    // const [topVendorList, setTopVendorList] = useState([]);
    // const [bottomVendorList, setBottomVendorList] = useState([]);
    const token = getToken()
    const [stateName, setStateName] = useState()
    const [industryName, setIndustryName] = useState()
    const [allIndustryList, setAllIndustryList] = useState()
    const [isLoading, setLoading] = useState(false)
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [parcentOrDigit, setParcentOrDigit] = useState('digit')
    const [chartData, setchartData] = useState()
    const [vendorCount, setVendorCount] = useState()
    const [avgNumData, setAvgNumData] = useState()
    const [avgPercentData, setAvgPercentData] = useState()
    const [numberToParcentData, setNumberToParcentDataData] = useState()
    const [parcentToParcentData, setParcentToParcentData] = useState()
    const [allLabels, setAllLabels] = useState()
    const [allValues, setAllValues] = useState()
    const [eye1, setEye1] = useState()
    const [eye2, setEye2] = useState()
    const [eye3, setEye3] = useState()
    const [eye4, setEye4] = useState()
    const languageUse = useSelector(selectLanguageUse || user?.language)
    const [countryList, setCountryList] = useState([]);
    const [countryId, setCountryId] = useState()

    const {
        register,
        reset,
        handleSubmit,
        watch,
        control,
        formState: { errors },
        setValue
    } = useForm();

    const watchCountry = watch("Country")

    /**
        * @function getCountryList
        * @param
        * @description get country list using api
    */
    const getCountryList = async () => {
        try {
            const result = await demographicsListService({
                type: 'Country',
                typeId: ""
            });
            if (result?.data?.statusCode === 200) {
                setCountryList(result?.data?.data?.list?.map(item => {
                    return {
                        value: item?._id,
                        label: item?.name
                    }
                }))
            }
        } catch (error) {
            setCountryList([])
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    };

    useEffect(() => {
        getCountryList()
    }, []);

    /**
    * @function getOverallService
    * @param ()
    * @description to get chart data
    */
    const getOverallDataService = async () => {
        try {
            setLoading(true)
            const result = await getOverallService({
                token,
                country: watchCountry?.value || ''
            });
            if (result?.data?.statusCode === 200) {
                setchartData(result?.data?.data)
                setAllLabels(Object.keys(result?.data?.data))
                setAllValues(Object.values(result?.data?.data))
                setLoading(false)
            } else {
                setLoading(false)
                setchartData()
                setAllLabels()
                setAllValues()
                toast.error(result?.response?.data?.message || 'Something went wrong')
            }
        } catch (error) {
            setLoading(false)
            setchartData()
            setAllLabels()
            setAllValues()
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    }

    /**
    * @function filterOverallIndustryAndStateService
    * @param ()
    * @description to get filter data
    */
    const getfilterOverallIndustryAndStateService = async () => {
        try {
            setLoading(true)
            const result = await filterOverallIndustryAndStateService({
                token,
                state: stateName?.label || '',
                industry: industryName?.value || '',
                fromDate: startDate ? moment(startDate).format('YYYY-MM-DD') : '',
                toDate: endDate ? moment(endDate).format('YYYY-MM-DD') : '',
                country: watchCountry?.value || ''
            });
            if (result?.data?.statusCode === 200) {
                setchartData(result?.data?.data)
                setAllLabels(Object.keys(result?.data?.data))
                setAllValues(Object.values(result?.data?.data))
                setLoading(false)
            } else {
                setLoading(false)
                setchartData()
                setAllLabels()
                setAllValues()
                toast.error(result?.response?.data?.message || 'Something went wrong')
            }
        } catch (error) {
            setLoading(false)
            setchartData()
            setAllLabels()
            setAllValues()
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    }

    useEffect(() => {
        if (stateName || industryName || startDate || endDate || watchCountry?.value) {
            getfilterOverallIndustryAndStateService()
        } else {
            getOverallDataService()
        }
    }, [stateName, industryName, startDate, endDate || watchCountry?.value]);

    /**
   * @function handleDigit
   * @param ()
   * @description to show data in digit
   */
    const handleDigit = async () => {
        setParcentOrDigit('digit')
    };

    /**
   * @function handleParcent
   * @param ()
   * @description to show data in parcent
   */
    const handleParcent = async () => {
        setParcentOrDigit('parcent')
    };

    const VarticalBarData = {
        labels: allLabels,
        // labels: states?.map(item => item?.label),

        datasets: [

            {
                data: allValues,
                // data: states?.map(item => item?.value),
                backgroundColor: ["#009EDB"],
                hoverBackgroundColor: ["#009EDB"],
                borderRadius: 5,
            }
        ]
    };

    const VarticalBarOption = {
        // responsive: true,
        maintainAspectRatio: false,
        // tooltips: {
        //     yAlign: 'bottom'
        // },

        scales: {
            x: {
                grid: {
                    display: false,
                },
                border: {
                    display: false,
                },
            },
            y: {
                // max: 100,
                ticks: {
                    display: false,
                },
                grid: {
                    display: false,
                },
                border: {
                    display: false,
                },
                title: {
                    display: true,
                    text: 'Submission Count'
                },
            },
            xAxes: [
                {
                    scaleLabel: {
                        display: false
                    },
                    gridLines: {
                        display: false
                    },
                }
            ],
            yAxes: [
                {
                    gridLines: {
                        display: false,
                    },
                }
            ]
        },

        plugins: {
            legend: {
                display: false,
            },

            datalabels: {
                anchor: 'end',
                // clamp: false,
                align: 'start',
                color: "white",
                display: function (context) {
                    return context.dataset.data[context.dataIndex] > 0;
                },
                font: {
                    weight: "bold",
                },
                formatter: (value) => {
                    return parcentOrDigit === 'digit' ? parseInt(value) : `${parseFloat(value).toFixed(2)}%`
                },
            },
        },

        pointLabelFontFamily: "Quadon Extra Bold",
        scaleFontFamily: "Quadon Extra Bold",
    };

    return (
        <>
            <Card className='border-0'>
                <div className=''>
                    <h4>Demographics:</h4>
                </div>
                <div className='px-2 py-1'>
                    <Selection className={'w-25'} options={countryList} control={control} title={'Country'} name={'Country'} setWatchValue={setCountryId} styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            width: '250px'
                        }),
                    }} />
                </div>
                <div className='p-2'>
                    <div className='d-flex flex-column flex-md-row justify-content-between align-items-center'>
                        {/* <div className=''>
                            <Selection className={'w-100'} options={states} title={'State'} name={'State'} setWatchValue={setStateName} styles={{
                                control: (baseStyles, state) => ({
                                ...baseStyles,
                                width: '250px'
                                }),
                            }} />
                        </div> */}
                        {/* <div className=''>
                            <Selection className={'w-100'} options={allIndustryList} title={'Industry'} name={'Industry'} setWatchValue={setIndustryName} styles={{
                                control: (baseStyles, state) => ({
                                ...baseStyles,
                                width: '250px'
                                }),
                            }} />
                        </div> */}

                        <div className='d-flex flex-column flex-md-row align-items-center'>
                            {/* <div className='me-2 mb-2 mb-md-0'>
                                <CustomDatePicker startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
                            </div> */}

                            {/* <div className='d-flex align-items-center rounded-pill bg-btn-analytics px-2 py-2'>
                                <Button color='' className={parcentOrDigit === 'parcent' ? 'bg-white rounded-pill text-primary' : 'bg-btn-analytics rounded-pill text-primary'} onClick={handleParcent}>
                                    Percentage
                                </Button>
                                <Button color='' className={parcentOrDigit === 'digit' ? 'bg-white rounded-pill text-primary' : 'bg-btn-analytics rounded-pill text-primary'} onClick={handleDigit}>
                                    Digit number
                                </Button>
                            </div> */}

                        </div>
                    </div>

                </div>
                {isLoading && <div className='text-center h-100'><Spinner></Spinner></div>}
                {!isLoading && <>
                    {/* <div className='col-12 p-2' id='chratScroll'> */}
                    <div className='col-12 p-2' style={{ height: '400px' }}>
                        <VerticalBarChart data={VarticalBarData} options={VarticalBarOption} />
                    </div>
                    <div className='text-center mt-1'>State</div>
                </>}
            </Card>
        </>
    )
};

export default Overall