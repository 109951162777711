import React, { useState, useEffect } from 'react'

//third party
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Row } from 'reactstrap';
import toast, { Toaster } from 'react-hot-toast'
// Utility-Service
import { getToken, getUser } from '../../../utility/AuthService'
//API
import { getAverageIndicatorData } from '../../../services/analyticsServices/indicatorAverageService';

const IndicatorScoreAnalytics = (props) => {
    const token = getToken()
    const user = getUser()
    const [isLoading, setLoading] = useState(false)
    const [indicatorAverageList, setIndicatorAverageList] = useState([])


    /**
  * @function fetchIndicatorAverageData
  * @param ()
  * @description to get all indistry list
  */
    const fetchIndicatorAverageData = async () => {
        try {
            const result = await getAverageIndicatorData({
                authToken: token,
                userId: user?._id
            });
            if (result?.data?.statusCode === 200) {
                setLoading(false)
                setIndicatorAverageList(result?.data?.data?.indicaters)
            } else {
                setLoading(false)
                setIndicatorAverageList([])
                toast.error(result?.response?.data?.message || 'Something went wrong')
            }
        } catch (error) {
            setLoading(false)
            setIndicatorAverageList([])
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    }

    useEffect(() => {
        fetchIndicatorAverageData()
    }, []);

    const indicatorChartOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: "Score of Category",
            },
            datalabels: {
                anchor: 'end',
                align: 'top',
                color: "black",

                // display: function (context) {
                //     return context.dataset.data[context.dataIndex] > 0;
                // },

                font: {
                    weight: "bold",
                },
                formatter: (value, ctx) => {
                    let percentage = 0
                    percentage = `${value}`;
                    // percentage = `${value}%`;
                    return percentage;
                },
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                border: {
                    display: false,
                },
            },
            y: {
                max: indicatorAverageList?.length > 0 ? Math.max(...indicatorAverageList?.map(item => Math.floor(item.score))) + 2 : 10,
                ticks: {
                    display: true,
                },
                grid: {
                    display: false,
                },
                border: {
                    display: false,
                },
            },
        },

        maintainAspectRatio: true,
    };


    const indicatorData = {
        labels: indicatorAverageList?.map(item => props?.languageUse === 'English' ? item.name : item.name_Malay),
        // labels: indicatorLabelList.map(item => item.value),
        datasets: [
            {
                data: indicatorAverageList?.map(item => Math.floor(item.score)),
                // data: [800, 900, 700, 800, 600, 900, 400, 500],
                backgroundColor: '#84ADFF',
                borderColor: 'white',
                borderWidth: 2
            },
        ],
    };

    return (
        <>
            <h4 className='pb-2 pt-3'>Level of Indicator</h4>
            <Row className="barCharHeight border p-2 mb-5">
                <Bar data={indicatorData} options={indicatorChartOptions}></Bar>
            </Row>
        </>
    )
};

export default IndicatorScoreAnalytics