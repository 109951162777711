import React, { Fragment, useState, forwardRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParam, Link } from "react-router-dom";

import {
  Row,
  Col,
  Container,
  Label,
  Spinner,
  Toast,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  FormFeedback,
} from 'reactstrap'

import { Button } from 'react-bootstrap'
import Header from '../components/auth/Header'

const Dashboard = () => {
  const homePage = () => {
    navigate('/')
  }
  return (
    <>
      <Fragment>
       <Header />

        <Container>
          <Row className="my-5">
            <Col xs lg="12" className='text-center'>
              <>
                <CardHeader>
                  <CardTitle>Sorry we are not your request 🚀</CardTitle>
                </CardHeader>
                <CardBody>
                  <h1>Not Found Page</h1>
                  <div className='my-4'>
                    <Button onClick={homePage} className="common-custom-button" color="primary">
                      Home
                    </Button>
                  </div>
                </CardBody>
              </>
            </Col>
          </Row>
        </Container>
      </Fragment>


    </>
  )
}

export default Dashboard
