import axios from "axios"
const baseUrl = process.env.REACT_APP_API_BASEURL

/**
    * @function getProgressAverageDataService
    * @param { token }
    * @description This function is used to get average data of progress
*/
export const getProgressAverageDataService = async ({ token }) => {
    const result = axios.get(`${baseUrl}users/get/progress/avergae`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    return result;
};

/**
    * @function filterProgressAverageDataService
    * @param { token, country, state, industry, companySize, toDate, fromDate }
    * @description This function is used to get average data of progress
*/
export const filterProgressAverageDataService = async ({ token, country, state, industry, companySize, toDate, fromDate }) => {
    const result = axios.get(`${baseUrl}users/get/progress/avergae?country=${country}&state=${state}&industry=${industry}&companySize=${companySize}&toDate=${toDate}&fromDate=${fromDate}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    return result;
};

/**
    * @function filterProgressMaturityIndustryAndStateService
    * @param { token, country, state, industry, companySize, toDate, fromDate }
    * @description This function is used to get progress maturity data
*/
export const filterProgressMaturityIndustryAndStateService = async ({ token, country, state, industry, companySize, toDate, fromDate }) => {
    const result = axios.get(`${baseUrl}users/get/progress/maturity?country=${country}&state=${state}&industry=${industry}&companySize=${companySize}&toDate=${toDate}&fromDate=${fromDate}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    return result;
};

/**
    * @function getAdvanceProgressMaturityDataService
    * @param { token }
    * @description this function is used to get progress maturity data
*/
export const getAdvanceProgressMaturityDataService = async ({ token }) => {
    const result = axios.get(`${baseUrl}users/get/progress/maturity`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    return result;
};

/**
* @function getActiveThemeDimensionListService
* @param ({ token })
* @description call API to get the Active theme/dimension list
*/
export const getActiveThemeDimensionListService = async ({ token }) => {
    const result = await axios.get(`${baseUrl}ProGress-dimension?filter=Active`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    return result;
}

/**
    * @function getUnderTakingService
    * @param { token, id }
    * @description This function is used to get under taking data
*/
export const getUnderTakingService = async ({ token, id }) => {
    const result = axios.get(`${baseUrl}users/get/progess/undertaking/actions?id=${id}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    return result;
};

/**
    * @function getRecordedEmissionService
    * @param { token }
    * @description This function is used to get progress emissions data
*/
export const getRecordedEmissionService = async ({ token }) => {
    const result = axios.get(`${baseUrl}users/get/progess/emissions`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    return result;
};

/**
    * @function filterEmissionIndustryAndStateService
    * @param { token, country, state, industry, companySize, toDate, fromDate}
    * @description This function is used to get progress emissions data
*/
export const filterEmissionIndustryAndStateService = async ({ token, country, state, industry, companySize, toDate, fromDate }) => {
    const result = axios.get(`${baseUrl}users/get/progess/emissions?country=${country}&state=${state}&industry=${industry}&companySize=${companySize}&toDate=${toDate}&fromDate=${fromDate}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    return result;
};

/**
    * @function getTotalRecordedEmissionService
    * @param { token }
    * @description This function is used to get total progress emissions data
*/
export const getTotalRecordedEmissionService = async ({ token }) => {
    const result = axios.get(`${baseUrl}users/get/progess/emissions/total`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    return result;
};

/**
    * @function filterTotalEmissionIndustryAndStateService
    * @param { token, country, state, industry, companySize, toDate, fromDate}
    * @description This function is used to get progress emissions data
*/
export const filterTotalEmissionIndustryAndStateService = async ({ token, country, state, industry, companySize, toDate, fromDate }) => {
    const result = axios.get(`${baseUrl}users/get/progess/emissions/total?country=${country}&state=${state}&industry=${industry}&companySize=${companySize}&toDate=${toDate}&fromDate=${fromDate}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    return result;
};