// ** React Imports
import React, { Fragment, useState, forwardRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { LogOut } from 'react-feather'
// ** Reactstrap Imports
import {
    Row,
    Col,
    Container,
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Dropdown,
    NavbarText
} from 'reactstrap'

// Utility-Service
import { getToken } from '../../../utility/AuthService'

import logo from '../../../assets/images/logo.png'
import LogoProgress from '../../../assets/images/LogoProgress.png'

const NavbarPage = (args) => {
    const Separation_Url = process.env.REACT_APP_SEPARATION
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(false)
    const token = getToken()
    const navigate = useNavigate()

    return (
        <>
            <Fragment>
                <Container>
                        <div className='d-md-flex justify-content-md-between align-items-center pt-3 pb-2'>
                            <div>
                                <NavbarBrand href="/">
                                    
                                    {/* {(Separation_Url === 'PROGRESS' || Separation_Url === 'ESG') && <div className="main-logo">
                                        <img src={logo} alt="logo_header" />
                                    </div>} */}

                                    {(Separation_Url === 'ESG') && <div className="main-logo">
                                    <img src={logo} alt="logo_header" />
                                    </div>}

                                    {Separation_Url === 'PROGRESS' && <div className="main-logo">
                                    <img src={LogoProgress} alt="logo_header" />
                                    </div>}
                                </NavbarBrand>
                            </div>
                            <div className='right-menu'>
                                
                            </div>
                        </div>
                </Container>
                <hr className='header-row'/>
            </Fragment>
        </>

    )
}

export default NavbarPage
