import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

//date picker css
import "react-datepicker/dist/react-datepicker.css";

//react-phone-number-input css
import 'react-phone-number-input/style.css'

// fonts css
import './assets/css/fonts/font.css';
// third party
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

import "rsuite/dist/rsuite.css";

// bootstrap 
import 'bootstrap/dist/css/bootstrap.min.css';
// import './assets/scss/app.scss'

// custom css 
import './assets/css/index.css';
import './assets/css/App.css';
import './assets/css/responsive.css';

// ** React Imports
// import { createRoot } from "react-dom/client"
import { BrowserRouter } from "react-router-dom"

// import {
//   BrowserRouter as Router,
//   Routes,
//   Route,
//   Link
// } from 'react-router-dom';

// ** Redux Imports
import { store } from "./redux/store"
import { Provider } from "react-redux"

i18n.use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ["en", "ms"],
    fallbackLng: "en",
    detection: {
      order: ["path", "cookie", "htmlTag", "localStorage", "subdomain"],
      caches: ["cookie"],
    },
    // backend: {
    //   loadPath: "/assets/locales/{{lng}}/translation.json",
    // }
  });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Provider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
