const states = [
{
    value: 2460,
    label: "Johor"
},
{
    value: 2461,
    label: "Kedah"
},
{
    value: 2462,
    label: "Kelantan"
}, {
    value: 2463,
    label: "Kuala Lumpur"
}, {
    value: 2464,
    label: "Labuan"
}, {
    value: 2465,
    label: "Malacca"
}, {
    value: 2466,
    label: "Negeri Sembilan"
}, {
    value: 2467,
    label: "Pahang"
}, {
    value: 2468,
    label: "Penang"
}, {
    value: 2469,
    label: "Perak"
}, {
    value: 2470,
    label: "Perlis"
}, {
    value: 2471,
    label: "Putrajaya"
}, {
    value: 2472,
    label: "Sabah"
}, {
    value: 2473,
    label: "Sarawak"
}, {
    value: 2474,
    label: "Selangor"
}, {
    value: 2475,
    label: "Terengganu"
}, {
    value: 2476,
    label: "Other"
}
]

export default states;