import React from 'react'
import ResetPassword from "../../components/auth/ResetPassword"
import Header from '../../components/auth/Header'
import Footer from '../../components/layouts/Footer'

function ResetPassPage() {
    return (
        <>
            <Header />
            <ResetPassword />
            <Footer />
        </>
    )
}

export default ResetPassPage