
import axios from "axios"
const baseUrl = process.env.REACT_APP_API_BASEURL

/**
    * @function uploadApprovalSignServices
    * @param (authToken, submissionId, name, desigantion, date)
    * @description call API to upload
*/
export const uploadApprovalInfoServices = async ({ authToken, submissionId, name, desigantion, date }) => {
    const result = await axios.patch(`${baseUrl}ProGress/submission/approvel`,
        {
            submissionId,
            approvedBy: {
                name,
                desigantion,
                date
            }
        },
        {
            headers: {
                Authorization: `Bearer ${authToken}`,
            }
        })
    return result;
};
