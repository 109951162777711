import React from 'react'

//third party

//custom components
import TermsAndCondition from '../../components/TermsAndCondition/TermsAndCondition'
import Header from '../../components/auth/Header'
import Footer from '../../components/layouts/Footer'

const TermsAndConditionPage = () => {
    return (
        <>
            <Header />
            <TermsAndCondition />
            <Footer />
        </>
    )
};

export default TermsAndConditionPage