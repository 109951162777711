
import axios from "axios"
const baseUrl = process.env.REACT_APP_API_BASEURL

/**
    * @function getSubmittedDocumentServices
    * @param (authToken, SubmissionId)
    * @description call API to get the submitted documents list
*/
export const getSubmittedDocumentServices = async ({ authToken, SubmissionId }) => {
    const result = await axios.get(`${baseUrl}users/submission/documents?SubmissionId=${SubmissionId}`, {
        headers: {
            Authorization: `Bearer ${authToken}`
        }
    })
    return result;
}

/**
    * @function getProgressSubmittedDocumentServices
    * @param (authToken, SubmissionId)
    * @description call API to get the progress submitted documents list
*/
export const getProgressSubmittedDocumentServices = async ({ authToken, SubmissionId }) => {
    const result = await axios.get(`${baseUrl}ProGress/submission/documents?SubmissionId=${SubmissionId}`, {
        headers: {
            Authorization: `Bearer ${authToken}`
        }
    })
    return result;
}
