// Custom Component
import Header from '../../components/auth/Header';
import Register from '../../components/auth/SignUp';
import Footer from '../../components/layouts/Footer'

const UserLogIn = () => {
    return <>
        <Header />
        <Register />
        <Footer />
    </>
}

export default UserLogIn;
