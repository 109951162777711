// App.js All routes is working currently
import { Routes, Route } from "react-router-dom";

// Auth-Service
import PrivateRoute from "./utility/PrivateRoute";

import HomePage from "./views/pages/Home/Index";
import Progress from "./views/pages/Progress/Index";
import LoginPage from "./views/pages/auth/UserLogIn";
import RegisterPage from "./views/pages/auth/SignUp";
import ForgotPassword from "./views/pages/auth/ForgotPasswordpage";
import ResetPassword from "./views/pages/auth//ResetPassPage";
import OTPverify from "./views/pages/auth/OtpVerifyPage";
import NotFoundPage from "./views/pages/NotFound";
import Dashboard from "./views/pages/dashboard/dashboard";
import History from "./views/pages/history/History";
import PdfReportPage3 from "./views/pages/pdfReport/PdfReportPage3";
import ProfilePage from "./views/pages/ProfilePage";
import AssociatedCompaniesPage from "./views/pages/associatedCompanies/AssociatedCompaniesPage";
import AnalyticsPage from "./views/pages/analyticsPage/AnalyticsPage";
import UploadDocumentsPage from "./views/pages/UploadDocumentsPage/UploadDocumentsPage";
import UploadProgressDocumentsPage from "./views/pages/UploadProgressDocumentsPage/UploadProgressDocumentsPage";
import TermsAndConditionPage from "./views/pages/TermsAndConditionPage/TermsAndConditionPage";
import DashboardProgress from "./views/pages/dashboardProgress/DashboardProgress";
import ProgressAssociatedCompaniesPage from "./views/pages/associatedCompanies/ProgressAssociatedCompaniesPage";
import AdvanceAnalyticsPage from "./views/pages/analyticsPage/AdvanceAnalyticsPage";
import AdvanceAnalyticsProgressPage from "./views/pages/analyticsPage/AdvanceAnalyticsProgressPage";

const App = () => {
    return <div className="App">
        <Routes>
            <Route path="/*" element={<NotFoundPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/forgotPassword" element={<ForgotPassword />} />
            <Route path="/OTPverify" element={<OTPverify />} />
            <Route path="/resetPassword" element={<ResetPassword />} />
            <Route path="/terms&condition" element={<TermsAndConditionPage />} />

            <Route element={<PrivateRoute />}>
                <Route path="/" element={<History />} />
                <Route path="/:id" element={<HomePage />} />
                <Route path="/view/:id" element={<HomePage />} />
                <Route path="/progress/:id" element={<Progress />} />
                <Route path="/view/progress/:id" element={<Progress />} />
                <Route path="/user/dashboard/:SId" element={<Dashboard />} />
                <Route path="/user/progress/dashboard/:SId" element={<DashboardProgress />} />
                <Route path="/user/history" element={<History />} />
                <Route path="/pdf/:SId" element={<PdfReportPage3 />} />
                <Route path="/user/profile" element={<ProfilePage />} />
                <Route path="/associatedCompanies" element={<AssociatedCompaniesPage />} />
                <Route path="/progress/associatedCompanies" element={<ProgressAssociatedCompaniesPage />} />
                <Route path="/analytics" element={<AnalyticsPage />} />
                <Route path="/advanceAnalytics" element={<AdvanceAnalyticsPage />} />
                <Route path="/progressAdvanceAnalytics" element={<AdvanceAnalyticsProgressPage />} />
                <Route path="/uploadDocuments/:id" element={<UploadDocumentsPage />} />
                <Route path="/uploadProgressDocuments/:id" element={<UploadProgressDocumentsPage />} />
            </Route>
        </Routes>
    </div>
}

export default App;
