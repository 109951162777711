import React, { useState, useEffect } from 'react'

//third party
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
ChartJS.register(BarElement, CategoryScale, LinearScale, ArcElement, Tooltip, Legend, ChartDataLabels);

// ** Reactstrap Imports
import {
    Row,
    Col,
    CardTitle,
    CardText,
    Form,
    Label,
    Input,
    Button
} from "reactstrap"
import toast, { Toaster } from 'react-hot-toast'
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
//custom
import Selection from '../Selection/Selection';
import PieChart from '../CustomChart/PieChart'
import PieChartLegends from '../CustomChart/PieChartLegends'
// Utility-Service
import { getToken, getUser } from '../../../utility/AuthService'
//API
import { getOverallDimensionIndustryData } from '../../../services/analyticsServices/overAllDimensionIndustryData';

const IndustryComparisionAnalytics = (props) => {
    const [isLoading, setLoading] = useState(false)
    const token = getToken()
    const user = getUser()
    const [parcentOrDigit, setParcentOrDigit] = useState('digit')
    const [industry1Data, setState1Data] = useState([]);
    const [industry1ParcentData, setState1ParcentData] = useState();
    const [industry2Data, setState2Data] = useState([]);
    const [industry2ParcentData, setState2ParcentData] = useState();
    const [industry3Data, setState3Data] = useState([]);
    const [industry3ParcentData, setState3ParcentData] = useState();
    const [industry1Select, setState1Select] = useState();
    const [industry2Select, setState2Select] = useState();
    const [industry3Select, setState3Select] = useState();
    const [allIndustryList, setAllIndustryList] = useState([])
    const [countryValue, setCountryValue] = useState(null);
    const [watchEnvironmentValue, setEnvironmentWatchValue] = useState('');
    const [watchSocialValue, setSocialWatchValue] = useState('');
    const [watchGovernanceValue, setGovernanceWatchValue] = useState('');

    const industryBasedLabelList = [
        {
            value: 'Leader',
            color: '#EC6258'
        },
        {
            value: 'Advanced',
            color: '#69BA6C'
        },
        {
            value: 'Beginner',
            color: '#FBC04F'
        },
        {
            value: 'Laggard',
            color: '#62AADF'
        },
        {
            value: 'Intermediate',
            color: '#ED61AE'
        },
    ];

    useEffect(() => {
        if (props.industryList) {
            const industry = []
            props.industryList?.map(i => {
                industry.push({ label: i?.name, value: i?._id })
            })
            setAllIndustryList(industry)
        }
    }, [props.industryList]);

    const {
        register,
        reset,
        handleSubmit,
        watch,
        control,
        formState: { errors },
    } = useForm();

    const watchSelect = watch('country')

    /**
     * @function handleParcent
     * @param ()
     * @description to show data in parcent
     */
    const handleParcent = async () => {
        if (industry1Data) {
            setParcentOrDigit('parcent')
            const sumEnv = industry1Data.reduce(function (a, b) {
                return a + b;
            });
            const parcentValEnv = industry1Data.map(function (a) {
                return Math.floor((a / sumEnv) * 100);
            });
            setState1ParcentData(parcentValEnv)

        }
        if (industry2Data) {
            setParcentOrDigit('parcent')
            const sumEnv = industry2Data.reduce(function (a, b) {
                return a + b;
            });
            const parcentValEnv = industry2Data.map(function (a) {
                return Math.floor((a / sumEnv) * 100);
            });
            setState2ParcentData(parcentValEnv)

        }
        if (industry3Data) {
            setParcentOrDigit('parcent')
            const sumEnv = industry3Data.reduce(function (a, b) {
                return a + b;
            });
            const parcentValEnv = industry3Data.map(function (a) {
                return Math.floor((a / sumEnv) * 100);
            });
            setState3ParcentData(parcentValEnv)

        }

    };

    /**
     * @function handleDigit
     * @param ()
     * @description to show data in digit
     */
    const handleDigit = async () => {
        setParcentOrDigit('digit')
    }

    /**
     * @function industry1DataList
     * @param ()
     * @description to handle get industry data List
     */
    const industry1DataList = async () => {
        let result = []
        try {
            result = await getOverallDimensionIndustryData({
                authToken: token,
                id: props?.dimensionList?.find(item => item?.name === 'Governance')?._id,
                industry: industry1Select.value,
                userId: user?._id
            });
            if (result?.data?.statusCode === 200) {
                setLoading(false)
                setState1Data(industryBasedLabelList.map(item => result?.data?.data[item.value]))
            } else {
                setLoading(false);
                setState1Data([])
                toast.error(result?.response?.data?.message || 'Something went wrong')
            }
        } catch (error) {
            setLoading(false);
            setState1Data([])
            toast.error(error?.response?.data?.message || 'Something went wrong!')
        }
    };

    useEffect(() => {
        if (industry1Select && props.watchDimensionValue) {
            industry1DataList()
        } else if (industry1Select && !props.watchDimensionValue) {
            toast.error('First select Dimension')
        }
    }, [industry1Select, props.watchDimensionValue])

    /**
 * @function industry2DataList
 * @param ()
 * @description to handle get industry data List
 */
    const industry2DataList = async () => {
        let result = []
        try {
            result = await getOverallDimensionIndustryData({
                authToken: token,
                id: props?.dimensionList?.find(item => item?.name === 'Governance')?._id,
                industry: industry2Select.value,
                userId: user?._id
            });
            if (result?.data?.statusCode === 200) {
                setLoading(false)
                setState2Data(industryBasedLabelList.map(item => result?.data?.data[item.value]))
            } else {
                setLoading(false);
                setState2Data([])
                toast.error(result?.response?.data?.message || 'Something went wrong')
            }
        } catch (error) {
            setLoading(false);
            setState2Data([])
            toast.error(error?.response?.data?.message || 'Something went wrong!')
        }
    };

    useEffect(() => {
        if (industry2Select && props.watchDimensionValue) {
            industry2DataList()
        } else if (industry2Select && !props.watchDimensionValue) {
            toast.error('First select Dimension')
        }
    }, [industry2Select, props.watchDimensionValue])

    /**
 * @function industry3DataList
 * @param ()
 * @description to handle get industry data List
 */
    const industry3DataList = async () => {
        let result = []
        try {
            result = await getOverallDimensionIndustryData({
                authToken: token,
                id: props?.dimensionList?.find(item => item?.name === 'Governance')?._id,
                industry: industry3Select.value,
                userId: user?._id
            });
            if (result?.data?.statusCode === 200) {
                setLoading(false)
                setState3Data(industryBasedLabelList.map(item => result?.data?.data[item.value]))
            } else {
                setLoading(false);
                setState3Data([])
                toast.error(result?.response?.data?.message || 'Something went wrong')
            }
        } catch (error) {
            setLoading(false);
            setState3Data([])
            toast.error(error?.response?.data?.message || 'Something went wrong!')
        }
    };

    useEffect(() => {
        if (industry3Select && props.watchDimensionValue) {
            industry3DataList()
        } else if (industry3Select && !props.watchDimensionValue) {
            toast.error('First select Dimension')
        }
    }, [industry3Select, props.watchDimensionValue])

    const industry1BasedChartOptions = {
        responsive: false,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                display: false
            },
            datalabels: {
                formatter: (value, ctx) => {
                    let percentage = 0
                    percentage = parcentOrDigit === 'digit' ? `${value}` : `${value}%`;
                    return percentage;
                },
                color: 'white',
                font: {
                    weight: 'bold',
                    size: 12
                }
            }
        }
    };

    const industry2BasedChartOptions = {
        responsive: false,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                display: false
            },
            datalabels: {
                formatter: (value, ctx) => {
                    let percentage = 0
                    percentage = parcentOrDigit === 'digit' ? `${value}` : `${value}%`;
                    return percentage;
                },
                color: 'white',
                font: {
                    weight: 'bold',
                    size: 12
                }
            }
        }
    };

    const industry3BasedChartOptions = {
        responsive: false,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                display: false
            },
            datalabels: {
                formatter: (value, ctx) => {
                    let percentage = 0
                    percentage = parcentOrDigit === 'digit' ? `${value}` : `${value}%`;
                    return percentage;
                },
                color: 'white',
                font: {
                    weight: 'bold',
                    size: 12
                }
            }
        }
    };

    const industryBasedChartOptions = {
        responsive: false,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                display: false
            },
            datalabels: {
                formatter: (value, ctx) => {
                    let percentage = 0
                    percentage = `${value}%`;
                    // percentage = `${ctx.chart.data.labels[ctx.dataIndex]}\n${value}%`;
                    return percentage;
                },
                color: 'white',
                font: {
                    weight: 'bold',
                    size: 12
                }
            }
        }
    };


    const industry1BasedData = {
        labels: industryBasedLabelList.map(item => item.value),
        datasets: [
            {
                data: parcentOrDigit === 'digit' ? industry1Data : industry1ParcentData,
                backgroundColor: industryBasedLabelList.map(item => item.color),
                borderColor: 'white',
                borderWidth: 2
            },
        ],
    };

    const industry2BasedData = {
        labels: industryBasedLabelList.map(item => item.value),
        datasets: [
            {
                data: parcentOrDigit === 'digit' ? industry2Data : industry2ParcentData,
                backgroundColor: industryBasedLabelList.map(item => item.color),
                borderColor: 'white',
                borderWidth: 2
            },
        ],
    };

    const industry3BasedData = {
        labels: industryBasedLabelList.map(item => item.value),
        datasets: [
            {
                data: parcentOrDigit === 'digit' ? industry3Data : industry3ParcentData,
                backgroundColor: industryBasedLabelList.map(item => item.color),
                borderColor: 'white',
                borderWidth: 2
            },
        ],
    };

    return (

        <>
            {/* <div>
                <Toaster />
            </div> */}
            <div className='d-flex flex-column flex-md-row justify-content-between align-items-center py-2'>
                <h4 className=''>Industry</h4>
                <div className='d-flex align-items-center rounded-pill bg-btn-analytics px-2 py-2'>
                    <Button color='' className={parcentOrDigit === 'parcent' ? 'bg-white rounded-pill text-primary' : 'bg-btn-analytics rounded-pill text-primary'} onClick={handleParcent}>
                        Percentage
                    </Button>
                    <Button color='' className={parcentOrDigit === 'digit' ? 'bg-white rounded-pill text-primary' : 'bg-btn-analytics rounded-pill text-primary'} onClick={handleDigit}>
                        Digit number
                    </Button>
                </div>

            </div>
            <Row className='border'>

                <Row className='d-flex flex-row justify-content-between px-5 py-2'>
                    <Col md={3} lg={3} sm={12}>
                        <div className=''>
                            <Selection className={'w-100'} options={allIndustryList} title={'Industry'} name={'Environment'} setWatchValue={setState1Select} />
                        </div>

                        {industry1Data?.every(item => item === 0) ? <>
                            <p className='my-5 text-danger'>No Data available</p>
                        </> : <>
                            <div className='d-flex flex-column  align-items-center'>
                                <PieChart options={industry1BasedChartOptions} data={industry1BasedData} width={220} height={220} />
                            </div>
                        </>}
                    </Col>
                    <Col md={3} lg={3} sm={12}>
                        <div className=''>
                            <Selection className={'w-100'} options={allIndustryList} title={'Industry'} name={'Social'} setWatchValue={setState2Select} />
                        </div>
                        {industry2Data?.every(item => item === 0) ? <>
                            <p className='my-5 text-danger'>No Data available</p>
                        </> : <>
                            <div className='d-flex flex-column  align-items-center'>
                                <PieChart options={industry2BasedChartOptions} data={industry2BasedData} width={220} height={220} />
                            </div>
                        </>}

                    </Col>
                    <Col md={3} lg={3} sm={12}>
                        <div className=''>
                            <Selection className={'w-100'} options={allIndustryList} title={'Industry'} name={'Governance'} setWatchValue={setState3Select} />
                        </div>

                        {industry3Data?.every(item => item === 0) ? <>
                            <p className='my-5 text-danger'>No Data available</p>
                        </> : <>
                            <div className='d-flex flex-column  align-items-center'>
                                <PieChart options={industry3BasedChartOptions} data={industry3BasedData} width={220} height={220} />
                            </div>
                        </>}


                    </Col>
                </Row>
                <div>
                    <PieChartLegends />
                </div>
            </Row>
        </>

    )
};

export default IndustryComparisionAnalytics