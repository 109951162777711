import { Nav } from 'rsuite';

const AdvancedAnalyticsProgressSidebar = (props) => {
    return <div>
        <Nav className='d-flex flex-wrap' activeKey={props?.activeKey} onSelect={(eventKey, event) => {
            props?.setActiveKey(eventKey)
        }}>
            <Nav.Item eventKey="1">
                Maturity Levels
            </Nav.Item>
            <Nav.Item eventKey="2">GHG Emission</Nav.Item>
            <Nav.Item eventKey="3">Progress actions</Nav.Item>
        </Nav>
    </div>
};

export default AdvancedAnalyticsProgressSidebar