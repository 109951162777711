import { useState, useEffect } from 'react'
import { Col, Row, Card, Form, Button } from 'react-bootstrap';
import { Spinner } from 'reactstrap';
import Select from 'react-select';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

// Custom Component
import SingleUploadDocumentCard from './SingleUploadDocumentCard';
import SubmissionEndModal from '../modal/SubmissionEndModal';

// Redux-Functions
import { selectLanguageUse } from '../../../redux/slices/language.Slice'
import { useSelector } from 'react-redux';

// Utility-Service
import { getToken, getUser } from "../../../utility/AuthService";
import { getActiveDimensionListService } from '../../../services/dimensionService';

// API-Service
import { uploadDocumentServices, uploadPDFServices } from '../../../services/uploadDocumentServices/uploadDocumentServices';

const UploadDocuments = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const authToken = getToken();
    const [modalShow, setModalShow] = useState(false);

    const [dimensions, setDimensions] = useState([]);
    const [dimensionsMalay, setDimensionsMalay] = useState([]);
    const [isLoading, setLoading] = useState(false);

    const user = getUser();
    const languageUse = useSelector(selectLanguageUse || user?.language);

    const handleModalShow = () => setModalShow(!modalShow);

    const { register, setValue, handleSubmit, watch, control, formState: { errors } } = useForm(
        {
            defaultValues: {
                inputs: [
                    {
                        Dimension: "",
                        document_Name: "",
                        Description: "",
                        uploadPDF: [{ files: null }]
                    }
                ]
            }
        }
    );

    const { fields: option_fields, append: option_append, remove: option_remove } = useFieldArray({
        control,
        name: "inputs"
    });

    const watchDocument = watch('inputs')
    const allWatchedValues = watch({ nest: true });

    /**
        * @function getDimensionList
        * @param ()
        * @description to get Dimension List
    */
    const getDimensionList = async () => {
        setLoading(true)
        try {
            const result = await getActiveDimensionListService({
                authToken: authToken,
            });
            if (result?.data?.statusCode === 200) {
                const dimension = []
                result?.data?.data?.list?.map(i => {
                    dimension.push({ label: i?.name, value: i?._id })
                });
                dimension?.shift()

                const dimensionMalay = []
                result?.data?.data?.list?.map(i => {
                    dimensionMalay.push({ label: i?.name_Malay, value: i?._id })
                });
                dimensionMalay?.shift()
                setDimensions(dimension)
                setDimensionsMalay(dimensionMalay)
                setLoading(false)
            } else {
                setLoading(false)
                setDimensions([])
                setDimensionsMalay([])
                toast.error(result.data.errorMessage || 'Something went wrong!')
            };
            return false;
        } catch (error) {
            setLoading(false)
            setDimensions([])
            setDimensionsMalay([])
            toast.error(error?.response?.data?.message || 'Something went wrong!')
        }
    }

    useEffect(() => {
        getDimensionList()
    }, [])

    /**
        * @function handleUploadPDF
        * @param index, id
        * @description to upload PDF
    */
    const handleUploadPDF = async (index, id) => {
        setLoading(true)
        const formData = new FormData();

        if (watchDocument?.length > 0) {
            watchDocument[index]?.uploadPDF?.filter(item => item?.files).map((file) => {
                formData.append(`files`, file?.files[0]);
            })
        };

        try {
            const result = await uploadPDFServices({
                authToken: authToken,
                id: id,
                files: formData
            });
            if (result?.data?.statusCode === 200) {
                setModalShow(true)
            } else {
                setLoading(false)
                toast.error(result.data.errorMessage || 'Something went wrong!')
            };
            return false;
        } catch (error) {
            setLoading(false)
            toast.error(error?.response?.data?.message || 'Something went wrong!')
        }
    }

    /**
        * @function handleUploadDoc
        * @param data
        * @description to upload image
    */
    const handleUploadDoc = async (data) => {
        setLoading(true)
        try {
            await Promise.all(watchDocument?.map(async (item, index) => {
                const result = await uploadDocumentServices({
                    authToken: authToken,
                    submissionId: id,
                    dimension: item?.Dimension?.value,
                    name: item?.document_Name,
                    description: item?.Description,
                });
                if (result?.data?.statusCode === 200) {
                    handleUploadPDF(index, result?.data?.data?._id)

                } else {
                    setLoading(false)
                    toast.error(result.data.errorMessage || 'Something went wrong!')
                };
                return false;
            }))

        } catch (error) {
            setLoading(false)
            toast.error(error?.response?.data?.message || 'Something went wrong!')
        }
    };

    /**
        * @function handleDelete
        * @param id
        * @description to handle delete
    */
    const handleDelete = (id) => {
        option_remove(id)
    }

    /**
        * @function onSubmit
        * @param data
        * @description to submit
    */
    const onSubmit = (data) => {
        handleUploadDoc(data)
    };

    return <>
        <div className='container pb-3'>
            <Form onSubmit={handleSubmit(onSubmit)} className='h-100'>
                <Row>
                    <h2 className='mb-4'>{languageUse === 'English' ? 'Upload Documents' : 'Muat Naik Dokumen'}</h2>
                    <p>{languageUse === 'English' ? "In this section, you can upload documents to provide evidence for your answers. This is important to increase the reliability of your assessment. Supporting documents are for example: a company policy or manual, ISO certification, or training certificates. If you do not have supporting documents, you must provide a document to validate your account. For example, your business card or a screenshot of your company's website." : 'Dalam bahagian ini, anda boleh memuat naik dokumen untuk memberikan bukti bagi jawapan anda. Ini penting untuk meningkatkan kebolehpercayaan penilaian anda. Dokumen sokongan adalah sebagai contoh: polisi atau manual syarikat, pensijilan ISO atau sijil latihan. Jika anda tidak mempunyai dokumen sokongan, anda mesti menyediakan dokumen untuk mengesahkan akaun anda. Contohnya, kad perniagaan anda atau tangkapan skrin tapak web syarikat anda.'}</p>
                    {option_fields.map((field, index) => (
                        <Col md={6} sm={12} >
                            <Card className='border p-3 mb-3'>
                                <h4>{languageUse === 'English' ? 'Supporting Document' : 'Dokumen sokongan'} {index + 1}</h4>
                                <div className='d-flex flex-column justify-content-center align-items-start mt-2 mb-3'>
                                    <label htmlFor="Dimension" className="form-label">
                                        {languageUse === 'English' ? 'Dimension' : 'Dimensi'}
                                    </label>
                                    <Controller
                                        name={`inputs.${index}.Dimension`}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                className={'w-100'}
                                                options={languageUse === 'English' ? dimensions : dimensionsMalay}
                                                {...field}
                                            />
                                        )}
                                        rules={{
                                            required: languageUse === 'English' ? 'Dimension is required' : 'Dimensi diperlukan'
                                        }}
                                    />
                                    {errors.inputs?.[index]?.Dimension && <p className='pt-1 text-danger'>{errors.inputs?.[index]?.Dimension?.message}</p>}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="document_Name" className="form-label">
                                        {languageUse === 'English' ? 'Document Name' : 'Nama Dokumen'}
                                    </label>
                                    <input
                                        type="text"
                                        className={`form-control ${errors.document_Name ? 'is-invalid' : ''}`}
                                        {...register(`inputs.${index}.document_Name`, {
                                            required: languageUse === 'English' ? 'Document Name is required' : 'Nama Dokumen diperlukan'
                                        })}
                                    />
                                    {errors.inputs?.[index]?.document_Name && <p className='pt-1 text-danger'>{errors.inputs?.[index]?.document_Name?.message}</p>}
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="Description" className="form-label">
                                        {languageUse === 'English' ? 'Description' : 'Penerangan'}
                                    </label>
                                    <input
                                        type="text"
                                        className={`form-control ${errors.Description ? 'is-invalid' : ''}`}
                                        {...register(`inputs.${index}.Description`, {
                                            required: languageUse === 'English' ? 'Description is required' : 'Penerangan diperlukan'
                                        })}
                                    />
                                    {errors.inputs?.[index]?.Description && <p className='pt-1 text-danger'>{errors.inputs?.[index]?.Description?.message}</p>}
                                </div>
                                <SingleUploadDocumentCard nestIndex={index} allWatchedValues={allWatchedValues} {...{ control, register, setValue, errors }} />
                                <div className='mb-2 d-flex justify-content-between'>
                                    <Button className="float-end authButton login-btn-min-width" variant='outline-primary' color="primary" type="button" onClick={() => handleDelete(index)}>
                                        {isLoading ? <Spinner variant='dark' size='sm' /> : languageUse === 'English' ? 'Remove' : 'Alih keluar'}
                                    </Button>
                                </div>
                            </Card>
                        </Col>
                    ))}
                </Row>
                <div className='d-flex flex-column'>
                    <Button type="button" className="float-start authButton w-50 my-4" variant='outline-primary' onClick={() => option_append(
                        {
                            Dimension: "",
                            document_Name: "",
                            Description: "",
                            uploadPDF: [{ files: null }]
                        }
                    )}>
                        {languageUse === 'English' ? '+ Add more documents' : '+ Tambah lebih banyak dokumen'}
                    </Button>
                    <div className='d-flex align-items-center'>
                        <Button className='float-end authButton login-btn-min-width me-3' type="button" variant='outline-primary' color='primary' onClick={() => navigate(-1)} >
                            {languageUse === 'English' ? 'Back' : 'Belakang'}
                        </Button>
                        <Button className='float-end authButton login-btn-min-width' type="submit" variant='outline-primary' color='primary' >
                            {languageUse === 'English' ? 'Submit' : 'Hantar'}
                        </Button>
                    </div>
                </div>
            </Form>
        </div>

        <SubmissionEndModal show={modalShow} handleClose={handleModalShow} submission_id={id} />
    </>
};

export default UploadDocuments;