// ** React Imports
import React, { Fragment, forwardRef } from "react";
import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
} from "chart.js";

// ** Reactstrap Imports
import { Row, Col, Container } from "reactstrap";
import { Bar } from "react-chartjs-2";
import { Link } from 'react-router-dom'
import moment from 'moment'
import { Button } from "react-bootstrap";
//redux
import { selectLanguageUse } from '../../../redux/slices/language.Slice'
// Images
import logo from '../../../assets/images/logo.png'
import footerLogo from '../../../assets/images/FooterLogo.png'
import Segwitz from '../../../assets/images/Segwitz.png'
import { BiImport } from "react-icons/bi";
import { AiOutlineEye } from "react-icons/ai";

import useWindowSize from '../../../hooks/windowSize'
import { useSelector } from "react-redux";
// Utility-Service
import { getUser } from "../../../utility/AuthService";
import classNames from "classnames";
import { BsCheckCircleFill, BsCheckCircle } from "react-icons/bs";

const PdfReportPage2 = forwardRef((props, ref) => {
    ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

    const size = useWindowSize()
    const dimensionData = props?.dimensionData;
    const indicatorData = props?.indicatorData;
    const selectedIndicatorData = props?.selectedIndicatorData;
    const profileData = props?.profileData;
    const ESGperformanceData = props?.ESGperformanceData;
    const optionalQuesData = props?.optionalQuesData;
    const requiredQuestions = props?.requiredQuestions;
    const user = getUser()
    const AWS_Url = process.env.REACT_APP_AWSURL
    const languageUse = useSelector(selectLanguageUse || user?.language)


    const colorLeval = [
        {
            leval: "Laggard",
            color_code: "#FF0000"
        },
        {
            leval: "Beginner",
            color_code: "#FF9900"
        },
        {
            leval: "Intermediate",
            color_code: "#B6D7A8"
        },
        {
            leval: "Advanced",
            color_code: "#93C47D"
        },
        {
            leval: "Leader",
            color_code: "#38761D"
        },
    ];

    // const higherLowerDetector = (item) => {
    //     if (item) {
    //         if (((item?.correct / item?.total) * 100).toFixed(2) >= ((item?.industryScore / item?.total) * 100).toFixed(2)) {
    //             return `${(((item?.correct / item?.total) * 100).toFixed(2) - ((item?.industryScore / item?.total) * 100).toFixed(2)).toFixed(2)}% higher`
    //         } else {
    //             return `${(((item?.industryScore / item?.total) * 100).toFixed(2) - ((item?.correct / item?.total) * 100).toFixed(2)).toFixed(2)}% lower`
    //         }
    //     } else {
    //         return ''
    //     }
    // }

    const higherLowerDetector = (item) => {
        if (item) {

            // if (((item?.correct / item?.total) * 100).toFixed(2) >= ((item?.industryScore / item?.total) * 100).toFixed(2)) {
            if (((item?.correct / item?.total)) >= ((item?.industryScore / item?.total))) {
                return `${(((item?.correct / item?.total) * 100).toFixed(2) - ((item?.industryScore / item?.total) * 100).toFixed(2)).toFixed(2)}% ${languageUse === 'English' ? 'higher' : 'yang lebih'}`
            } else {
                return `${(((item?.industryScore / item?.total) * 100).toFixed(2) - ((item?.correct / item?.total) * 100).toFixed(2)).toFixed(2)}% ${languageUse === 'English' ? 'lower' : 'yang kurang'}`
            }
        } else {
            return ''
        }
    };


    return (
        <Container>
            <div ref={ref} className={`px-4 w-100 pdfFont ${props.pdfContent ? 'd-block' : 'd-none'}`}>
                {/* <div ref={ref} className='px-2 pdfFont bg-danger w-100'> */}
                {/* <div ref={ref} className='container px-4 pdfClass'> */}
                {/* {props?.printing &&
                    <div className="d-flex justify-content-between mb-3">
                        <h2 className="pt-3">ESG MATURITY REPORT</h2>
                        <img src={logo} alt="logo" width={300} />
                    </div>
                } */}
                <table className="report-container" width={1100}>
                    <thead className="report-header">
                        <tr>
                            <th className="report-header-cell">
                                <div className="header-info" style={{
                                    paddingRight: '0'
                                    // paddingRight: '28rem'
                                }}>
                                    <div className="d-flex justify-content-between mb-5">
                                        <h2 className="pt-3">{languageUse === 'English' ? "ESG MATURITY REPORT" : "Laporan Kematangan ESG"}</h2>
                                        <img src={logo} alt="logo" width={500} />
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tfoot className="report-footer">
                        <tr>
                            {' '}
                        </tr>

                        {/* <tr>
                            {(size?.width && size?.width >= 1400) ? <>
                            
                                <td class="report-footer-cell position-relative " style={{ paddingRight: '12rem' }}>
                                    <div class="footer-info text-end" style={{ marginBottom: '.5rem' }}>
                                        <span className="position-absolute bottom-0 start-80" style={{ marginRight: '0px', marginBottom: '10px' }}></span>
                                        <img className="" src={footerLogo} alt="footerLogo" width={50} />
                                    </div>
                                
                                </td> </> : <>
                                    <td class="report-footer-cell position-relative" style={{ paddingRight: '35rem' }}>
                                        <div class="footer-info text-end" style={{ marginBottom: '.5rem' }}>
                                            <span className="position-absolute bottom-0 end-0" style={{ marginRight: '20px', marginBottom: '13px' }}></span>
                                            <img className="position-absolute bottom-0 end-0" src={footerLogo} alt="footerLogo" width={50} />
                                        </div>
                                    </td>
                                </>
                            }
                        </tr> */}
                    </tfoot>
                    <tbody className="report-content">
                        <tr>
                            <td className="report-content-cell">
                                <div className="main">
                                    <div className="article">
                                        <div className="profile" style={{ paddingRight: size?.width >= 1400 ? '0' : '0' }}>

                                            <Row className="profile px-5 pt-4">
                                                <Col className="" md="6" sm="6">
                                                    <p className="mb-0">{languageUse === 'English' ? 'Current Report Dated' : 'Laporan Semasa Bertarikh'} : <span className="bold">{moment(profileData?.updatedAt).format("DD-MM-YYYY")}</span></p>
                                                    <p className="mb-0">{languageUse === 'English' ? 'Company' : 'Syarikat'} : <span className="bold">{profileData?.company}</span></p>
                                                    <p className="mb-0">{languageUse === 'English' ? 'Company Registration Number' : 'No. Pendaftaran Syarikat'} : <span className="fw-bold">{profileData?.companyReg}</span></p>
                                                    <p className="mb-0">{languageUse === 'English' ? 'Username' : 'Nama Pengguna'} : <span className="bold">{profileData?.username}</span></p>
                                                    <p>
                                                        {languageUse === 'English' ? 'State' : 'Negeri'} : <span className="fw-bold">{profileData?.state}</span>
                                                    </p>
                                                </Col>
                                                <Col className="" md="6" sm="6">
                                                    <p className="mb-0">{languageUse === 'English' ? 'Last Submission Date' : 'Tarikh Penyerahan Terakhir'} :{" "}<span className="bold">{props?.lastSubmission ? moment(props?.lastSubmission).format("DD-MM-YYYY") : 'No Previous Submission'}</span></p>
                                                    <p className="mb-0">
                                                        {languageUse === 'English' ? 'Industry' : 'Industri'} : <span className="bold">{languageUse === 'English' ? profileData?.industry?.name : profileData?.industry?.name_Malay}</span>
                                                    </p>
                                                    <p className="mb-0">
                                                        Email : <span className="bold">{profileData?.email}</span>
                                                    </p>
                                                    <p className="mb-0">
                                                        {languageUse === 'English' ? 'Country' : 'Negara'} : <span className="fw-bold">{profileData?.country}</span>
                                                    </p>
                                                    <p>
                                                        {languageUse === 'English' ? 'City' : 'Bandar'} : <span className="fw-bold">{profileData?.city}</span>
                                                    </p>
                                                </Col>
                                                <hr></hr>
                                            </Row>

                                            {dimensionData?.length > 0 &&
                                                <Row className="profile px-5 mb-4">
                                                    <Col className="" md="6" sm="6">
                                                        <p>
                                                            {languageUse === 'English' ? 'ESG Maturity Level' : 'Tahap Kematangan ESG'} : <span className="fw-bold" style={{ color: colorLeval.find(item => item.leval === ESGperformanceData?.leval)?.color_code }}>{languageUse === 'English' ? ESGperformanceData?.data?.leval : ESGperformanceData?.data?.leval_Malay}</span>
                                                        </p>
                                                        {languageUse === 'English' ? <p className="mb-0">
                                                            {dimensionData[0]?.dimension} Maturity Level : <span className="fw-bold" style={{ color: colorLeval.find(item => item.leval === dimensionData[0]?.leval)?.color_code }}>{dimensionData[0]?.leval}</span>
                                                        </p> : <p className="mb-0">
                                                            Tahap Kematangan {dimensionData[0]?.dimension_Malay} : <span className="fw-bold" style={{ color: colorLeval.find(item => item.leval === dimensionData[0]?.leval)?.color_code }}>{dimensionData[0]?.performance?.leval_Malay}</span>
                                                        </p>}
                                                    </Col>
                                                    <Col className="" md="6" sm="6">
                                                        {languageUse === 'English' ? <p className="mb-0">
                                                            {dimensionData[1]?.dimension} Maturity Level : <span className="fw-bold" style={{ color: colorLeval.find(item => item.leval === dimensionData[1]?.leval)?.color_code }}>{dimensionData[1]?.leval}</span>
                                                        </p> : <p className="mb-0">
                                                            Tahap Kematangan {dimensionData[1]?.dimension_Malay} : <span className="fw-bold" style={{ color: colorLeval.find(item => item.leval === dimensionData[1]?.leval)?.color_code }}>{dimensionData[1]?.performance?.leval_Malay}</span>
                                                        </p>}
                                                        {languageUse === 'English' ? <p className="mb-0">
                                                            {dimensionData[2]?.dimension} Maturity Level : <span className="fw-bold" style={{ color: colorLeval.find(item => item.leval === dimensionData[2]?.leval)?.color_code }}>{dimensionData[2]?.leval}</span>
                                                        </p> : <p className="mb-0">
                                                            Tahap Kematangan {dimensionData[2]?.dimension_Malay} : <span className="fw-bold" style={{ color: colorLeval.find(item => item.leval === dimensionData[2]?.leval)?.color_code }}>{dimensionData[2]?.performance?.leval_Malay}</span>
                                                        </p>}
                                                    </Col>
                                                </Row>
                                            }

                                        </div>

                                        <Row className="" style={{ paddingRight: size?.width >= 1400 ? '0' : '0', marginBottom: '10rem', marginTop: '5rem' }}>
                                            <p className="fw-bold px-0">ESG Maturity Level:</p>
                                            <table className="table table-bordered border-0">
                                                <tbody>
                                                    <tr className="border-0">

                                                        <td className="ps-3 border-0 text-center fw-bold text-dark bg-Laggard">{languageUse === 'English' ? 'Laggard' : 'Laggard'}</td>

                                                        <td className="ps-3 border-0 text-center fw-bold text-dark bg-Beginner">{languageUse === 'English' ? 'Beginner' : 'Pemula'}</td>

                                                        <td className="ps-3 border-0 text-center fw-bold text-dark bg-Intermediate">{languageUse === 'English' ? 'Intermediate' : 'Pertengahan'}</td>

                                                        <td className="ps-3 border-0 text-center fw-bold text-white bg-Advanced">{languageUse === 'English' ? 'Advanced' : 'Maju'}</td>

                                                        <td className="ps-3 border-0 text-center fw-bold text-white bg-Leader">{languageUse === 'English' ? 'Leader' : 'Ketua'} </td>
                                                    </tr>
                                                    <tr className="border-0">
                                                        <td className={classNames("ps-3 border-0 text-center fw-bold", { 'bg-Laggard': ESGperformanceData?.leval === 'Laggard' || ESGperformanceData?.data?.leval_Malay === 'Laggard', })}>{languageUse === 'English' ? ESGperformanceData?.leval === 'Laggard' ? <BsCheckCircleFill className="text-white" /> : '' : ESGperformanceData?.data?.leval_Malay === 'Laggard' ? <BsCheckCircleFill className="text-white" /> : ''}</td>

                                                        <td className={classNames("ps-3 border-0 text-center fw-bold", { 'bg-Beginner': ESGperformanceData?.leval === 'Beginner' || ESGperformanceData?.data?.leval_Malay === 'Pemula', })}>{languageUse === 'English' ? ESGperformanceData?.leval === 'Beginner' ? <BsCheckCircleFill className="text-white" /> : '' : ESGperformanceData?.data?.leval_Malay === 'Pemula' ? <BsCheckCircleFill className="text-white" /> : ''}</td>

                                                        <td className={classNames("ps-3 border-0 text-center fw-bold", { 'bg-Intermediate': ESGperformanceData?.leval === 'Intermediate' || ESGperformanceData?.data?.leval_Malay === 'Pertengahan', })}>{languageUse === 'English' ? ESGperformanceData?.leval === 'Intermediate' ? <BsCheckCircleFill className="text-white" /> : '' : ESGperformanceData?.data?.leval_Malay === 'Pertengahan' ? <BsCheckCircleFill className="text-white" /> : ''}</td>

                                                        <td className={classNames("ps-3 border-0 text-center fw-bold", { 'bg-Advanced': ESGperformanceData?.leval === 'Advanced' || ESGperformanceData?.data?.leval_Malay === 'Maju', })}>{languageUse === 'English' ? ESGperformanceData?.leval === 'Advanced' ? <BsCheckCircleFill className="text-white" /> : '' : ESGperformanceData?.data?.leval_Malay === 'Maju' ? <BsCheckCircleFill className="text-white" /> : ''}</td>

                                                        <td className={classNames("ps-3 border-0 text-center fw-bold", { 'bg-Leader': ESGperformanceData?.leval === 'Leader' || ESGperformanceData?.data?.leval_Malay === 'Ketua', })}>{languageUse === 'English' ? ESGperformanceData?.leval === 'Leader' ? <BsCheckCircleFill className="text-white" /> : '' : ESGperformanceData?.data?.leval_Malay === 'Ketua' ? <BsCheckCircleFill className="text-white" /> : ''}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Row>

                                        {/* <Row className="" style={{ paddingRight: size?.width >= 1400 ? '25rem' : '25rem' }}>
                                            <p className="fw-bold px-0">Your Climate Action Readiness Level:</p>
                                            <table className="table table-bordered border-0">
                                                <tbody>
                                                    <tr className="border-0">
                                                        
                                                        <td className="ps-3 border-0 text-center fw-bold text-dark bg-Laggard">{languageUse === 'English' ? 'Laggard' : 'Laggard'}</td>

                                                        <td className="ps-3 border-0 text-center fw-bold text-dark bg-Beginner">{languageUse === 'English' ? 'Beginner' : 'Pemula'}</td>

                                                        <td className="ps-3 border-0 text-center fw-bold text-dark bg-Intermediate">{languageUse === 'English' ? 'Intermediate' : 'Pertengahan'}</td>

                                                        <td className="ps-3 border-0 text-center fw-bold text-white bg-Advanced">{languageUse === 'English' ? 'Advanced' : 'Maju'}</td>

                                                        <td className="ps-3 border-0 text-center fw-bold text-white bg-Leader">{languageUse === 'English' ? 'Leader' : 'Ketua'} </td>
                                                    </tr>
                                                    <tr className="border-0">
                                                            <td className={classNames("ps-3 border-0 text-center fw-bold", { 'bg-Laggard': ESGperformanceData?.leval === 'Laggard' || ESGperformanceData?.data?.leval_Malay === 'Laggard', })}>{languageUse === 'English' ? ESGperformanceData?.leval === 'Laggard' ? <BsCheckCircleFill className="text-white" /> : '' : ESGperformanceData?.data?.leval_Malay === 'Laggard' ? <BsCheckCircleFill className="text-white" /> : ''}</td>
                                                            
                                                        <td className={classNames("ps-3 border-0 text-center fw-bold", { 'bg-Beginner': ESGperformanceData?.leval === 'Beginner' || ESGperformanceData?.data?.leval_Malay === 'Beginner', })}>{languageUse === 'English' ? ESGperformanceData?.leval === 'Beginner' ? <BsCheckCircleFill className="text-white" /> : '' : ESGperformanceData?.data?.leval_Malay === 'Beginner' ? <BsCheckCircleFill className="text-white" /> : ''}</td>
                                                        
                                                        <td className={classNames("ps-3 border-0 text-center fw-bold", { 'bg-Intermediate': ESGperformanceData?.leval === 'Intermediate' || ESGperformanceData?.data?.leval_Malay === 'Intermediate', })}>{languageUse === 'English' ? ESGperformanceData?.leval === 'Intermediate' ? <BsCheckCircleFill className="text-white" /> : '' : ESGperformanceData?.data?.leval_Malay === 'Intermediate' ? <BsCheckCircleFill className="text-white" /> : ''}</td>

                                                        <td className={classNames("ps-3 border-0 text-center fw-bold", { 'bg-Advanced': ESGperformanceData?.leval === 'Advanced' || ESGperformanceData?.data?.leval_Malay === 'Advanced', })}>{languageUse === 'English' ? ESGperformanceData?.leval === 'Advanced' ? <BsCheckCircleFill className="text-white" /> : '' : ESGperformanceData?.data?.leval_Malay === 'Advanced' ? <BsCheckCircleFill className="text-white" /> : ''}</td>

                                                        <td className={classNames("ps-3 border-0 text-center fw-bold", { 'bg-Leader': ESGperformanceData?.leval === 'Leader' || ESGperformanceData?.data?.leval_Malay === 'Leader', })}>{languageUse === 'English' ? ESGperformanceData?.leval === 'Leader' ? <BsCheckCircleFill className="text-white" /> : '' : ESGperformanceData?.data?.leval_Malay === 'Leader' ? <BsCheckCircleFill className="text-white" /> : ''}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Row> */}

                                        <Row className="justify-content-evenly" style={{ paddingRight: size?.width >= 1400 ? '0' : '0' }}>
                                            <Col
                                                className="history-text-color px-0 mb-4 mb-md-0"
                                                md="5"
                                                sm="5"
                                            >
                                                <h4 className="ps-3 pt-2 mb-4">{languageUse === 'English' ? 'Score of Category' : 'Skor Kategori'}</h4>
                                                <div className="mt-5 d-flex justify-content-start align-items-center">
                                                    <Bar className="ps-1 pe-2 chart-wrapper" data={props.data} options={props.options} height={220}></Bar>
                                                </div>
                                            </Col>
                                            <Col className="history-text-color px-0" md="6" sm="6">
                                                <div>
                                                    <h4 className="ps-3 pt-2 mb-4">{languageUse === 'English' ? "Score of Indicator" : "Skor Penunjuk"}</h4>
                                                    <table className="table border">
                                                        <thead className="table-head">
                                                            <tr>
                                                                <td scope="col">{languageUse === 'English' ? "Dimension" : "Dimensi"}</td>
                                                                <td scope="col">{languageUse === 'English' ? "Indicator" : "Penunjuk"}</td>
                                                                <td scope="col" className="text-center">
                                                                    {languageUse === 'English' ? "Your Score" : "Skor Anda"}
                                                                </td>
                                                                <td scope="col" className="text-center">
                                                                    {languageUse === 'English' ? "Peer Score" : "Skor Rakan Industri"}
                                                                </td>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {indicatorData?.length > 0 && [...new Set(indicatorData.map(item => item?.dimension?._id))].map((it, ind) => {
                                                                return (
                                                                    indicatorData?.filter(item => item?.dimension?._id === it).map((item, index) => (
                                                                        <tr key={index}>
                                                                            {index === 0 && <td rowSpan={indicatorData?.filter(item => item?.dimension?._id === it).length} style={{ backgroundColor: '#f4fbfe' }}>{languageUse === 'English' ? item?.dimension?.name ? `${item?.dimension?.name}` : '--' : item?.dimension?.name_Malay ? `${item?.dimension?.name_Malay}` : '--'}</td>}

                                                                            <td className="">{languageUse === 'English' ? item.name ? `${item?.name}` : '--' : item.name_Malay ? `${item?.name_Malay}` : '--'}</td>
                                                                            <td className="text-center">{item?.correct ? `${((item?.correct / item?.total) * 100).toFixed(2)}%` : '0%'}</td>
                                                                            <td className="text-center">{item?.industryScore ? `${((item?.industryScore / item?.total) * 100).toFixed(2)}%` : '0%'}</td>
                                                                        </tr>

                                                                    )))
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className="" style={{ marginTop: '', pageBreakBefore: 'always' }} >
                                            <h4 className="pt-2 px-5">{languageUse === 'English' ? "Peer Comparison Bar" : "Carta Perbandingan Rakan Industri"}</h4>
                                            <div className="mt-2">
                                                {/* <Bar className="ps-1 pe-4" data={props.horizontalData} options={props.horoizontalOptions}></Bar> */}
                                                <Bar style={{ marginRight: '10rem', paddingRight: '18rem' }} data={props.horizontalData} options={props.horoizontalOptions}></Bar>
                                            </div>
                                        </Row>

                                        <Row className="mt-5" style={{ pageBreakBefore: '', paddingRight: size?.width >= 1400 ? '0' : '0' }}>
                                            <h4 className="pt-2 mb-4 fw-bold">{languageUse === 'English' ? "ESG performance" : "Prestasi ESG"}</h4>

                                            {languageUse === 'English' ? <h4 className="textColor pt-2">{profileData?.company} has an {ESGperformanceData?.leval} level</h4> : <h4 className="textColor pt-2">{profileData?.company} memiliki tahap {ESGperformanceData?.performance?.leval_Malay}</h4>}
                                            {/* <p className="pt-2 mb-4">{ESGperformanceData?.data?.esgDescription.replace(/SME X/g, profileData?.company)}</p> */}

                                            <p className="pt-2 mb-4">{languageUse === 'English' ? ESGperformanceData?.data?.esgDescription && ESGperformanceData?.data?.esgDescription.replace(/SME X/g, profileData?.company) : ESGperformanceData?.data?.esgDescription_Malay && ESGperformanceData?.data?.esgDescription_Malay}</p>

                                            <h5><hr></hr></h5>
                                        </Row>

                                        {dimensionData?.length > 0 && dimensionData?.map((dimensionItem, dimensionIndex) => (
                                            <Row className="mt-5" key={dimensionIndex} style={{ paddingRight: size?.width >= 1400 ? '0' : '0' }}>
                                                <h4 className="pt-2 mb-4 fw-bold">{`${languageUse === 'English' ? `${dimensionItem?.dimension} performance` : `Prestasi ${dimensionItem?.dimension_Malay}`}`}</h4>

                                                {/* <h4 className="pt-2 textColor">{`SME X shows ${dimensionItem?.leval} level in the ${languageUse === 'English' ? dimensionItem?.dimension : dimensionItem?.dimension_Malay} dimension which is evident for the ${languageUse === 'English' ? selectedIndicatorData?.find(element => element?.dimension?._id === dimensionItem?.id)?.name : selectedIndicatorData?.find(element => element?.dimension?._id === dimensionItem?.id)?.name_Malay} indicator which scores ${higherLowerDetector(selectedIndicatorData?.find(element => element?.dimension?._id === dimensionItem?.id))} than peers .`.replace(/SME X/g, profileData?.company)}</h4> */}

                                                {languageUse === 'English' ? <>
                                                    <h4 className="textColor mb-2">{`SME X shows ${dimensionItem?.leval} level in the ${languageUse === 'English' ? dimensionItem?.dimension : dimensionItem?.dimension_Malay} dimension which is evident for the ${languageUse === 'English' ? selectedIndicatorData?.find(element => element?.dimension?._id === dimensionItem?.id)?.name : selectedIndicatorData?.find(element => element?.dimension?._id === dimensionItem?.id)?.name_Malay} indicator which scores ${higherLowerDetector(selectedIndicatorData?.find(element => element?.dimension?._id === dimensionItem?.id))} than peers.`.replace(/SME X/g, profileData?.company)}</h4>
                                                </> : <>
                                                    <h4 className="textColor mb-2">{`SME X memiliki tahap ${dimensionItem?.performance?.leval_Malay} dalam ${languageUse === 'English' ? dimensionItem?.dimension : dimensionItem?.dimension_Malay} dimensi Persekitaran selaras dengan skor ${higherLowerDetector(selectedIndicatorData?.find(element => element?.dimension?._id === dimensionItem?.id))} rendah berbanding rakan industri dalam kategori ${languageUse === 'English' ? selectedIndicatorData?.find(element => element?.dimension?._id === dimensionItem?.id)?.name : selectedIndicatorData?.find(element => element?.dimension?._id === dimensionItem?.id)?.name_Malay}.`.replace(/SME X/g, profileData?.company)}</h4>

                                                </>}

                                                <p className="pt-2 mb-4">{languageUse === 'English' ? dimensionItem?.performance?.description.replace(/SME X/g, profileData?.company) : dimensionItem?.performance?.description_Malay.replace(/SME X/g, profileData?.company)}</p>
                                                {/* <p className="pt-2 mb-4">{dimensionItem?.performance?.description.replace(/SME X/g, profileData?.company)}</p> */}

                                                <h5 className="pt-2 fw-bold">The company undertakes the following activities on {dimensionItem?.dimension} topics:</h5>

                                                {optionalQuesData?.length > 0 && optionalQuesData?.map((optItem, optIndex) => (
                                                    <Row className="mt-1" key={optIndex}>

                                                        {dimensionItem?.dimension === optItem?.dimension ? <>

                                                            {/* {optItem?.questons?.filter(itemInd => itemInd.questions?.length > 0 && itemInd?.questions?.filter(item => item.answer.length > 0)).map((itemInd, indexInd) => (
                                
                                                                <div className="ps-4 mb-2">
                                                                    {itemInd?.questions?.filter(item => item?.answer?.length > 0).map((itemQ, indexQ) => (
                                                                        <div className="">
                                                                            {indexQ === 0 && <p>{itemInd.indicater}</p>}
                                                                            <p key={indexQ} className="">{indexInd + 1}.{indexQ + 1}. {itemQ?.answer.join(', ')}</p>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            ))} */}

                                                            {optItem?.questons?.filter(itemInd => itemInd.questions?.length > 0 && itemInd?.questions).map((itemInd, indexInd) => (

                                                                <div className="ps-4 mb-2" key={indexInd}>
                                                                    {itemInd?.questions?.map((itemQ, indexQ) => (
                                                                        <div className="" key={indexQ}>
                                                                            {indexQ === 0 && <p>{languageUse === 'English' ? itemInd.indicater : itemInd.indicater_name_malay}</p>}
                                                                            {itemQ?.answer?.length > 0 ? <p className="">{indexInd + 1}.{indexQ + 1}. {itemQ?.answer?.join(', ')}</p> : <p>{indexInd + 1}.{indexQ + 1}. None</p>
                                                                            }

                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            ))}
                                                        </> : <></>}

                                                    </Row>
                                                ))}

                                                <h5><hr></hr></h5>
                                            </Row>
                                        ))}

                                        <Row className="profile" style={{ paddingBottom: '1rem', paddingRight: size?.width >= 1400 ? '0' : '0' }}>
                                            <h4 className="pt-2 mb-4 fw-bold">SDG MAPPING</h4>

                                            <div className="px-2">
                                                <table className="table table-bordered">
                                                    <thead className="table-head">

                                                    </thead>

                                                    <tbody>
                                                        {indicatorData?.length > 0 && indicatorData?.map((item, index) => (
                                                            <tr key={index}>
                                                                <td className="ps-3 textColor">{languageUse === 'English' ? item?.name ? `${item?.name}` : '--' : item?.name_Malay ? `${item?.name_Malay}` : '--'}</td>
                                                                {/* <td className="ps-3">{item?.performance?.description ? `${item?.performance?.description?.replace(/SME X/g, profileData?.company)}` : '--'}</td> */}

                                                                <td className="ps-3">{(item?.performance?.description || item?.performance?.description_Malay) ? `${languageUse === 'English' ? item?.performance?.description?.replace(/SME X/g, profileData?.company) : item?.performance?.description_Malay?.replace(/SME X/g, profileData?.company)}` : '--'}</td>

                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>

                                        </Row>
                                        <Row className="profile" style={{ marginTop: '1rem', paddingRight: size?.width >= 1400 ? '0' : '0' }}>
                                            <h4 className="pt-2 mt-2 fw-bold">SUBMISSION DETAILS</h4>
                                            {requiredQuestions?.length > 0 && requiredQuestions?.map((optItem, optReqIndex) => (
                                                <Row className="mt-1" key={optReqIndex}>

                                                    {Object.values(optItem?.questons).some(el => el.questions.length > 0) ? <>
                                                        <h4 className="pt-2 mb-4 mt-2 fw-bold">{languageUse === 'English' ? optItem?.dimension : optItem?.name_Malay}</h4>
                                                    </> : <></>}
                                                    {optItem?.questons?.map((itemInd, indexInd) => (

                                                        <div className="ps-4 ms-2" key={indexInd}>
                                                            {itemInd?.questions?.length > 0 ? <h4 className="textColor mt-2">{languageUse === 'English' ? itemInd?.indicater : itemInd?.indicater_name_malay}:</h4> : <></>}

                                                            {itemInd?.questions?.length > 0 && itemInd?.questions?.map((itemQ, indexQ) => (
                                                                <div className="" key={indexQ}>
                                                                    {/* <p key={indexQ}>{indexQ + 1}. {itemQ?.questionId?.question} </p> */}
                                                                    <p>{indexQ + 1}. {languageUse === 'English' ? itemQ?.questionId?.question : itemQ?.questionId?.question_Malay} </p>
                                                                    <p className="ps-3"> {itemQ?.answer.join(', ')}</p>
                                                                </div>
                                                            ))}
                                                        </div>

                                                    ))}
                                                </Row>
                                            ))}

                                        </Row>
                                        <h4 className="pt-2 mb-4 fw-bold">Uploaded Documents</h4>
                                        {props?.uploadedDocInfo?.length > 0 ? <div className="table-responsive" style={{ paddingRight: size?.width >= 1400 ? '0' : '0' }}>
                                            <table className="table table-bordered table-responsive">
                                                <thead className="text-color-primary fw-bold">
                                                    <tr>
                                                        <td scope="col">Dimension</td>
                                                        <td scope="col">Name</td>
                                                        <td scope="col">Description</td>
                                                        <td scope="col">Uploaded PDF</td>
                                                        <td scope="col">Actions</td>

                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {props?.uploadedDocInfo?.length > 0 && props?.uploadedDocInfo?.map((it, ind) => {
                                                        return (
                                                            it?.files?.map((item, index) => (
                                                                <tr key={index}>
                                                                    {index === 0 && <td rowSpan={it?.files?.length}>{languageUse === 'English' ? it?.dimension?.name : it?.dimension?.name_Malay}</td>}
                                                                    {index === 0 && <td rowSpan={it?.files?.length}>{it?.name}</td>}
                                                                    {index === 0 && <td rowSpan={it?.files?.length}>{it?.description}</td>}
                                                                    <td className=''>{item ? `Document-${index + 1}` : '--'}</td>
                                                                    <td className='text-color-primary'>
                                                                        {/* <AiOutlineEye className='me-2' role='button' onClick={() => viewDoc(item)} /> */}
                                                                        <a href={`${AWS_Url}${item}`} target="_blank"><BiImport className='' role='button' /></a>
                                                                    </td>
                                                                </tr>
                                                            ))

                                                        )
                                                    })
                                                    }

                                                </tbody>
                                            </table>
                                        </div> : <div className='w-100 d-flex align-items-center justify-content-center'>
                                            <p className=''>No Document Available</p>
                                        </div>}
                                        <div style={{ paddingRight: size?.width >= 1400 ? '0' : '0' }}>

                                            <h4 className="pt-2 mb-4 fw-bold">DISCLAIMER</h4>

                                            <p style={{ fontSize: '.9rem' }}>
                                                The ESG maturity levels and score calculations provided on this website are based on self-declared data submissions by <span className="textColor">{profileData?.company}</span>. The information provided in this report shall not be relied upon as a substitute for professional advice. <span className="textColor">UNGCMYB</span> shall not be held liable for any errors, omissions, or inaccuracies in the data provided by <span className="textColor">{profileData?.company}</span> or for any actions taken in reliance on such information. <span className="textColor">{profileData?.company}</span> is solely responsible for the accuracy and completeness of the information they provide.
                                            </p>
                                            {/* <p className="fs-14">For more information & support, reach out to <a className="text-decoration-none" href="mailto:strategicpartnerships@alliancefg.com">strategicpartnerships@alliancefg.com</a></p> */}
                                        </div>

                                        <Row style={{ marginBottom: '5rem', paddingRight: size?.width > 1400 ? '0rem' : '0rem' }}>
                                            <Col className='d-flex flex-wrap align-items-center justify-content-center justify-content-sm-between' xs='12'>
                                                <span className='text-center mb-0'>Copyright © 2024 UN Global Compact Network Malaysia & Brunei. All Rights Reserved.</span>
                                                <div className='d-flex flex-column align-items-center mt-3 mt-sm-0'>
                                                    <span className='text-primary'>Technology Partner</span>
                                                    <Link target="_blank" to="https://segwitz.com/">
                                                        <img className='mt-1 img-fluid' src={Segwitz} />
                                                    </Link>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </Container>
    )
});

export default PdfReportPage2;